import { IconProps } from '@/components/ui/icons/types';

export const MinusIcon = ({ className }: IconProps) => (
  <svg
    width="15"
    height="2"
    viewBox="0 0 15 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M1 1H14" stroke="#192A68" strokeWidth="2" strokeLinecap="square" />
    <path
      d="M14 1L1 0.999999"
      stroke="#192A68"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);
