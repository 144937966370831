import { useEditor, EditorContent, JSONContent } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import History from '@tiptap/extension-history';
import { useEffect } from 'react';
import { AutoCompleteField } from './suggestion';

interface EmailTemplateEditorProps {
  content: JSONContent;
  onContentChange: (content: JSONContent, text: string) => void;
  disabled?: boolean;
  singleLine?: boolean; // New prop
}

export const EmailTemplateEditor = ({
  content,
  onContentChange,
  disabled = false,
  singleLine = false, // Default value
}: EmailTemplateEditorProps) => {
  const editor = useEditor({
    extensions: [Document, Paragraph, Text, History, AutoCompleteField],
    content,
    editable: !disabled,
    onUpdate: ({ editor }) => {
      let text = editor.getText().replace(/\n{2}/g, '\n');
      const jsonContent = editor.getJSON();
      if (singleLine) {
        text = text.replace(/\n/g, ' ');
        if (jsonContent.content && jsonContent.content.length > 1) {
          jsonContent.content = [jsonContent.content[0]];
        }
      }
      onContentChange(jsonContent, text);
    },
  });

  useEffect(() => {
    if (
      editor &&
      JSON.stringify(editor.getJSON()) !== JSON.stringify(content)
    ) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  if (!editor) {
    return null;
  }

  return (
    <EditorContent
      editor={editor}
      style={{
        maxHeight: singleLine ? '1.5em' : 'calc(100vh - 260px)',
        overflowY: singleLine ? 'hidden' : 'auto',
      }}
      className={`bg-light-light editor ${disabled ? 'cursor-not-allowed' : ''}`}
    />
  );
};
