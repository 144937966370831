import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button, buttonVariants } from '@/components/ui/button';
import { SalesPipeline } from '@/pages/crm/lead-stages/types';
import { EmailTemplate } from '@/pages/admin/email-templates/types';
import { getMortgageType, getOwnerEmails } from '@/components/crm/board/utils';
import { cn } from '@/lib/utils';
import {
  generateEmailContent,
  getFieldMapping,
} from '@/pages/admin/email-templates/email-fields';
import { AppSettingResponse } from '@/pages/data-pipeline/api-usage/types';

interface EmailTemplateDialogProps {
  open: boolean;
  salesPipeline?: SalesPipeline;
  emailTemplates: EmailTemplate[];
  onOpenChange(open: boolean): void;
  userFullName: string;
  appSetting?: AppSettingResponse;
}

export const EmailTemplateDialog = ({
  open,
  salesPipeline,
  emailTemplates,
  onOpenChange,
  userFullName,
  appSetting,
}: EmailTemplateDialogProps) => {
  const mortgageType = getMortgageType(salesPipeline);
  const emailTemplate = emailTemplates.find(
    (template) => template.mortgageType === mortgageType
  );

  const emailFieldMapping = getFieldMapping(
    userFullName,
    salesPipeline,
    appSetting
  );
  const emailSubject = generateEmailContent(
    emailTemplate?.subjectText ?? '',
    emailFieldMapping
  );
  const emailBody = generateEmailContent(
    emailTemplate?.bodyText ?? '',
    emailFieldMapping
  );

  const ownerEmails = getOwnerEmails(salesPipeline);
  const mailtoLink = emailTemplate
    ? `mailto:${ownerEmails.join(',')}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`
    : '#';

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Send Email</DialogTitle>
        </DialogHeader>
        <div>
          <div className="text-navy-navy text-label-md border-navy-navy border-b py-2 mb-2 normal-case">
            To: {ownerEmails.join(', ')}
          </div>

          {emailTemplate ? (
            <div>
              <div className="text-navy-navy text-label-md border-navy-navy border-b py-2 normal-case">
                Subject: {emailSubject}
              </div>
              <div
                className="my-6 whitespace-pre-wrap bg-light-light p-4 overflow-y-auto"
                style={{ maxHeight: 'calc(100vh - 250px)' }}
              >
                {emailBody}
              </div>
            </div>
          ) : (
            <div className="my-6 bg-light-light p-4 text-red-red">
              No Email Template Found
            </div>
          )}
          <DialogFooter>
            <Button onClick={() => onOpenChange?.(false)}>Close</Button>
            {emailTemplate && (
              <a href={mailtoLink} className={cn(buttonVariants())}>
                Open Email Client
              </a>
            )}
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
};
