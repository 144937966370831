import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Link } from '@/components/ui/link';

import { Alert, AlertDescription } from '@/components/ui/alert';
import { ChangeEvent, FormEvent } from 'react';

interface LoginFormProps {
  form: { email: string };
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleLogin: (e: FormEvent) => void;
  error?: string;
}

export const ForgotPasswordForm = ({
  form,
  handleInputChange,
  handleLogin,
  error,
}: LoginFormProps) => {
  return (
    <div className="mx-auto w-[400px]">
      <form onSubmit={handleLogin}>
        {error && (
          <Alert variant="destructive" className="mb-6">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <div className="flex flex-col space-y-6">
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="EMAIL ADDRESS"
            required
            className="w-full h-10 px-3 bg-transparent border-0 rounded-none border-b border-[#192a68] justify-start items-center gap-2.5 inline-flex text-label-md normal-case focus-visible:ring-0 focus-visible:ring-offset-0"
            value={form.email}
            onChange={handleInputChange}
          />

          <div className="space-y-6">
            <Button type="submit" className="w-full">
              Send reset link
            </Button>

            <Link
              href="/login"
              className="w-full text-center uppercase ml-auto inline-block text-paragraph-sm text-blue-blue"
              tabIndex={-1}
            >
              Back to login page
            </Link>
          </div>

          <div className="space-y-3 text-paragraph-sm text-navy-navy">
            <div>
              For technical support, please contact us at admin@leadsiege.io
            </div>
            <div className="flex justify-between items-center">
              <div>&copy;LeadSiege 2024</div>
              <div className="underline">Privacy policy</div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
