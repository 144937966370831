export const InterestRateIcon = () => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8594 14V11.6L9.20938 8H6.85938V5.5L13.8594 0.5L20.8594 5.5V14H18.8594ZM14.3594 7H15.3594V6H14.3594V7ZM12.3594 7H13.3594V6H12.3594V7ZM14.3594 9H15.3594V8H14.3594V9ZM12.3594 9H13.3594V8H12.3594V9ZM0.859375 21V10H4.85938V21H0.859375ZM13.8594 21L6.85938 19.025V10H8.83438L16.8594 13V15H12.8594L11.1094 14.325L10.7594 15.25L12.8594 16H21.8594V18L13.8594 21Z"
      fill="#192A68"
    />
  </svg>
);
