import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/use-api';

interface DataImportErrorsProps {
  dataImportId: string;
}

interface DataImportError {
  propertyApn: string;
  errorMessage: string;
  propertyData: Record<string, unknown>;
}

interface DataImportErrorsResponse {
  errors: DataImportError[];
}

export const DataImportErrors = ({ dataImportId }: DataImportErrorsProps) => {
  const { getRequest } = useApi();
  const { isPending, error, data } = useQuery<DataImportErrorsResponse>({
    queryKey: ['propertiesDataAll', dataImportId],
    queryFn: () =>
      getRequest(`/api/data_pipeline/data_imports/${dataImportId}/errors`),
  });

  const createJsonBlobUrl = (propertyData: Record<string, unknown>) => {
    const dataStr = JSON.stringify(propertyData, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    return URL.createObjectURL(blob);
  };

  if (isPending || error || data.errors.length === 0) {
    return null;
  }

  return (
    <div>
      <h2 className="text-heading-03 mb-2">Imported Data Errors</h2>
      <div className="space-y-4">
        {data.errors.map((error) => (
          <div key={error.propertyApn} className="space-y-1">
            <div className="text-navy-navy text-label-lg">
              {error.propertyApn}
            </div>
            <div className="text-red-red text-label-md">
              {error.errorMessage}
            </div>
            <a
              href={createJsonBlobUrl(error.propertyData)}
              download={`${error.propertyApn}.json`}
              className="text-blue-500 underline"
            >
              Download JSON
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
