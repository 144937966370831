import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { EyeIcon } from 'lucide-react';
import { Table } from '@tanstack/react-table';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';
import { toISODate } from '@/utils/date';
import { exportCsv } from '@/utils/csv';

interface DataTableViewOptionsProps<TData> {
  table: Table<TData>;
}

export function DataTableViewOptions<TData>({
  table,
}: DataTableViewOptionsProps<TData>) {
  const handleSelectAllChange = (value: boolean) => {
    table.getAllColumns().forEach((column) => {
      if (typeof column.accessorFn !== 'undefined' && column.getCanHide()) {
        column.toggleVisibility(value);
      }
    });
  };

  const columns = table
    .getAllColumns()
    .filter(
      (column) =>
        typeof column.accessorFn !== 'undefined' && column.getCanHide()
    );

  const allColumnsVisible = columns.every((column) => column.getIsVisible());

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="orange"
          className="flex space-x-2"
          onClick={() => {
            const formattedDate = toISODate(new Date());
            exportCsv(`property-data_${formattedDate}`, table);
          }}
        >
          <EyeIcon className="mr-2 h-4 w-4" />
          View
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="max-h-[500px] overflow-y-auto"
      >
        <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          className="capitalize"
          checked={allColumnsVisible}
          onCheckedChange={handleSelectAllChange}
        >
          Select All
        </DropdownMenuCheckboxItem>
        {columns.map((column) => {
          return (
            <DropdownMenuCheckboxItem
              key={column.id}
              className="capitalize"
              checked={column.getIsVisible()}
              onCheckedChange={(value) => column.toggleVisibility(!!value)}
            >
              {column.columnDef.meta?.title}
            </DropdownMenuCheckboxItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
