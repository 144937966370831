export const EmailIcon = ({ active }: { active?: boolean }) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 0.00012207H0V16.0001H20V0.00012207ZM18 4.00012L10 9.00012L2 4.00012V2.00012L10 7.00012L18 2.00012V4.00012Z"
      className={active ? 'fill-green-green' : 'fill-navy-navy'}
    />
  </svg>
);
