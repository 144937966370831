export const UserIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="9.375"
      transform="rotate(-90 12 12)"
      stroke="#192A68"
      strokeWidth="2.25"
    />
    <g clipPath="url(#clip0_2457_32763)">
      <path
        d="M12.0003 13.6406C7.01957 13.6406 4.42822 16.5853 4.42822 18.268V19.1094H19.5725V18.268C19.5725 16.5853 16.9811 13.6406 12.0003 13.6406Z"
        fill="#192A68"
      />
      <path
        d="M12 12.6562C13.8122 12.6562 15.2812 11.1872 15.2812 9.375C15.2812 7.56282 13.8122 6.09375 12 6.09375C10.1878 6.09375 8.71875 7.56282 8.71875 9.375C8.71875 11.1872 10.1878 12.6562 12 12.6562Z"
        fill="#192A68"
      />
    </g>
    <defs>
      <clipPath id="clip0_2457_32763">
        <rect
          x="5.4375"
          y="5.4375"
          width="13.125"
          height="13.125"
          rx="6.5625"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
