import { getPtDatetimeFormattedString } from '@/utils/date';

interface DueDatetimeLabelProps {
  taskDueDatetime?: string;
  hideLabel?: boolean;
  colorClassName?: string;
}

export const DueDatetimeLabel = ({
  taskDueDatetime,
  hideLabel,
  colorClassName = 'text-navy-navy',
}: DueDatetimeLabelProps) => {
  if (!taskDueDatetime) {
    return (
      <div className={`text-paragraph-md ${colorClassName}`}>
        {!hideLabel && 'Task Due: '}
        Unscheduled
      </div>
    );
  }

  const isDue = taskDueDatetime && new Date() > new Date(taskDueDatetime);

  return (
    <div
      className={`text-paragraph-md ${isDue ? 'text-red-red' : colorClassName}`}
    >
      {!hideLabel && 'Task Due: '}
      {taskDueDatetime
        ? getPtDatetimeFormattedString(taskDueDatetime)
        : 'Unscheduled'}
    </div>
  );
};
