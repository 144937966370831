import { useMutation, useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/use-api';
import { Loader } from '@/components';
import { ReactTable } from '@/components/table/react-table';
import { useEffect, useState } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle, DownloadIcon, Trash2Icon } from 'lucide-react';
import { Checkbox } from '@/components/ui/checkbox';
import { DataTableColumnHeader } from '@/components/table/data-table-column-header';
import { SearchIcon } from '@/pages/crm/lead-stages/icons/search-icon';
import { Button } from '@/components/ui/button';
import { getPtDatetimeFormattedString, toISODate } from '@/utils/date';
import { exportCsv } from '@/utils/csv';
import { useToast } from '@/components/ui/use-toast';
import {
  EmailOptOut,
  EmailOptOutListResponse,
} from '@/pages/admin/email-opt-out-list/types';
import { searchEmailOptOut } from '@/pages/admin/email-opt-out-list/utils';

export const EmailOptOutListContainer = () => {
  const { getRequest, deleteRequest } = useApi();
  const [emailOptOutList, setEmailOptOutList] = useState<EmailOptOut[]>([]);
  const [selectedRows, setSelectedRows] = useState<EmailOptOut[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [search, setSearch] = useState<string>('');
  const columns: ColumnDef<EmailOptOut>[] = [
    {
      id: 'select',
      header: () => (
        <Checkbox
          checked={selectedRows.length === emailOptOutList.length}
          onClick={async () => {
            const isAllPageRowsSelected =
              selectedRows.length === emailOptOutList.length;
            if (isAllPageRowsSelected) {
              setSelectedRows([]);
            } else {
              setSelectedRows(emailOptOutList);
            }
          }}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={selectedRows.map((u) => u.id).includes(row.original.id)}
          onClick={() => {
            const isSelected = selectedRows
              .map((u) => u.id)
              .includes(row.original.id);
            if (isSelected) {
              setSelectedRows((prev) =>
                prev.filter((u) => u.id !== row.original.id)
              );
            } else {
              setSelectedRows((prev) => [...prev, row.original]);
            }
          }}
        />
      ),
      enableSorting: false,
      enableHiding: false,
      meta: {
        ignoreExport: true,
      },
    },
    {
      accessorKey: 'entryPoint',
      meta: {
        title: 'Opt-Out Entry Point',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div>{row.getValue('entryPoint')}</div>,
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'timestamp',
      meta: {
        title: 'Entry Timestamp',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => (
        <div>
          {getPtDatetimeFormattedString(row.getValue('timestamp') as string)}
        </div>
      ),
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'ownerEmail',
      meta: {
        title: 'Owner Email',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div>{row.getValue('ownerEmail')}</div>,
      enableSorting: true,
      enableHiding: false,
    },
  ];

  const { toast } = useToast();

  const { isPending, error, data, refetch } = useQuery<EmailOptOutListResponse>(
    {
      queryKey: ['email_opt_outs'],
      queryFn: () => getRequest('/api/email_opt_outs'),
    }
  );

  const { isPending: isDeleting, mutateAsync: deleteIdnc } = useMutation({
    mutationFn: () =>
      deleteRequest(
        `/api/email_opt_outs/${selectedRows.map((r) => r.id).join(',')}`
      ),
    onSuccess: async () => {
      setSelectedRows([]);
      toast({
        variant: 'success',
        description: 'Email Opt-out entries deleted',
        title: 'Email Opt-outs Deleted',
      });
      await refetch();
    },
  });

  useEffect(() => {
    if (data) {
      setEmailOptOutList(searchEmailOptOut(search, data.data));
      setSelectedRows([]);
    }
  }, [search, data]);

  const table = useReactTable<EmailOptOut>({
    data: emailOptOutList ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
      pagination,
    },
    enableRowSelection: true,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <div className="flex justify-between items-center w-full py-2 rounded-lg">
        <div className="relative w-full">
          <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="text"
            id="simple-search"
            autoComplete="off"
            className="h-8 w-[420px] text-label-md bg-gray-50 border border-gray-300 rounded ps-9 p-1 normal-case"
            placeholder="SEARCH BY PLATFORM USER, PHONE NUMBER..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="flex items-center space-x-2">
          <Button
            className="flex space-x-2"
            onClick={async () => {
              await deleteIdnc();
            }}
            disabled={isDeleting || selectedRows.length === 0}
          >
            <span>
              <Trash2Icon className="h-4 w-4" />
            </span>
          </Button>
          <Button
            className="flex space-x-2"
            onClick={() => {
              const formattedDate = toISODate(new Date());
              exportCsv(`email-opt-out-list_${formattedDate}`, table);
            }}
          >
            <span>
              <DownloadIcon className="h-4 w-4" />
            </span>
            <span>Download as CSV</span>
          </Button>
        </div>
      </div>
      <div>
        {isPending && <Loader />}
        {emailOptOutList && <ReactTable table={table} />}
      </div>
    </>
  );
};
