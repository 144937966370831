import { FilterContainer } from '@/components/filters/sections/filter-container';
import { TaskItem } from '@/components/layouts/top-navigation/task-item';
import { useNotificationContext } from '@/context';
import { Dispatch, SetStateAction } from 'react';
import { storePreference } from '@/utils/preference';
import { z } from 'zod';

export const taskPreferenceSchema = z.object({
  root: z.boolean(),
  due: z.boolean(),
  upcoming: z.boolean(),
});

export type TaskPreference = z.infer<typeof taskPreferenceSchema>;

export const defaultTaskPreference: TaskPreference = {
  root: true,
  due: true,
  upcoming: true,
};

interface TaskItemsProps {
  taskPreference: TaskPreference;
  setTaskPreference: Dispatch<SetStateAction<TaskPreference>>;
  preferenceKey: 'leadStagesTaskState' | 'mapTaskState';
}

export const TaskItems = ({
  taskPreference,
  setTaskPreference,
  preferenceKey,
}: TaskItemsProps) => {
  const { notifications } = useNotificationContext();

  return (
    <>
      {notifications.due.length + notifications.upcoming.length == 0 && (
        <div className="text-navy-navy space-y-2 px-4 pt-2">No Tasks</div>
      )}

      {notifications.due.length > 0 && (
        <FilterContainer
          value="stage"
          defaultClose={!taskPreference.due}
          onChange={(isOpen) => {
            setTaskPreference((prev) => {
              const newState = { ...prev, due: isOpen };
              storePreference(preferenceKey, taskPreferenceSchema, newState);
              return newState;
            });
          }}
          title={<div className="text-heading-05 text-dark-dark">Due</div>}
        >
          <div className="flex-col items-center justify-center space-y-2 px-4">
            {notifications.due.map((item) => (
              <TaskItem key={item.id} item={item} />
            ))}
          </div>
        </FilterContainer>
      )}

      {notifications.upcoming.length > 0 && (
        <FilterContainer
          value="upcoming"
          defaultClose={!taskPreference.upcoming}
          onChange={(isOpen) => {
            console.log('upcoming', isOpen);
            setTaskPreference((prev) => {
              const newState = { ...prev, upcoming: isOpen };
              storePreference(preferenceKey, taskPreferenceSchema, newState);
              return newState;
            });
          }}
          title={<div className="text-heading-05 text-dark-dark">Upcoming</div>}
        >
          <div className="flex-col items-center justify-center space-y-2 px-4">
            {notifications.upcoming.map((item) => (
              <TaskItem key={item.id} item={item} />
            ))}
          </div>
        </FilterContainer>
      )}
    </>
  );
};
