import { MapFilter, MapFilterProps } from '@/components';

export const MapFilterContainer = (props: MapFilterProps) => {
  return (
    <nav
      className="fixed m-4 bg-white bg-opacity-95 z-50 shadow-lg rounded-lg mt-24 overflow-y-auto"
      style={{ maxHeight: 'calc(-125px + 100vh)' }}
    >
      <MapFilter {...props} />
    </nav>
  );
};
