import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { useApi } from '@/hooks/use-api';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '@/components';
import { Sidebar } from '@/pages/settings/common/sidebar';

interface JobDashboard {
  activeTasks: Record<string, string>;
  reservedTasks: Record<string, string>;
  scheduledTasks: Record<string, string>;
}

export const JobDashboard = () => {
  const { getRequest } = useApi();
  const { isPending, error, data } = useQuery<JobDashboard>({
    queryKey: ['jobDashboard'],
    queryFn: () => getRequest('/api/admin/app/celery/jobs'),
  });

  return (
    <Sidebar currentPage="job-dashboard">
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-heading-01">Job Dashboard</div>
        </div>
        <Card>
          <CardContent>
            <div className="pt-6">
              {isPending && <Loader />}
              {error && <div>{error.message}</div>}
              {data && (
                <div className="whitespace-pre-wrap">
                  {JSON.stringify(data, null, 2)}
                </div>
              )}
            </div>
          </CardContent>
          <CardFooter></CardFooter>
        </Card>
      </div>
    </Sidebar>
  );
};
