export const TickIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16.5C0 7.66344 7.16344 0.5 16 0.5C24.8366 0.5 32 7.66344 32 16.5C32 25.3366 24.8366 32.5 16 32.5C7.16344 32.5 0 25.3366 0 16.5Z"
      fill="#08A057"
    />
    <path
      d="M8.85718 16.4999L13.8572 21.4999L23.1429 12.2142"
      stroke="#FEFEFF"
      strokeWidth="1.71429"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
