import { AssignLeadsDialog } from '@/components';
import { MarketFilter } from '@/components/market/assign-leads-dialog/type';
import { useApi } from '@/hooks/use-api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { NumberOfAssignedLeads } from '@/pages/market/market/types';
import { useEffect } from 'react';
import { generateQueryParams } from '@/pages/market/market/utils';
import { useToast } from '@/components/ui/use-toast';
import { UserListResponse } from '@/pages';

interface AssignLeadsDialogContainerProps {
  filter: MarketFilter;
  open: boolean;
  onClose?(): void;
  refreshMarket?(): void;
  isAdmin?: boolean;
  currentUserEmail?: string;
}

export const AssignLeadsDialogContainer = (
  props: AssignLeadsDialogContainerProps
) => {
  const { getRequest, postRequest } = useApi();
  const { toast } = useToast();
  const { data, refetch } = useQuery<NumberOfAssignedLeads>({
    queryKey: ['properties', 'number_of_assigned_leads'],
    queryFn: () => {
      return getRequest(
        '/api/sales_pipelines/assigned_leads/number_of_assigned_leads'
      );
    },
    enabled: false,
  });
  const { data: userList } = useQuery<UserListResponse>({
    queryKey: ['usersData'],
    queryFn: () => getRequest('/api/users'),
    enabled: !!props.isAdmin,
  });

  const { isPending, mutate: assignLeads } = useMutation({
    mutationFn: ({
      numberOfLeads,
      userId,
    }: {
      numberOfLeads: number;
      userId?: number;
    }) => {
      let queryParams = generateQueryParams(props.filter);
      queryParams += `&state=${props.filter.selectedStates.join(',')}`;
      return postRequest(
        `/api/properties/assign_leads?${queryParams}&number_of_leads=${numberOfLeads}${props.isAdmin ? `&user_id=${userId}` : ''}`,
        {}
      );
    },
    onSuccess: () => {
      toast({
        variant: 'success',
        description: props.isAdmin
          ? 'The leads have been assigned to the user. They will be able to view the leads in their Map tab or LO CRM tab.'
          : 'Please return to your Map tab or LO CRM tab to view leads.',
        title: 'Assignment Complete',
      });
      props.refreshMarket?.();
      props.onClose?.();
    },
    onError: (error: Error) => {
      console.log(error);
      const errorMessage = error.message || 'Failed to assign leads';
      toast({
        variant: 'destructive',
        description: errorMessage,
        title: 'Error',
      });
    },
  });

  useEffect(() => {
    if (props.open) {
      (async () => refetch())();
    }
  }, [props.open, refetch]);

  return (
    <AssignLeadsDialog
      {...props}
      numberOfAllowedAssignedLeads={data?.numberOfAllowedAssignedLeads}
      assignLeads={(numberOfLeads, userId) =>
        assignLeads({ numberOfLeads, userId })
      }
      assigning={isPending}
      users={userList?.data?.filter(
        (user) =>
          (user.isActive && !user.isSuperuser) ||
          user.email === props.currentUserEmail
      )}
    />
  );
};
