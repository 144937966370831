export const OptOutIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6609_70118)">
      <path
        d="M5.14879 1.5H11.0579V2.68182H13.4215V14.5H2.78516V2.68182H5.14879V1.5ZM5.14879 3.86364H3.96697V13.3182H12.2397V3.86364H11.0579V5.04545H5.14879V3.86364ZM9.87607 2.68182H6.33061V3.86364H9.87607V2.68182ZM6.33061 7.40909H9.87607V8.59091H6.33061V7.40909ZM6.33061 9.77273H9.87607V10.9545H6.33061V9.77273Z"
        fill="#192A68"
      />
    </g>
    <defs>
      <clipPath id="clip0_6609_70118">
        <rect
          width="13"
          height="13"
          fill="white"
          transform="translate(1.60156 1.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
