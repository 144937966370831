import { Link } from '@/components/ui/link';
import { PropsWithChildren } from 'react';

interface NavLinkProps {
  pathname: string;
  href: string;
  disabled?: boolean;
}

export const NavLink = ({
  children,
  pathname,
  href,
}: PropsWithChildren<NavLinkProps>) => {
  return (
    <Link
      href={href}
      className={`h-[60px] flex items-center px-8 uppercase whitespace-nowrap text-button-lg ${
        pathname.startsWith(href)
          ? 'text-dark-dark transition-colors bg-orange-shade'
          : 'text-white transition-colors bg-dark-dark hover:text-dark-dark hover:bg-orange-shade'
      }`}
    >
      {children}
    </Link>
  );
};
