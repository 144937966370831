import { ArrowIcon } from '@/components/map/property-details/icons/arrow-icon';
import { BoardType, SalesPipeline } from '@/pages/crm/lead-stages/types';
import { StepIcon } from '@/components/map/property-details/icons/step-icon';
import { TickIcon } from '@/components/map/property-details/icons/tick-icon';
import { CloseStopIcon } from '@/components/map/property-details/icons/close-stop-icon';
import { titleMap } from '@/components/crm/board/board-title';

interface StageDetailsProps {
  salesPipeline?: SalesPipeline;
  boardType?: BoardType;
}

export const StageDetails = ({
  boardType,
  salesPipeline,
}: StageDetailsProps) => {
  const currentBoardType = boardType ?? salesPipeline?.boardType;
  const stage =
    currentBoardType === 'LO' ? salesPipeline?.loStage : salesPipeline?.aeStage;
  const isLOWorkflow = currentBoardType === 'LO';
  const firstStage = isLOWorkflow ? 'assigned_lead' : 'potential_lead';
  const secondStage = isLOWorkflow ? 'follow_up' : 'first_call';
  const thirdStage = isLOWorkflow ? 'front_line_processing' : 'second_call';
  const fourthStage = isLOWorkflow ? 'refinancing' : 'third_call';
  const fifthStage = isLOWorkflow ? 'refinanced' : 'interested_party';
  const lostStage = isLOWorkflow ? 'lost' : 'ae_lost';

  if (!salesPipeline) {
    return (
      <div className="flex space-x-3.5 items-center">
        <div className="flex space-x-2.5 items-center">
          <StepIcon value={0} active done />
          <div className="text-label-md">Available</div>
        </div>
      </div>
    );
  }

  if (stage === lostStage) {
    return (
      <div className="flex space-x-2.5 items-center">
        <div className="h-8 w-8">
          <CloseStopIcon />
        </div>
        <div className="text-label-md text-navy-navy uppercase">
          Closed: {salesPipeline.lostReasonDescription}
        </div>
      </div>
    );
  }

  if (stage === fifthStage) {
    return (
      <div className="flex space-x-2.5 items-center">
        <div className="h-8 w-8">
          <TickIcon />
        </div>
        <div className="text-label-md text-navy-navy uppercase">
          Closed: {titleMap[fifthStage]}
        </div>
      </div>
    );
  }

  return (
    <div className="flex space-x-3.5 items-center">
      <div className="flex space-x-2.5 items-center">
        <StepIcon
          value={1}
          done={stage !== firstStage}
          active={stage === firstStage}
        />
        <div className="text-label-md">{titleMap[firstStage]}</div>
      </div>
      <div>
        <ArrowIcon />
      </div>
      <div className="flex space-x-2.5 items-center">
        <StepIcon
          value={2}
          active={stage === secondStage}
          done={![firstStage, secondStage].includes(stage ?? '')}
        />
        <div className="text-label-md text-nowrap">{titleMap[secondStage]}</div>
      </div>
      <div>
        <ArrowIcon />
      </div>
      <div className="flex space-x-2.5 items-center">
        <StepIcon
          value={3}
          active={stage === thirdStage}
          done={![firstStage, secondStage, thirdStage].includes(stage ?? '')}
        />
        <div className="text-label-md text-nowrap">{titleMap[thirdStage]}</div>
      </div>
      <div>
        <ArrowIcon />
      </div>
      <div className="flex space-x-2.5 items-center">
        <StepIcon
          value={4}
          active={stage === fourthStage}
          done={
            ![firstStage, secondStage, thirdStage, fourthStage].includes(
              stage ?? ''
            )
          }
        />
        <div className="text-label-md text-nowrap">{titleMap[fourthStage]}</div>
      </div>
      <div>
        <ArrowIcon />
      </div>
      <div className="flex space-x-2.5 items-center">
        <StepIcon value={5} />
        <div className="text-label-md">{titleMap[fifthStage]}</div>
      </div>
    </div>
  );
};
