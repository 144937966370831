export const StarIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.93437 21L7.55937 13.975L2.10938 9.25L9.30937 8.625L12.1094 2L14.9094 8.625L22.1094 9.25L16.6594 13.975L18.2844 21L12.1094 17.275L5.93437 21Z"
      fill="#FFA53C"
    />
  </svg>
);
