import { AuthLayout } from '@/components/layouts/auth';
import { ResetPasswordFormContainer } from '@/pages/auth/reset-password-page/reset-password-form-container';

export const ResetPasswordPage = () => {
  return (
    <AuthLayout title="Create a new password">
      <ResetPasswordFormContainer />
    </AuthLayout>
  );
};
