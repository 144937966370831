import { useApi } from '@/hooks/use-api';
import { useCallback } from 'react';
import { capitalize } from '@/utils';
import { useToast } from '@/components/ui/use-toast';
import {
  SalesPipeline,
  UpdateSalesPipeline,
} from '@/pages/crm/lead-stages/types';
import { OwnerForm } from '@/components/map/property-details/types';

interface PropertyCardProps {
  afterChangeStage?(options: {
    salesPipelineId: number;
    salesPipeline: SalesPipeline;
    fromStage: string;
    toStage: string;
  }): Promise<void>;
  afterCloseAsLost?(options: {
    salesPipelineId: number;
    lostReasonCode: number;
    lostReasonDescription: string;
  }): Promise<void>;
  afterUpdateSalesPipeline?(options: {
    salesPipeline: SalesPipeline;
  }): Promise<void>;
  afterReactivate?(): Promise<void>;
  afterUpdateSalesPipelineOwner?(): Promise<void>;
}
export const usePropertyCard = ({
  afterChangeStage,
  afterCloseAsLost,
  afterUpdateSalesPipeline,
  afterReactivate,
  afterUpdateSalesPipelineOwner,
}: PropertyCardProps) => {
  const { patchRequest } = useApi();
  const { toast } = useToast();

  const updateSalesPipelineOwner = useCallback(
    async ({
      position,
      salesPipelineId,
      ownerForm,
    }: {
      position: 1 | 2;
      salesPipelineId: number;
      ownerForm: OwnerForm;
    }) => {
      try {
        await patchRequest(
          `/api/sales_pipelines/${salesPipelineId}/update_contact?position=${position}`,
          ownerForm
        );

        await afterUpdateSalesPipelineOwner?.();

        toast({
          variant: 'success',
          description: 'Contact was successfully updated',
          title: 'Contact Updated',
        });
      } catch (error) {
        console.error('Failed to update contact', error);

        const errorMessage =
          (error as Error).message || 'An unknown error occurred';

        toast({
          variant: 'destructive',
          description: errorMessage,
          title: 'Failed to Update Contact',
        });
      }
    },
    [patchRequest, afterUpdateSalesPipelineOwner, toast]
  );

  const changeStage = useCallback(
    async (
      salesPipelineId: number,
      fromStage: string,
      toStage: string,
      passingToUserId?: number
    ) => {
      try {
        const response: SalesPipeline = await patchRequest(
          `/api/sales_pipelines/${salesPipelineId}/change_stage?from_stage=${fromStage}`,
          {
            stage: toStage,
            passingToUserId,
          }
        );

        await afterChangeStage?.({
          salesPipelineId,
          salesPipeline: response,
          fromStage,
          toStage,
        });

        toast({
          variant: 'success',
          description: `Card was successfully moved to ${capitalize(toStage)}${passingToUserId ? ' and assigned' : ''}`,
          title: 'Card Moved',
        });
      } catch (error) {
        console.error('Failed to fetch property details', error);

        const errorMessage =
          (error as Error).message || 'An unknown error occurred';

        toast({
          variant: 'destructive',
          description: errorMessage,
          title: 'Failed to Move Card',
        });
      }
    },
    [patchRequest, afterChangeStage, toast]
  );

  const closeAsLost = useCallback(
    async (
      salesPipelineId: number,
      fromStage: string,
      lostReasonCode: number,
      lostReasonDescription: string
    ) => {
      try {
        await patchRequest(
          `/api/sales_pipelines/${salesPipelineId}/close_as_lost?from_stage=${fromStage}`,
          {
            lostReasonCode,
            lostReasonDescription,
          }
        );

        await afterCloseAsLost?.({
          salesPipelineId,
          lostReasonCode,
          lostReasonDescription,
        });

        toast({
          variant: 'success',
          description: `Card was successfully closed as lost`,
          title: 'Card Closed',
        });
      } catch (error) {
        console.error('Failed to close as lost', error);

        const errorMessage =
          (error as Error).message || 'An unknown error occurred';

        toast({
          variant: 'destructive',
          description: errorMessage,
          title: 'Failed to Close as Lost',
        });
      }
    },
    [afterCloseAsLost, patchRequest, toast]
  );

  const updateSalesPipeline = useCallback(
    async (salesPipelineId: number, data: UpdateSalesPipeline) => {
      try {
        const response: SalesPipeline = await patchRequest(
          `/api/sales_pipelines/${salesPipelineId}`,
          data
        );

        await afterUpdateSalesPipeline?.({ salesPipeline: response });

        toast({
          variant: 'success',
          description: 'Card was successfully updated',
          title: 'Card Updated',
        });
      } catch (error) {
        console.error('Failed to update receptiveness', error);

        const errorMessage =
          (error as Error).message || 'An unknown error occurred';

        toast({
          variant: 'destructive',
          description: errorMessage,
          title: 'Failed to update',
        });
      }
    },
    [afterUpdateSalesPipeline, patchRequest, toast]
  );

  const reactivate = useCallback(
    async (salesPipelineId: number, toStage?: string) => {
      try {
        await patchRequest(
          `/api/sales_pipelines/${salesPipelineId}/reactivate${toStage ? `?to_stage=${toStage}` : ''}`,
          {}
        );

        await afterReactivate?.();

        toast({
          variant: 'success',
          description: 'Card was successfully reactivated',
          title: 'Card Reactivated',
        });
      } catch (error) {
        console.error('Failed to reactivate', error);

        const errorMessage =
          (error as Error).message || 'An unknown error occurred';

        toast({
          variant: 'destructive',
          description: errorMessage,
          title: 'Failed to Reactivate',
        });
      }
    },
    [afterReactivate, patchRequest, toast]
  );

  return {
    changeStage,
    closeAsLost,
    updateSalesPipeline,
    reactivate,
    updateSalesPipelineOwner,
  };
};
