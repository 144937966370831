import { EmailOptOut } from '@/pages/admin/email-opt-out-list/types';
import { getPtDatetimeFormattedString } from '@/utils/date';

export const searchEmailOptOut = (
  searchString: string,
  data: EmailOptOut[]
): EmailOptOut[] => {
  if (searchString.trim() === '') {
    return data;
  }

  const searchWords = searchString.toLowerCase().split(' ');

  return data.filter((internalDnc) => {
    return searchWords.every((word) => {
      return (
        (internalDnc.ownerEmail ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (internalDnc.entryPoint ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (getPtDatetimeFormattedString(internalDnc.timestamp) ?? '')
          .toLowerCase()
          .includes(word.toLowerCase())
      );
    });
  });
};
