import { FilterContainer } from '@/components/filters/sections/filter-container';
import { DateRange } from 'react-day-picker';
import { TwoFieldsDateRangeSelector } from '@/components/filters/sections/two-fields-date-range-selector';

export interface SaleDateFilterProps {
  selectedSaleDateRange: DateRange;
  onSelectSaleDateRange: (selectedSaleDateRange: DateRange) => void;
  defaultClose?: boolean;
  onChange?: (isOpen: boolean) => void;
}

export const SaleDateFilter = ({
  selectedSaleDateRange,
  onSelectSaleDateRange,
  defaultClose,
  onChange,
}: SaleDateFilterProps) => {
  return (
    <FilterContainer
      value="state"
      title={
        <div className="text-heading-05 text-dark-dark">Sale Date Range</div>
      }
      defaultClose={defaultClose}
      onChange={onChange}
    >
      <TwoFieldsDateRangeSelector
        selectedDateRange={selectedSaleDateRange}
        onSelectDateRange={onSelectSaleDateRange}
        hideIcon
      />
    </FilterContainer>
  );
};
