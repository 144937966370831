import { useState, useEffect, FormEvent, useCallback } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { X } from 'lucide-react';
import { EditIcon } from '@/components/icons';
import { AppSettingResponse } from '@/pages/data-pipeline/api-usage/types';

interface EditRecipientEmailListDialogProps {
  appSetting: AppSettingResponse;
  updateAppSetting(newAppSetting: Partial<AppSettingResponse>): Promise<void>;
}

export const EditRecipientEmailListDialog = ({
  appSetting,
  updateAppSetting,
}: EditRecipientEmailListDialogProps) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [newEmail, setNewEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isExisting, setIsExisting] = useState(false);

  useEffect(() => {
    setEmails(appSetting?.notificationEmails ?? []);
    const lowercaseNewEmail = newEmail.toLowerCase();
    if (emails.map((e) => e.toLowerCase()).includes(lowercaseNewEmail)) {
      setError('This email already exists in the list.');
      setIsExisting(true);
    } else {
      setError(null);
      setIsExisting(false);
    }
  }, [newEmail, emails, appSetting?.notificationEmails]);

  const addEmail = async (e: FormEvent) => {
    e.preventDefault();
    const lowercaseEmail = newEmail.toLowerCase();
    if (
      lowercaseEmail &&
      !emails.map((e) => e.toLowerCase()).includes(lowercaseEmail)
    ) {
      const newEmails = [...emails, lowercaseEmail];
      setEmails(newEmails);
      setNewEmail('');
      await updateAppSetting({ notificationEmails: newEmails });
      setError(null);
    }
  };

  const removeEmail = useCallback(
    async (email: string) => {
      const newEmails = emails.filter((e) => e !== email);
      setEmails(newEmails);
      await updateAppSetting({ notificationEmails: newEmails });
    },
    [emails, updateAppSetting]
  );

  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="w-6 h-6">
          <EditIcon />
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Recipient Emails</DialogTitle>
          <DialogDescription>
            Manage your recipient email list by adding or removing email
            address.
          </DialogDescription>
        </DialogHeader>
        <div className="py-4">
          <h4 className="mb-4 text-sm font-medium">Current Emails:</h4>
          {emails.length === 0 ? (
            <p className="text-sm text-muted-foreground">
              No emails added yet.
            </p>
          ) : (
            <ul className="space-y-2 overflow-y-auto">
              {emails.map((email) => (
                <li
                  key={email}
                  className="flex items-center justify-between rounded-md border p-2"
                >
                  <span className="text-sm">{email}</span>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => removeEmail(email)}
                    aria-label={`Remove ${email}`}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <form onSubmit={addEmail} className="flex flex-col gap-2 pt-4 border-t">
          <div className="flex items-end gap-2">
            <div className="flex-1">
              <Label htmlFor="email" className="sr-only">
                Email
              </Label>
              <Input
                id="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Enter an email address"
                type="email"
                aria-invalid={isExisting}
                aria-describedby={error ? 'email-error' : undefined}
              />
            </div>
            <Button type="submit" disabled={isExisting || !newEmail}>
              Add
            </Button>
          </div>
          {error && (
            <p
              id="email-error"
              className="text-sm text-destructive"
              role="alert"
            >
              {error}
            </p>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};
