import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useToast } from '@/components/ui/use-toast';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { useApi } from '@/hooks/use-api';

interface DeleteUserFormProps {
  id: number;
  firstName: string;
  lastName: string;
}

interface DeleteUserAlertDialogProps {
  onUserDeleted: () => void;
  open: boolean;

  onOpenChange(open: boolean): void;

  deleteUserForm: DeleteUserFormProps;
}

export const DeleteUserAlertDialog = ({
  onUserDeleted,
  open,
  onOpenChange,
  deleteUserForm,
}: DeleteUserAlertDialogProps) => {
  const { deleteRequest } = useApi();
  const { toast } = useToast();

  const { mutateAsync: deleteUser } = useMutation({
    mutationFn: () => deleteRequest(`/api/users/${deleteUserForm.id}`),
    onSuccess: () => {
      onUserDeleted();
      onOpenChange(false);
    },
  });

  const onDelete = React.useCallback(async () => {
    await deleteUser();
    toast({
      variant: 'success',
      description: `User '${deleteUserForm.firstName} ${deleteUserForm.lastName}' was successfully deleted`,
      title: 'User Deleted',
    });
  }, [deleteUserForm.firstName, deleteUserForm.lastName, deleteUser, toast]);

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete the user
            '{deleteUserForm.firstName}' '{deleteUserForm.lastName}'.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={async () => await onDelete()}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
