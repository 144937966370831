import {
  MapProperty,
  Property,
} from '@/pages/admin/property-data/property-table';
import {
  SalesPipeline,
  UpdateSalesPipeline,
} from '@/pages/crm/lead-stages/types';
import { Owners } from '@/components/map/property-details/owners';
import { GeneralDetails } from '@/components/map/property-details/general-details';
import { NotesTaskDetails } from '@/components/map/property-details/notes-task-details';
import { OwnerForm } from '@/components/map/property-details/types';

interface MainSectionProps {
  mapProperty?: MapProperty;
  property?: Property;
  salesPipeline?: SalesPipeline;
  updateSalesPipelineOwner?(params: {
    position: 1 | 2;
    ownerForm: OwnerForm;
  }): Promise<void>;
  updateSalesPipeline?(
    salesPipelineId: number,
    data: UpdateSalesPipeline
  ): Promise<void>;
}

export const MainSection = ({
  mapProperty,
  property,
  salesPipeline,
  updateSalesPipelineOwner,
  updateSalesPipeline,
}: MainSectionProps) => {
  return (
    <div className="flex space-x-8">
      <div className="w-[320px]">
        <Owners
          property={property}
          salesPipeline={salesPipeline}
          updateSalesPipelineOwner={updateSalesPipelineOwner}
        />
      </div>

      <div>
        <GeneralDetails
          mapProperty={mapProperty}
          property={salesPipeline?.property ?? property}
          salesPipeline={salesPipeline}
        />
      </div>

      {salesPipeline && (
        <div className="w-[360px]">
          <NotesTaskDetails
            salesPipeline={salesPipeline}
            updateSalesPipeline={updateSalesPipeline}
          />
        </div>
      )}
    </div>
  );
};
