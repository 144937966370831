import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/use-api';
import { Loader } from '@/components';
import { ReactTable } from '@/components/table/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle, DownloadIcon, Plus } from 'lucide-react';
import { DataTableColumnHeader } from '@/components/table/data-table-column-header';
import { SearchIcon } from '@/pages/crm/lead-stages/icons/search-icon';
import { Button } from '@/components/ui/button';
import { toISODate } from '@/utils/date';
import { exportCsv } from '@/utils/csv';
import { Lender, LenderListResponse } from '@/pages/admin/lender-list/types';
import { searchLender } from '@/pages/admin/lender-list/utils';
import { EditIcon } from '@/components/icons';
import { EditLenderDialog } from '@/pages/admin/lender-list/edit-lender-dialog';
import { RankingMap } from '@/components/filters/sections/lender-ranking-filter';
import { LeaderCompetitiveness } from '@/pages/admin/property-data/property-table';
import { formatInteger } from '@/utils/number';

export const LenderListContainer = () => {
  const { getRequest } = useApi();
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [isCreateMode, setIsCreateMode] = React.useState(false);
  const [lenderList, sendLenderList] = useState<Lender[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [editLenderForm, setEditLenderForm] = React.useState<Lender>({
    id: 0,
    name: '',
    ranking: 0,
  });
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [search, setSearch] = useState<string>('');
  const columns: ColumnDef<Lender>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        meta: {
          title: 'ID',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => <div>{row.getValue('id')}</div>,
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: 'name',
        meta: {
          title: 'Name',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => <div>{row.getValue('name')}</div>,
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: 'ranking',
        meta: {
          title: 'Competitiveness Ranking',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => {
          const ranking = row.getValue('ranking') as LeaderCompetitiveness;

          return <div>{`${ranking} - ${RankingMap[ranking]}`}</div>;
        },
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: 'activePropertyCount',
        meta: {
          title: 'Number of Properties',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => (
          <div>{formatInteger(row.getValue('activePropertyCount'))}</div>
        ),
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: 'percentageOfProperties',
        meta: {
          title: 'Percentage of Properties',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => (
          <div>{formatInteger(row.getValue('percentageOfProperties'))}%</div>
        ),
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: 'edit',
        meta: {
          title: 'Edit',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} />,
        cell: ({ row }) => {
          const lender = row.original as Lender;
          return (
            <button
              className="w-6 h-6"
              onClick={() => {
                setEditLenderForm(lender);
                setIsCreateMode(false);
                setOpenEditDialog(true);
              }}
            >
              <EditIcon />
            </button>
          );
        },
        enableSorting: false,
        enableHiding: false,
      },
    ],
    []
  );

  const { isPending, error, data, refetch } = useQuery<LenderListResponse>({
    queryKey: ['lender-list'],
    queryFn: () => getRequest('/api/data_pipeline/lenders'),
  });

  useEffect(() => {
    if (data) {
      sendLenderList(searchLender(search, data.data));
    }
  }, [search, data]);

  const table = useReactTable<Lender>({
    data: lenderList ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
      pagination,
    },
    enableRowSelection: true,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <div className="flex justify-between items-center w-full py-2 rounded-lg bg-white">
        <div className="relative w-full">
          <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="text"
            id="simple-search"
            autoComplete="off"
            className="h-8 w-[420px] text-label-md bg-gray-50 border border-gray-300 rounded ps-9 p-1 normal-case"
            placeholder="SEARCH BY NAME..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="ml-auto gap-1"
            onClick={() => {
              setEditLenderForm({ id: 0, name: '', ranking: 0 });
              setIsCreateMode(true);
              setOpenEditDialog(true);
            }}
          >
            <span>Create Lender</span>
            <Plus className="h-4 w-4" />
          </Button>
          <Button
            className="flex space-x-2"
            onClick={() => {
              const formattedDate = toISODate(new Date());
              exportCsv(`lenders_${formattedDate}`, table);
            }}
          >
            <span>
              <DownloadIcon className="h-4 w-4" />
            </span>
            <span>Download as CSV</span>
          </Button>
        </div>
      </div>
      <div className="bg-white">
        {isPending && <Loader />}
        {!isPending && lenderList && <ReactTable table={table} />}
      </div>
      <EditLenderDialog
        onLenderUpdated={async () => {
          await refetch();
        }}
        lender={editLenderForm}
        open={openEditDialog}
        onOpenChange={setOpenEditDialog}
        isCreateMode={isCreateMode}
      />
    </>
  );
};
