import { Lender } from '@/pages/admin/lender-list/types';

export const searchLender = (
  searchString: string,
  data: Lender[]
): Lender[] => {
  if (searchString.trim() === '') {
    return data;
  }

  const searchWords = searchString.toLowerCase().split(' ');

  return data.filter((internalDnc) => {
    return searchWords.every((word) => {
      return (internalDnc.name ?? '')
        .toLowerCase()
        .includes(word.toLowerCase());
    });
  });
};
