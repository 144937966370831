import type { EChartsOption, BarSeriesOption } from 'echarts';
import { ECharts } from '@/components/charts/market-opportunity-chart/e-charts';
import {
  LostReasonCode,
  LostReasonMap,
  AllLostReasons,
  LostReasonCodeMap,
  LostReasonColorMap,
} from '@/components/crm/types';

interface LostReasonStatsByMonth {
  date: string;
  lostReasons: Record<LostReasonCode, number>;
}

interface TeamLossReasonChartProps {
  data: LostReasonStatsByMonth[];
}

export const TeamLossReasonChart = ({ data }: TeamLossReasonChartProps) => {
  const sortedReasons = AllLostReasons.slice().reverse();
  const totalReasons: Record<LostReasonCode, number> = Object.keys(
    LostReasonMap
  ).reduce(
    (acc, code) => {
      const reasonCode = parseInt(code) as LostReasonCode;
      acc[reasonCode] = data.reduce(
        (sum, month) => sum + (month.lostReasons[reasonCode] || 0),
        0
      );
      return acc;
    },
    {} as Record<LostReasonCode, number>
  );

  const seriesData: BarSeriesOption[] = [
    {
      type: 'bar',
      data: sortedReasons.map(
        (reason) => totalReasons[LostReasonCodeMap[reason]] || 0
      ),
      itemStyle: {
        color: (params) => {
          return LostReasonColorMap[sortedReasons[params.dataIndex]];
        },
      },
    },
  ];

  const option: EChartsOption = {
    grid: {
      left: '150px',
    },
    yAxis: {
      type: 'category',
      data: sortedReasons,
    },
    xAxis: {
      type: 'value',
    },
    series: seriesData,
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const tooltipParams = params as unknown as {
          axisValue: string;
          marker: string;
          data: number;
        }[];
        let tooltipText = `${tooltipParams[0].axisValue}<br/>`;
        tooltipParams.forEach((item) => {
          tooltipText += `${item.marker} ${item.data}<br/>`;
        });
        return tooltipText;
      },
    },
    legend: {
      data: ['Total'],
    },
  };

  return (
    <div className="h-[400px] mt-6">
      {data ? (
        <ECharts option={option} style={{ height: 450 }} />
      ) : (
        <div style={{ height: 450 }}>Loading...</div>
      )}
    </div>
  );
};
