import { PropertyTable } from './property-table';
import { Sidebar } from '@/pages/settings/common/sidebar';
import { Card, CardContent } from '@/components/ui/card';

export const PropertyData = () => {
  return (
    <Sidebar currentPage="property-data">
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-heading-01">Property Data</div>
        </div>

        <Card>
          <CardContent>
            <PropertyTable />
          </CardContent>
        </Card>
      </div>
    </Sidebar>
  );
};
