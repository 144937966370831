export const BellIcon = ({ active }: { active?: boolean }) => (
  <svg
    width="20"
    height="25"
    viewBox="0 0 20 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="group"
  >
    <path
      d="M0.398438 20.7506V18.3506H2.79844V9.95059C2.79844 8.29059 3.29844 6.81579 4.29844 5.52619C5.29844 4.23659 6.59844 3.39139 8.19844 2.99059V0.350586H11.7984V2.99059C13.3984 3.39059 14.6984 4.23579 15.6984 5.52619C16.6984 6.81659 17.1984 8.29139 17.1984 9.95059V18.3506H19.5984V20.7506H0.398438ZM9.99844 24.3506C9.33844 24.3506 8.77364 24.1158 8.30404 23.6462C7.83444 23.1766 7.59924 22.6114 7.59844 21.9506H12.3984C12.3984 22.6106 12.1636 23.1758 11.694 23.6462C11.2244 24.1166 10.6592 24.3514 9.99844 24.3506Z"
      className={`${active ? 'fill-orange-shade' : 'fill-light-tint'} group-hover:fill-orange-shade`}
    />
  </svg>
);
