import { Label } from '@/components/ui/label';
import { Checkbox } from '@/components/ui/checkbox';

interface CheckboxWithLabelProps {
  id?: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const CheckboxWithLabel = ({
  id,
  label,
  checked,
  onClick,
  disabled,
}: CheckboxWithLabelProps) => {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={id ?? label}
        checked={checked}
        onClick={onClick}
        disabled={disabled}
      />
      <Label
        htmlFor={id ?? label}
        className={
          checked
            ? 'text-label-md text-dark-shade'
            : 'text-paragraph-md text-dark-dark'
        }
      >
        {label}
      </Label>
    </div>
  );
};
