import { DataImport } from '@/pages/data-pipeline/run-history/types';
import { formatDateVerbose, getPtDatetimeFormattedString } from '@/utils/date';
import { Badge, BadgeProps } from '@/components/ui/badge';
import { formatInteger } from '@/utils/number';
import { formatShortestMoney, isNational, stateMap } from '@/utils';
import {
  OwnershipTypes,
  SellerCarrybacks,
} from '@/pages/data-pipeline/manual-data-import/types';

interface RunHistoryDetailsProps {
  dataImport: DataImport;
}

const statusVariantMap: { [key: string]: BadgeProps['variant'] } = {
  COMPLETED: 'green',
  FAILED: 'red',
  IN_PROGRESS: 'default',
  PENDING: 'blue',
};

export const RunHistoryDetails = ({ dataImport }: RunHistoryDetailsProps) => {
  const national = isNational(dataImport.states);
  return (
    <div className="flex items-center justify-between mb-2 space-x-12">
      <div className="space-y-4">
        <div className="text-heading-04 text-navy-navy">
          {getPtDatetimeFormattedString(dataImport.createdAt)}
        </div>
        <div className="flex flex-wrap gap-4">
          <Badge variant="blue" className="inline-block whitespace-nowrap">
            Mtg. Amount: {formatShortestMoney(dataImport.mortgageAmountFrom)}-
            {formatShortestMoney(dataImport.mortgageAmountTo)}
          </Badge>
          <Badge variant="blue" className="inline-block whitespace-nowrap">
            IR: {dataImport.interestRateFrom}%-{dataImport.interestRateTo}%
          </Badge>
          <Badge variant="blue" className="inline-block whitespace-nowrap">
            Last Sale Date: {formatDateVerbose(dataImport.lastSaleDateFrom)} -
            {formatDateVerbose(dataImport.lastSaleDateTo)}
          </Badge>
          <Badge variant="blue" className="inline-block whitespace-nowrap">
            Txn Type: Arms-Length Sale
          </Badge>
          <Badge variant="blue" className="inline-block whitespace-nowrap">
            Mtg. Type: {dataImport.mortgageType.join(', ')}
          </Badge>
          <Badge variant="blue" className="inline-block whitespace-nowrap">
            Seller Carrybacks: {SellerCarrybacks[dataImport.sellerCarryback]}
          </Badge>
          <Badge variant="blue" className="inline-block whitespace-nowrap">
            Ownership Type: {OwnershipTypes[dataImport.ownershipType]}
          </Badge>
          {national ? (
            <Badge variant="blue" className="inline-block whitespace-nowrap">
              National
            </Badge>
          ) : (
            dataImport.states.map((state) => (
              <Badge
                key={state}
                variant="blue"
                className="inline-block whitespace-nowrap"
              >
                {stateMap[state]}
              </Badge>
            ))
          )}
        </div>
        <div>Imported by: {dataImport.userFullName}</div>
        <div>
          Status:{' '}
          <Badge variant={statusVariantMap[dataImport.status] ?? 'default'}>
            {dataImport.status}
          </Badge>
        </div>
      </div>
      <div className="bg-light-light w-[300px] h-full rounded-md">
        <div className="space-y-2 p-8 flex flex-col justify-center items-center">
          <div className="text-display-01 text-navy-navy">
            {formatInteger(
              dataImport.propertyRetrievedCount === 0
                ? dataImport.count
                : dataImport.propertyRetrievedCount
            )}
          </div>
          <div className="text-heading-04 text-navy-navy">Properties</div>
        </div>
      </div>
    </div>
  );
};
