import { SalesFunnelContainer } from '@/pages/crm/sales-funnel/sales-funnel-container';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@/context';
import { useEffect } from 'react';
import { Loader } from '@/components';

export const SalesFunnel = () => {
  const { type } = useParams<{ type: string }>();
  const navigate = useNavigate();
  const { userDetails } = useAuth();

  useEffect(() => {
    if (!type) {
      if (userDetails.isSuperuser) {
        navigate('/sales-funnel/lo', { replace: true });
      } else {
        navigate(`/sales-funnel/${userDetails.userType.toLocaleLowerCase()}`, {
          replace: true,
        });
      }
    }
  }, [type, userDetails, navigate]);

  if (!['lo', 'ae'].includes(type ?? '')) {
    return <Loader />;
  }

  return <SalesFunnelContainer boardType={type === 'ae' ? 'AE' : 'LO'} />;
};
