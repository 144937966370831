import { EmailTemplate } from '@/pages/admin/email-templates/types';
import { EmailTemplateEditor } from '@/components/editors/email-template-editor';
import { Label } from '@/components/ui/label';

interface EmailTemplateFormProps {
  editMode: boolean;
  emailTemplate: EmailTemplate;
  onChange: (emailTemplate: EmailTemplate) => void;
}

export const EmailTemplateForm = ({
  emailTemplate,
  onChange,
  editMode,
}: EmailTemplateFormProps) => {
  return (
    <div className="space-y-6 bg-transparent">
      <div className="space-y-1">
        <Label htmlFor="subject">Subject</Label>
        <EmailTemplateEditor
          content={emailTemplate.subjectJson}
          disabled={!editMode}
          onContentChange={(content, text) => {
            onChange({
              ...emailTemplate,
              subjectJson: content,
              subjectText: text,
            });
          }}
        />
      </div>
      <div className="space-y-1">
        <Label htmlFor="subject">Body</Label>
        <EmailTemplateEditor
          content={emailTemplate.bodyJson}
          disabled={!editMode}
          onContentChange={(content, text) => {
            onChange({
              ...emailTemplate,
              bodyJson: content,
              bodyText: text,
            });
          }}
        />
      </div>
    </div>
  );
};
