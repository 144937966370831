export const ArrowIcon = () => (
  <svg
    width="8"
    height="11"
    viewBox="0 0 8 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.758545 10.0164L1.74188 10.9998L7.24186 5.49976L1.74188 -0.000244141L0.758545 0.983095L5.27518 5.49976L0.758545 10.0164Z"
      fill="#192A68"
    />
  </svg>
);
