import { Property } from '@/pages/admin/property-data/property-table';

export const searchProperties = (
  searchString: string,
  data: Property[]
): Property[] => {
  if (searchString.trim() === '') {
    return data;
  }

  const searchWords = searchString.toLowerCase().split(' ');

  return data.filter((property) => {
    return searchWords.every((word) => {
      return (
        (property.firstCurrentOwnerFirstName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.firstCurrentOwnerLastName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.secondCurrentOwnerFirstName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.secondCurrentOwnerLastName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.situsFullAddress ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.firstCurrentOwnerPhoneNumber ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.firstCurrentOwnerPhoneNumber2 ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.secondCurrentOwnerPhoneNumber ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.secondCurrentOwnerPhoneNumber2 ?? '')
          .toLowerCase()
          .includes(word.toLowerCase())
      );
    });
  });
};
