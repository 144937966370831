import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { LeadStagesContainer } from '@/pages';
import { useAuth } from '@/context';

export const LeadStages = () => {
  const { type } = useParams<{ type: string }>();
  const navigate = useNavigate();
  const { userDetails } = useAuth();

  useEffect(() => {
    if (!type) {
      if (userDetails.isSuperuser) {
        navigate('/lead-stages/lo', { replace: true });
      } else {
        navigate(`/lead-stages/${userDetails.userType.toLocaleLowerCase()}`, {
          replace: true,
        });
      }
    }
  }, [type, userDetails, navigate]);

  return <LeadStagesContainer type={type === 'ae' ? 'AE' : 'LO'} />;
};
