import { Badge } from '@/components/ui/badge';

interface DncRegisteredCellProps {
  value?: 'Y' | 'N';
}

export const DncRegisteredCell = ({ value }: DncRegisteredCellProps) => {
  if (value === 'Y') {
    return <Badge variant="red">Yes</Badge>;
  } else if (value === 'N') {
    return <Badge variant="green">No</Badge>;
  }

  return value ?? '';
};
