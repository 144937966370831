import { useParams } from 'react-router-dom';
import { Sidebar } from '@/pages/settings/common/sidebar';
import { useApi } from '@/hooks/use-api';
import { DataImport } from '@/pages/data-pipeline/run-history/types';
import { Loader } from '@/components';
import { RunHistoryDetails } from '@/pages/data-pipeline/run-history/run-history-details';
import { StepIcon } from '@/components/map/property-details/icons/step-icon';
import { ArrowIcon } from '@/components/map/property-details/icons/arrow-icon';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { getPtDatetimeFormattedString } from '@/utils/date';

import { useCallback, useEffect, useState } from 'react';
import { PropertyTable } from '@/pages/admin/property-data/property-table';
import { DataImportErrors } from '@/pages/data-pipeline/run-history/data-import-errors';

export const DataImportDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { getRequest, postRequest } = useApi();
  const { toast } = useToast();

  const [data, setData] = useState<DataImport | null>(null);
  const [isPending, setIsPending] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const fetchData = useCallback(async () => {
    setIsPending(true);
    const response = await getRequest(`/api/data_pipeline/data_imports/${id}`);
    setData(response);
    setIsPending(false);
  }, [getRequest, id]);

  useEffect(() => {
    (async () => await fetchData())();
    const interval = setInterval(() => {
      if (data?.status !== 'COMPLETED') {
        (async () => await fetchData())();
      } else {
        clearInterval(interval);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [data?.status, fetchData]);

  const runImportJob = async () => {
    setIsUpdating(true);
    setIsButtonVisible(false);
    await postRequest(`/api/data_pipeline/data_imports/${id}/run`, {});
    toast({
      variant: 'success',
      description: 'Import data has been triggered successfully.',
      title: 'Run Import Data',
    });
    setIsUpdating(false);
    await fetchData();
  };

  return (
    <Sidebar currentPage="manual-data-import">
      <div className="flex flex-col space-y-4 bg-white">
        <div>
          <div className="text-heading-01">Manual Data Import Details</div>
        </div>
        {data && (
          <div className="space-y-4">
            <RunHistoryDetails dataImport={data} />
            {data.status !== 'COMPLETED' &&
              data.status !== 'IN_PROGRESS' &&
              isButtonVisible && (
                <div>
                  <Button
                    variant="default"
                    onClick={runImportJob}
                    disabled={isUpdating}
                  >
                    Run
                  </Button>
                </div>
              )}
            <div className="mt-6 pb-12">
              <div className="flex space-x-3.5 items-center">
                {data.steps.map((step, index) => (
                  <TooltipProvider key={step.key}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="flex space-x-3.5 items-center">
                          <div className="flex space-x-2.5 items-center">
                            <StepIcon
                              value={index + 1}
                              failed={step.status === 'FAILED'}
                              done={step.status === 'COMPLETED'}
                              active={step.status === 'IN_PROGRESS'}
                            />
                            <div className="text-label-md">{step.name}</div>
                          </div>
                          {index < data.steps.length - 1 && (
                            <div>
                              <ArrowIcon />
                            </div>
                          )}
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <div>
                          <p>Status: {step.status}</p>
                          {step.startedAt && (
                            <p>
                              Started:{' '}
                              {getPtDatetimeFormattedString(step.startedAt)}
                            </p>
                          )}
                          {step.status === 'COMPLETED' && step.completedAt && (
                            <p>
                              Completed:{' '}
                              {getPtDatetimeFormattedString(step.completedAt)}
                            </p>
                          )}
                          {step.status === 'FAILED' && step.failedAt && (
                            <p>
                              Failed:{' '}
                              {getPtDatetimeFormattedString(step.failedAt)}
                            </p>
                          )}
                          {step.status === 'FAILED' && step.error && (
                            <p>Error: {step.error}</p>
                          )}
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ))}
              </div>
            </div>

            {data.status === 'COMPLETED' && (
              <>
                {id && <DataImportErrors dataImportId={id} />}
                <h2 className="text-heading-03">Imported Data</h2>
                <PropertyTable dataImportId={parseInt(id ?? '0')} />
              </>
            )}
          </div>
        )}
        {isPending && <Loader />}
      </div>
    </Sidebar>
  );
};
