import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { DropdownButtonIcon } from '@/components/map/property-details/icons/dropdown-button-icon';
import { User } from '@/pages';
import { useAuth } from '@/context';
import { BoardType } from '@/pages/crm/lead-stages/types';

interface UserDropdownProps {
  selectedUser?: User;
  users: User[];
  onUserChange: (user: User) => void;
  filterOutCurrentUser?: boolean;
  alwaysIncludeCurrentUser?: boolean;
  borderType?: BoardType;
}

export const UserDropdown = ({
  selectedUser,
  users,
  filterOutCurrentUser,
  onUserChange,
  borderType,
  alwaysIncludeCurrentUser,
}: UserDropdownProps) => {
  const { userDetails } = useAuth();

  let filteredUsers = users.filter((u) => u.isActive);
  if (filterOutCurrentUser) {
    filteredUsers = filteredUsers.filter((u) => u.email === userDetails.email);
  }

  if (borderType) {
    filteredUsers = filteredUsers.filter((u) => {
      if (alwaysIncludeCurrentUser && u.email === userDetails.email) {
        return true;
      }

      return u.userType === borderType;
    });
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="blue">
          <span>
            {selectedUser?.firstName} {selectedUser?.lastName}
            {' - '}
            {selectedUser?.isSuperuser ? 'Admin' : selectedUser?.userType}
          </span>
          <span>
            <DropdownButtonIcon />
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-48 bg-blue-tint text-blue-shade max-h-80 overflow-y-auto">
        <DropdownMenuGroup>
          {filteredUsers.map((user) => (
            <DropdownMenuCheckboxItem
              checked={user.id === selectedUser?.id}
              key={user.id}
              className="focus:text-blue-tint focus:bg-blue-blue"
              onClick={() => onUserChange(user)}
            >
              {user.firstName} {user.lastName}
              {' - '}
              {user.isSuperuser ? 'Admin' : user.userType}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
