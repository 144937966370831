export const AddressIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.06689 8.47066L12.0001 1.53748L18.9333 8.47066L14.6 12.8039L12.0001 18.8704L9.40013 12.8039L5.06689 8.47066Z"
      fill="#192A68"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.01166 18.0657L8.78028 15.7583L9.46327 17.3519L5.66704 18.8704L12 21.4036L18.333 18.8704L14.5365 17.3518L15.2194 15.7582L20.9883 18.0657L23 18.8704L20.9883 19.6751L12.3219 23.1417L12 23.2704L11.6781 23.1417L3.01166 19.6751L1 18.8704L3.01166 18.0657Z"
      fill="#192A68"
    />
  </svg>
);
