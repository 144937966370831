import { NewLeadDialog } from '@/components';
import { ManualProperty } from '@/pages/admin/property-data/property-table';

interface NewLeadDialogContainerProps {
  open: boolean;
  onClose(): void;
  createNewLead?(newLead: ManualProperty): Promise<void>;
  isCreating?: boolean;
}

export const NewLeadDialogContainer = ({
  open,
  onClose,
  createNewLead,
  isCreating,
}: NewLeadDialogContainerProps) => {
  return (
    <NewLeadDialog
      open={open}
      onClose={onClose}
      createNewLead={createNewLead}
      isCreating={isCreating}
    />
  );
};
