export const SettingIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.1956 12.3514L3.94818 13.8497L2.14827 12.0498L3.6465 9.80242L0.998047 9.27273V6.72727L3.64663 6.19756L2.1484 3.9502L3.9483 2.15029L6.19562 3.6485L6.72532 1H9.27077L9.80047 3.64847L12.0478 2.15027L13.8477 3.95018L12.3494 6.19755L14.998 6.72727V9.27273L12.3496 9.80242L13.8478 12.0498L12.0479 13.8497L9.80049 12.3514L9.27077 15H6.72532L6.1956 12.3514ZM7.99805 11.5C9.93104 11.5 11.498 9.933 11.498 8C11.498 6.067 9.93104 4.5 7.99805 4.5C6.06505 4.5 4.49805 6.067 4.49805 8C4.49805 9.933 6.06505 11.5 7.99805 11.5Z"
      fill="#192A68"
    />
    <circle cx="8.00065" cy="7.99967" r="1.16667" fill="#192A68" />
  </svg>
);
