import { SalesFunnel } from '@/pages/crm/sales-funnel/types';
import { BoardType } from '@/pages/crm/lead-stages/types';

interface SalesFunnelChartProps {
  data?: SalesFunnel;
  boardType: BoardType;
}

export const SalesFunnelChart = ({
  data,
  boardType,
}: SalesFunnelChartProps) => {
  return (
    <svg
      width="540"
      height="440"
      viewBox="0 0 540 460"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M540 -0.000934515L0 -0.000976562L38.8187 83.9404L501.181 83.9404L540 -0.000934515Z"
        fill="#192A68"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M496.523 93.9405L457.704 177.883L82.2962 177.883L43.4771 93.9404L496.523 93.9405Z"
        fill="#05597C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M453.046 187.883L414.227 271.825L125.773 271.825L86.9541 187.883L453.046 187.883Z"
        fill="#0885BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M409.568 281.825L370.749 365.767L169.251 365.767L130.431 281.825L409.568 281.825Z"
        fill="#056B3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.908 375.767L212.727 459.71H327.273L366.092 375.767L173.908 375.767Z"
        fill="#08A057"
      />
      <text
        x="50%"
        y="16"
        fontFamily="PP Telegraf"
        fontSize="12"
        fill="#FEFEFF"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {boardType === 'LO' ? 'ASSIGNED LEADS' : 'POTENTIAL LEADS'}
      </text>
      <text
        x="50%"
        y="52"
        fontFamily="PP Telegraf"
        fontSize="48"
        fill="#FEFEFF"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {data?.assignedLeads ?? 0}
      </text>
      <text
        x="50%"
        y="112"
        fontFamily="PP Telegraf"
        fontSize="12"
        fill="#FEFEFF"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {boardType === 'LO' ? 'FOLLOW-UP' : 'FIRST CALL'}
      </text>
      <text
        x="50%"
        y="148"
        fontFamily="PP Telegraf"
        fontSize="48"
        fill="#FEFEFF"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {data?.followUp ?? 0}
      </text>

      <text
        x="50%"
        y="208"
        fontFamily="PP Telegraf"
        fontSize="12"
        fill="#FEFEFF"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {boardType === 'LO' ? 'FRONT LINE PROCESSING' : 'SECOND CALL'}
      </text>
      <text
        x="50%"
        y="240"
        fontFamily="PP Telegraf"
        fontSize="48"
        fill="#FEFEFF"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {data?.frontLineProcessing ?? 0}
      </text>
      <text
        x="50%"
        y="300"
        fontFamily="PP Telegraf"
        fontSize="12"
        fill="#FEFEFF"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {boardType === 'LO' ? 'REFINANCING' : 'THIRD CALL'}
      </text>
      <text
        x="50%"
        y="335"
        fontFamily="PP Telegraf"
        fontSize="48"
        fill="#FEFEFF"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {data?.refinancing ?? 0}
      </text>
      <text
        x="50%"
        y="392"
        fontFamily="PP Telegraf"
        fontSize="12"
        fill="#FEFEFF"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {boardType === 'LO' ? 'REFINANCED' : 'INTERESTED PARTY'}
      </text>
      <text
        x="50%"
        y="430"
        fontFamily="PP Telegraf"
        fontSize="48"
        fill="#FEFEFF"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {data?.refinanced ?? 0}
      </text>
    </svg>
  );
};
