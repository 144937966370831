import { useCallback, useEffect, useState } from 'react';
import { Calendar } from '@/components/ui/calendar';
import { TimeDropdown } from '@/components/map/property-details/time-dropdown';
import { Button } from '@/components/ui/button';
import {
  createLocalDatetime,
  formatDateVerbose,
  getPacificTimeZone,
  getPtDatetime,
} from '@/utils/date';

interface DueDateSettingProps {
  dueDatetime: string;
  setDueDatetime(datetime: string): void;
  onCancel(): void;
}

interface FormState {
  date: string;
  time: string;
}

export const DueDateSetting = ({
  dueDatetime,
  setDueDatetime,
  onCancel,
}: DueDateSettingProps) => {
  const [pstDatetime, setpstDatetime] = useState<FormState>({
    date: '',
    time: '',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setpstDatetime(getPtDatetime(dueDatetime));
  }, [dueDatetime]);

  const handleDateChange = useCallback((newDate: Date | undefined) => {
    if (!newDate) return;

    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    setpstDatetime((prevState) => ({
      ...prevState,
      date: formattedDate,
    }));
  }, []);

  const handleTimeChange = useCallback((time: string) => {
    setpstDatetime((prevState) => ({
      ...prevState,
      time,
    }));
  }, []);

  const handleApply = useCallback(() => {
    const currentDateTime = new Date();
    const localDatetimeString = `${pstDatetime.date}T${pstDatetime.time}-0${getPacificTimeZone(pstDatetime.date) === 'PDT' ? 7 : 8}:00`;
    const localDatetime = new Date(localDatetimeString);

    if (localDatetime && localDatetime < currentDateTime) {
      setErrorMessage('Due date cannot be in the past.');
      return false;
    }
    setDueDatetime(localDatetimeString);
    return true;
  }, [pstDatetime.date, pstDatetime.time, setDueDatetime]);

  const handleCancel = () => {
    setErrorMessage(null);
    setpstDatetime(getPtDatetime(dueDatetime));
    onCancel();
  };

  return (
    <div className="flex flex-col space-y-4">
      <Calendar
        mode="single"
        selected={createLocalDatetime(pstDatetime.date)}
        onSelect={handleDateChange}
        initialFocus
      />

      <div className="flex justify-between items-center px-3">
        <div>{formatDateVerbose(createLocalDatetime(pstDatetime.date))}</div>
        <div className="flex items-center space-x-0.5">
          <TimeDropdown
            selectedTime={pstDatetime.time}
            onChange={handleTimeChange}
          />
          <div>PT</div>
        </div>
      </div>

      {errorMessage && <div className="text-red-500 px-3">{errorMessage}</div>}

      <div className="flex justify-end items-center space-x-3 p-3">
        <Button variant="ghost" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (handleApply()) {
              handleCancel();
            }
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
