import { BoardCard, BoardCardProps } from '@/components/crm/board/board-card';
import { useDrag } from 'react-dnd';

interface DraggableBoardCardProps extends BoardCardProps {}

export const DraggableBoardCard = (props: DraggableBoardCardProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: props.salesPipeline.stage,
    item: props.salesPipeline,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        fontSize: 25,
        fontWeight: 'bold',
        cursor: 'move',
      }}
    >
      <BoardCard {...props} />
    </div>
  );
};
