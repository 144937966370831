import { ApiUsageChart } from '@/pages/data-pipeline/api-usage/components/api-usage-chart';
import { useApi } from '@/hooks/use-api';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '@/components';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import { useCallback } from 'react';
import {
  ApiInterface,
  APIUsageResponse,
  AppSettingResponse,
} from '@/pages/data-pipeline/api-usage/types';
import { generateDataForChart } from '@/pages/data-pipeline/api-usage/utils';
import { ApiQuotaDisplay } from '@/pages/data-pipeline/api-usage/components/api-quota-display';
import { EditRecipientEmailListDialog } from '@/pages/data-pipeline/api-usage/components/edit-recipient-email-list-dialog';
import { useToast } from '@/components/ui/use-toast';
import { EditQuotaDialog } from '@/pages/data-pipeline/api-usage/components/edit-quota-dialog';

export const ApiUsageContainer = () => {
  const { getRequest, patchRequest } = useApi();
  const { toast } = useToast();

  const { isPending, error, data } = useQuery<APIUsageResponse>({
    queryKey: ['data-imports'],
    queryFn: () => getRequest('/api/data_pipeline/api_usages'),
  });

  const {
    isPending: isAppSettingLoading,
    data: appSetting,
    refetch: refetchAppSetting,
  } = useQuery<AppSettingResponse>({
    queryKey: ['app-setting'],
    queryFn: () => getRequest('/api/app/setting'),
  });

  const getDataForChart = useCallback(
    (apiInterface: ApiInterface, apiName: string | null = null) => {
      return generateDataForChart(data, apiInterface, apiName);
    },
    [data]
  );

  const getCurrentMonthUsage = useCallback(
    (apiInterface: ApiInterface, apiName: string | null = null) => {
      const dataForChart = generateDataForChart(data, apiInterface, apiName);

      return dataForChart[dataForChart.length - 1]?.value ?? 0;
    },
    [data]
  );

  const updateAppSetting = useCallback(
    async (newAppSetting: Partial<AppSettingResponse>) => {
      await patchRequest('/api/admin/app/setting', newAppSetting);
      toast({
        variant: 'success',
        title: 'Setting Updated',
        description: newAppSetting['notificationEmails']
          ? 'Email recipient list has been updated successfully.'
          : 'Monthly API quota has been updated successfully.',
      });
      await refetchAppSetting();
    },
    [patchRequest, refetchAppSetting, toast]
  );

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  if (isPending || isAppSettingLoading) {
    return <Loader />;
  }

  return (
    <div className="flex space-x-7 w-full items-start justify-start">
      {appSetting && (
        <div className="space-y-5 w-[400px]">
          <div className="flex justify-start items-center space-x-2">
            <div className="text-heading-04 text-navy-navy">
              Recipient Emails
            </div>
            <EditRecipientEmailListDialog
              appSetting={appSetting}
              updateAppSetting={updateAppSetting}
            />
          </div>
          <div className="space-y-3">
            {appSetting?.notificationEmails.map((email) => (
              <div className="text-paragraph-md text-blue-blue">{email}</div>
            ))}
          </div>

          <div className="flex justify-start items-center space-x-2">
            <div className="text-heading-04 text-navy-navy">
              Monthly API Quota
            </div>
            <EditQuotaDialog
              appSetting={appSetting}
              updateAppSetting={updateAppSetting}
            />
          </div>
          <div className="space-y-3">
            <ApiQuotaDisplay
              title="Real Phone Validator"
              apiName="DNC PLUS API"
              currentUsage={getCurrentMonthUsage('dnc')}
              monthlyQuota={appSetting.dncQuota}
            />
            <ApiQuotaDisplay
              title="Endato"
              apiName="Contact Enrichment API"
              currentUsage={getCurrentMonthUsage('endato')}
              monthlyQuota={appSetting.endatoQuota}
            />
            <ApiQuotaDisplay
              title="First American"
              apiName="Property Detail Export API"
              currentUsage={getCurrentMonthUsage('first_american', 'report')}
              monthlyQuota={appSetting.firstAmericanQuota}
            />
            <ApiQuotaDisplay
              title="First American"
              apiName="Property Detail Export API - Count Only"
              currentUsage={getCurrentMonthUsage('first_american', 'count')}
              monthlyQuota={appSetting.firstAmericanCountQuota}
            />
            <ApiQuotaDisplay
              title="Federal Reserve Bank of St Louise API"
              apiName="30 YR Fixed MTG Rate API"
              currentUsage={getCurrentMonthUsage('fred')}
              monthlyQuota={appSetting.fredQuota}
            />
          </div>
        </div>
      )}

      <div className="grow space-y-6">
        <ApiUsageChart
          data={getDataForChart('dnc')}
          title="Real Phone Validator"
          subtitle="DNC PLUS API"
        />
        <ApiUsageChart
          data={getDataForChart('endato')}
          title="Endato"
          subtitle="Contact Enrichment API"
        />
        <ApiUsageChart
          data={getDataForChart('first_american', 'report')}
          title="First American / Report"
          subtitle="Property Detail Export API"
        />
        <ApiUsageChart
          data={getDataForChart('first_american', 'count')}
          title="First American / Count"
          subtitle="Property Detail Export API - Count Only"
        />
        <ApiUsageChart
          data={getDataForChart('fred')}
          title="Federal Reserve Bank of St Louise API"
          subtitle="30 YR Fixed MTG Rate API"
        />
      </div>
    </div>
  );
};
