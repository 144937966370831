export const defaultAprRange = { from: 0, to: 20 };
export const defaultMortgageBalanceRange = { from: 100000, to: 10000000 };
export const defaultBuildYearRange = {
  from: 1800,
  to: new Date().getFullYear(),
};
export const defaultSaleDateRange = {
  from: new Date('2022-01-01'),
  to: new Date(),
};
