import { IconProps } from '@/components/ui/icons/types';

export const CupIcon = ({ className }: IconProps) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="48" height="48" rx="24" fill="transparent" />
    <path
      d="M37.3334 18.8829V18.9802C37.3334 20.1269 37.3334 20.7016 37.0574 21.1709C36.7814 21.6402 36.2787 21.9189 35.2761 22.4776L34.2187 23.0642C34.9467 20.6002 35.1907 17.9522 35.2801 15.6882L35.2934 15.3936L35.2961 15.3242C36.1641 15.6256 36.6521 15.8509 36.9561 16.2722C37.3334 16.7962 37.3334 17.4922 37.3334 18.8829ZM10.6667 18.8829V18.9802C10.6667 20.1269 10.6667 20.7016 10.9427 21.1709C11.2187 21.6402 11.7214 21.9189 12.7241 22.4776L13.7827 23.0642C13.0534 20.6002 12.8094 17.9522 12.7201 15.6882L12.7067 15.3936L12.7054 15.3242C11.8361 15.6256 11.3481 15.8509 11.0441 16.2722C10.6667 16.7962 10.6667 17.4936 10.6667 18.8829Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8359 11.129C27.9068 10.813 25.9547 10.6582 23.9999 10.6663C21.6226 10.6663 19.6626 10.8757 18.1639 11.129C16.6453 11.385 15.8866 11.513 15.2519 12.2943C14.6186 13.0757 14.6519 13.9197 14.7186 15.6077C14.9493 21.405 16.1999 28.6476 22.9999 29.2877V33.9997H21.0933C20.7851 33.9998 20.4866 34.1067 20.2484 34.3022C20.0102 34.4976 19.847 34.7695 19.7866 35.0717L19.5333 36.333H15.9999C15.7347 36.333 15.4804 36.4383 15.2928 36.6259C15.1053 36.8134 14.9999 37.0678 14.9999 37.333C14.9999 37.5982 15.1053 37.8526 15.2928 38.0401C15.4804 38.2276 15.7347 38.333 15.9999 38.333H31.9999C32.2651 38.333 32.5195 38.2276 32.707 38.0401C32.8946 37.8526 32.9999 37.5982 32.9999 37.333C32.9999 37.0678 32.8946 36.8134 32.707 36.6259C32.5195 36.4383 32.2651 36.333 31.9999 36.333H28.4666L28.2133 35.0717C28.1529 34.7695 27.9897 34.4976 27.7515 34.3022C27.5133 34.1067 27.2147 33.9998 26.9066 33.9997H24.9999V29.2877C31.7999 28.6476 33.0519 21.4063 33.2813 15.6077C33.3479 13.9197 33.3826 13.0743 32.7479 12.2943C32.1133 11.513 31.3546 11.385 29.8359 11.129Z"
      fill="currentColor"
    />
  </svg>
);
