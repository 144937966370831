import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useCallback, useEffect, useState } from 'react';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Lender } from '@/pages/admin/lender-list/types';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/hooks/use-api';
import { useToast } from '@/components/ui/use-toast';
import { RANKING_OPTIONS } from '@/components/filters/sections/lender-ranking-filter';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/ui/select';

const formSchema = z.object({
  id: z.number().optional(),
  name: z
    .string()
    .min(1, { message: 'Name is required' })
    .max(30, { message: 'Name must be 30 characters or less' }),
  ranking: z.number(),
});

type FormValues = z.infer<typeof formSchema>;

interface EditLenderDialogProps {
  open: boolean;
  onOpenChange(open: boolean): void;
  onLenderUpdated: () => Promise<void>;
  lender?: Lender;
  isCreateMode?: boolean;
}

export const EditLenderDialog = ({
  open,
  onOpenChange,
  onLenderUpdated,
  lender,
  isCreateMode = false,
}: EditLenderDialogProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { ...lender },
  });

  const [updating, setUpdating] = useState(false);
  const { postRequest, putRequest } = useApi();
  const { toast } = useToast();
  const { mutateAsync: saveLender } = useMutation({
    mutationFn: (lenderData: FormValues) => {
      if (isCreateMode) {
        return postRequest('/api/data_pipeline/lenders', lenderData);
      } else {
        const { id, ...props } = lenderData;
        return putRequest(`/api/data_pipeline/lenders/${id}`, props);
      }
    },
  });

  useEffect(() => {
    if (!updating && lender) {
      form.reset({ ...lender });
    }
  }, [lender, updating, form]);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      setUpdating(true);

      try {
        await saveLender?.(values);
        await onLenderUpdated();
        toast({
          variant: 'success',
          description: `${values.name} has been ${isCreateMode ? 'created' : 'updated'} successfully.`,
          title: `Lender ${isCreateMode ? 'Created' : 'Updated'}`,
        });
        onOpenChange(false);
      } finally {
        setUpdating(false);
      }
    },
    [isCreateMode, onLenderUpdated, onOpenChange, saveLender, toast]
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>
            {isCreateMode ? 'Create Lender' : 'Edit Lender Info'}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-3" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-1">
              <Label htmlFor="name">Name</Label>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input {...field} autoFocus />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="ranking">Competitiveness Ranking</Label>
              <FormField
                control={form.control}
                name="ranking"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select
                        defaultValue={field.value?.toString()}
                        onValueChange={(value) =>
                          field.onChange(parseInt(value, 10))
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select a ranking" />
                        </SelectTrigger>
                        <SelectContent>
                          {RANKING_OPTIONS.map((option) => (
                            <SelectItem
                              key={option.value}
                              value={option.value.toString()}
                            >
                              {option.value} - {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button type="submit" disabled={updating}>
                {updating ? 'Updating' : isCreateMode ? 'Create' : 'Update'}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
