import { EmailIcon, PhoneIcon, UserIcon } from '@/components/icons';
import { OwnerItem } from '@/components/map/property-details/owner-item';
import { EditContactDialog } from '@/components/map/property-details/dialogs/edit-contact-dialog';
import { Owner, OwnerForm } from '@/components/map/property-details/types';
import { SalesPipeline } from '@/pages/crm/lead-stages/types';
import { useState } from 'react';

interface OwnerDetailsProps {
  title: string;
  owner: Owner;
  loading?: boolean;
  salesPipeline?: SalesPipeline;
  position: 1 | 2;
  updateSalesPipelineOwner?(params: {
    position: 1 | 2;
    ownerForm: OwnerForm;
  }): Promise<void>;
}

export const OwnerDetails = ({
  title,
  owner,
  position,
  loading,
  salesPipeline,
  updateSalesPipelineOwner,
}: OwnerDetailsProps) => {
  const [lastCallTime, setLastCallTime] = useState<number | null>(null);

  const handleCallClick = (phoneNumber: string) => {
    const sanitizedPhoneNumber = phoneNumber.replace(/[^\d+]/g, '');
    const now = Date.now();
    if (lastCallTime === null || now - lastCallTime > 5000) {
      setLastCallTime(now);
      window.location.href = `tel:${sanitizedPhoneNumber}`;
    } else {
      alert('Please wait 5 seconds before making another call.');
    }
  };

  return (
    <>
      <div className="flex items-center space-x-3">
        <h2 className="text-dark-dark text-heading-05 py-4">{title}</h2>
        {salesPipeline && (
          <EditContactDialog
            owner={owner}
            position={position}
            updateSalesPipelineOwner={updateSalesPipelineOwner}
          />
        )}
      </div>
      <ul>
        <OwnerItem
          value={
            owner.firstName ? `${owner.firstName} ${owner.lastName}` : 'N/A'
          }
          icon={<UserIcon />}
          loading={loading}
        />
        {owner.phoneNumber && (
          <OwnerItem
            value={
              owner.dncStatus === 'DNC' ? (
                'DNC'
              ) : (
                <button
                  onClick={() => handleCallClick(owner.phoneNumber as string)}
                >
                  {owner.phoneNumber}
                </button>
              )
            }
            icon={<PhoneIcon />}
            loading={loading}
          />
        )}
        {owner.phoneNumber2 && (
          <OwnerItem
            value={
              owner.dncStatus2 === 'DNC' ? (
                'DNC'
              ) : (
                <button
                  onClick={() => handleCallClick(owner.phoneNumber2 as string)}
                >
                  {owner.phoneNumber2}
                </button>
              )
            }
            icon={<PhoneIcon />}
            loading={loading}
          />
        )}
        {owner.email && (
          <OwnerItem
            value={<a href={`mailto:${owner.email}`}>{owner.email}</a>}
            icon={<EmailIcon />}
            loading={loading}
          />
        )}
      </ul>
    </>
  );
};
