import { DownloadIcon } from 'lucide-react';
import { Table } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { toISODate } from '@/utils/date';
import { exportCsv } from '@/utils/csv';

interface DataTableExportProps<TData> {
  table: Table<TData>;
}

export function DataTableExport<TData>({ table }: DataTableExportProps<TData>) {
  return (
    <Button
      className="flex space-x-2"
      onClick={() => {
        const formattedDate = toISODate(new Date());
        exportCsv(`property-data_${formattedDate}`, table);
      }}
    >
      <span>
        <DownloadIcon className="h-4 w-4" />
      </span>
      <span>Download as CSV</span>
    </Button>
  );
}
