import { PropsWithChildren } from 'react';

interface AuthLayoutProps {
  title?: string;
}

export const AuthLayout = ({
  children,
  title,
}: PropsWithChildren<AuthLayoutProps>) => {
  return (
    <div className="flex min-h-screen flex-col text-left py-[60px] px-6 md:px-[100px]">
      <div className="w-full flex flex-col p-4">
        <img
          src="/assets/images/logo.png"
          alt="Logo"
          className="h-[120px] mx-auto"
        />
        <div className="md:hidden">
          <hr className="h-px my-4 bg-navy-navy border-0" />
          <div className="text-heading-02-mobile text-navy-navy mx-auto">
            This platform is designed for desktop and laptop use only. Please
            switch devices.
          </div>
          <hr className="h-px my-4 bg-navy-navy border-0" />
        </div>
        <div className="hidden md:block">
          <div>
            {title && (
              <div className="tmx-auto my-12 flex items-center space-x-4">
                <div className="flex-1">
                  <hr className="h-px my-8 bg-navy-navy border-0" />
                </div>
                <div className="text-heading-01 text-navy-navy mx-auto">
                  {title}
                </div>
                <div className="flex-1">
                  <hr className="h-px my-8 bg-navy-navy border-0" />
                </div>
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
