export const CalendarIcon = () => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
  >
    <path
      d="M25.1007 25.3165H6.90069V11.0165H25.1007M21.2007 1.9165V4.5165H10.8007V1.9165H8.20069V4.5165H4.3009L4.30078 27.9165C4.30078 27.9165 23.7615 27.9165 27.7007 27.9165C27.7007 20.1165 27.7007 12.3165 27.7007 4.5165C26.4007 4.5165 25.1007 4.5165 23.8007 4.5165V1.9165M22.5007 16.2165H16.0007V22.7165H22.5007V16.2165Z"
      fill="currentColor"
    />
  </svg>
);
