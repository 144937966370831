export const DollarIcon = ({ className }: { className?: string }) => (
  <svg
    width="19"
    height="32"
    viewBox="0 0 19 32"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9388 21.0286C18.9388 25.034 16.4136 27.1673 11.2762 27.5156V32H7.7932V27.4721C2.26395 26.8626 0.0870748 23.7279 0 19.5918H3.22177C3.48299 22.5524 4.96326 24.9034 9.83945 24.9034C14.019 24.9034 15.4993 23.5973 15.4993 21.2027C15.4993 18.9823 14.2803 17.8939 11.5374 17.502L6.48707 16.7619C2.69932 16.2395 0.565986 14.2803 0.565986 10.8844C0.565986 7.31429 3.30884 4.91973 7.7932 4.44082V0H11.2762V4.44082C15.8476 5.0068 18.2857 7.66258 18.5034 11.3197H15.0639C14.8463 9.05578 13.4095 7.00952 9.40408 7.00952C5.7034 7.00952 3.9619 8.4898 3.9619 10.6231C3.9619 12.1469 4.74558 13.4531 7.48843 13.8449L12.4082 14.5415C17.1102 15.2381 18.9388 17.415 18.9388 21.0286Z"
      fill="currentColor"
    />
  </svg>
);
