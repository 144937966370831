import { Loader, MarketOpportunityMap } from '@/components';
import { useQuery } from '@tanstack/react-query';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import {
  MapProperty,
  MapPropertyListResponse,
  MapPropertyWithStage,
  SimpleSalesPipeline,
} from '@/pages/admin/property-data/property-table';
import { MapFilterContainer } from '@/pages/map/map/map-filter-container';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApi } from '@/hooks/use-api';
import { MapFilterState } from './types';
import { PropertyDetailsDialog } from '@/pages/map/map/property-details-dialog';
import {
  defaultAprRange,
  defaultBuildYearRange,
  defaultMortgageBalanceRange,
  defaultSaleDateRange,
} from '@/components/filters/sections/constants';
import { allStages } from '@/components/filters/sections/lead-stage-filter';
import { usePropertyCard } from '@/hooks/use-property-card';
import { SalesPipeline, StageEnum } from '@/pages/crm/lead-stages/types';
import { UserListResponse } from '@/pages';
import { isCardDisabled } from '@/components/crm/board/utils';
import { MapTasksContainer } from '@/pages/map/map/map-tasks-container';

interface MapContainerProps {
  defaultFilter?: Partial<MapFilterState>;
  updateMapFilter?: (filter: Partial<MapFilterState>) => void;
}

export const MapContainer = ({
  defaultFilter,
  updateMapFilter,
}: MapContainerProps) => {
  const { getRequest } = useApi();
  const [filter, setFilter] = useState<MapFilterState>({
    selectedStates: [],
    selectedSaleDateRange: defaultSaleDateRange,
    selectedAprRange: defaultAprRange,
    selectedMortgageBalanceRange: defaultMortgageBalanceRange,
    selectedBuildYearRange: defaultBuildYearRange,
    selectedMortgageTypes: [
      'Conventional',
      'Federal Housing Administration',
      'Veterans Affairs',
    ],
    selectedOpportunityValues: [1, 2, 3, 4],
    selectedLeaderCompetitiveness: [-1, 4, 3, 2],
    selectedCallableOwners: [1, 2],
    selectedStages: allStages,
    selectedEmailableOwners: [0, 1, 2],
    selectedDoNotMail: [true, false],
    ...(defaultFilter ?? {}),
    ...(defaultFilter?.selectedSaleDateRange
      ? {
          selectedSaleDateRange: {
            from: new Date(
              defaultFilter?.selectedSaleDateRange?.from ??
                defaultSaleDateRange.from.toISOString()
            ),
            to: new Date(
              defaultFilter?.selectedSaleDateRange?.to ??
                defaultSaleDateRange.to.toISOString()
            ),
          },
        }
      : {}),
  });

  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectedSalesPipeline, setSelectedSalesPipeline] = useState<
    SalesPipeline | undefined
  >();

  const [propertyDetailsDialog, setPropertyDetailsDialog] = useState<{
    open: boolean;
    mapProperty?: MapProperty;
  }>({
    open: false,
  });

  const {
    isPending: isLoadingProperties,
    error,
    data,
  } = useQuery<MapPropertyListResponse>({
    queryKey: ['mapPropertiesData'],
    queryFn: () => getRequest('/api/map'),
  });

  const {
    isPending: isLoadingSalesPipeline,
    data: salesPipelines,
    refetch: refetchSalesPipelines,
  } = useQuery<Record<number, SimpleSalesPipeline>>({
    queryKey: ['salesPipelineDataAll'],
    queryFn: () => getRequest('/api/sales_pipelines/all'),
  });

  const { isPending: isUsersPending, data: userList } =
    useQuery<UserListResponse>({
      queryKey: ['usersData'],
      queryFn: () => getRequest('/api/users'),
    });

  useEffect(() => {
    updateMapFilter?.(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const fetchSalesPipeline = useCallback(
    async (salesPipelineId: number) => {
      try {
        const response = await getRequest(
          `/api/sales_pipelines/${salesPipelineId}`
        );
        setSelectedSalesPipeline(response);
      } catch (error) {
        console.error('Failed to fetch property details', error);
        setSelectedSalesPipeline(undefined);
      }
    },
    [getRequest]
  );

  const refetchSelectedSalesPipeline = useCallback(async () => {
    if (selectedSalesPipeline) {
      await fetchSalesPipeline(selectedSalesPipeline.id);
    }
  }, [fetchSalesPipeline, selectedSalesPipeline]);

  const onPropertyClick = useCallback(
    async (mapProperty: MapProperty) => {
      setPropertyDetailsDialog({ open: true, mapProperty });
      if (mapProperty.salesPipelineId) {
        await fetchSalesPipeline(mapProperty.salesPipelineId);
      }
    },
    [fetchSalesPipeline]
  );

  const properties: MapPropertyWithStage[] = useMemo(() => {
    return data && salesPipelines
      ? data.data.map((property) => {
          const salesPipeline = salesPipelines[property.id];
          return {
            ...property,
            stage: salesPipeline?.stage ?? 'available_lead',
            loStage: salesPipeline?.loStage,
            aeStage: salesPipeline?.aeStage,
            salesPipelineId: salesPipeline?.id,
            loAssignedUserId: salesPipeline?.loAssignedUserId,
            aeAssignedUserId: salesPipeline?.aeAssignedUserId,
          };
        })
      : [];
  }, [data, salesPipelines]);

  const afterChangeStage = useCallback(
    async ({
      salesPipelineId,
      toStage,
      salesPipeline,
    }: {
      salesPipeline: SalesPipeline;
      salesPipelineId: number;
      toStage: string;
    }) => {
      await refetchSelectedSalesPipeline();
      await refetchSalesPipelines();
      setSelectedSalesPipeline((prev) => ({
        ...(prev as SalesPipeline),
        stage: toStage as StageEnum,
        loStage: salesPipeline.loStage,
        aeStage: salesPipeline.aeStage,
      }));

      if (
        isCardDisabled(
          toStage === 'follow_up' ? 'LO' : 'AE',
          salesPipeline.loStage,
          salesPipeline.aeStage
        )
      ) {
        setPropertyDetailsDialog({ open: false });
      } else {
        setPropertyDetailsDialog((prev) => {
          if (prev.mapProperty?.salesPipelineId === salesPipelineId) {
            return {
              ...prev,
              mapProperty: {
                ...prev.mapProperty,
                stage: toStage,
              },
            };
          }
          return prev;
        });
      }
    },
    [refetchSalesPipelines, refetchSelectedSalesPipeline]
  );

  const afterCloseAsLost = useCallback(
    async ({ salesPipelineId }: { salesPipelineId: number }) => {
      await refetchSelectedSalesPipeline();
      await refetchSalesPipelines();
      setPropertyDetailsDialog((prev) => {
        if (prev.mapProperty?.salesPipelineId === salesPipelineId) {
          return {
            ...prev,
            mapProperty: {
              ...prev.mapProperty,
              stage: 'lost',
            },
          };
        }
        return prev;
      });
    },
    [refetchSalesPipelines, refetchSelectedSalesPipeline]
  );

  const afterReactivate = useCallback(async () => {
    await refetchSelectedSalesPipeline();
    await refetchSalesPipelines();
  }, [refetchSalesPipelines, refetchSelectedSalesPipeline]);

  const {
    changeStage,
    closeAsLost,
    updateSalesPipeline,
    reactivate,
    updateSalesPipelineOwner,
  } = usePropertyCard({
    afterChangeStage,
    afterCloseAsLost,
    afterReactivate,
    afterUpdateSalesPipeline: refetchSelectedSalesPipeline,
    afterUpdateSalesPipelineOwner: refetchSelectedSalesPipeline,
  });

  const closeDialog = useCallback(() => {
    setPropertyDetailsDialog({ open: false });
    setSelectedSalesPipeline(undefined);
  }, []);

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  const isPending =
    isLoadingProperties || isLoadingSalesPipeline || isUsersPending;

  return (
    <div className="h-screen">
      {isPending && (
        <div className="h-screen top-20 fixed inset-0 bg-white bg-opacity-70 z-50 shadow-lg rounded-lg overflow-y-auto flex items-center justify-center">
          <Loader />
        </div>
      )}

      <MapFilterContainer
        selectedSaleDateRange={filter.selectedSaleDateRange}
        onSelectSaleDateRange={(selectedSaleDateRange) => {
          setFilter((prev) => ({ ...prev, selectedSaleDateRange }));
        }}
        selectedStates={filter.selectedStates}
        selectedBuildYearRange={filter.selectedBuildYearRange}
        onSelectBuildYearRange={(selectedBuildYearRange) => {
          setFilter((prev) => ({ ...prev, selectedBuildYearRange }));
        }}
        selectedAprRange={filter.selectedAprRange}
        onSelectAprRange={(selectedAprRange) => {
          setFilter((prev) => ({ ...prev, selectedAprRange }));
        }}
        selectedMortgageBalanceRange={filter.selectedMortgageBalanceRange}
        onSelectMortgageBalanceRange={(selectedMortgageBalanceRange) => {
          setFilter((prev) => ({ ...prev, selectedMortgageBalanceRange }));
        }}
        onSelectStates={(selectedStates) =>
          setFilter((prev) => ({ ...prev, selectedStates }))
        }
        selectedOpportunityValues={filter.selectedOpportunityValues}
        selectedMortgageTypes={filter.selectedMortgageTypes}
        onSelectMortgageTypes={(selectedMortgageTypes) => {
          setFilter((prev) => ({ ...prev, selectedMortgageTypes }));
        }}
        onSelectOpportunityValues={(selectedOpportunityValues) => {
          setFilter((prev) => ({ ...prev, selectedOpportunityValues }));
        }}
        selectedLeaderCompetitiveness={filter.selectedLeaderCompetitiveness}
        onSelectLeaderCompetitiveness={(selectedLeaderCompetitiveness) => {
          setFilter((prev) => ({ ...prev, selectedLeaderCompetitiveness }));
        }}
        selectedCallableOwners={filter.selectedCallableOwners}
        onSelectCallableOwners={(selectedCallableOwners) => {
          setFilter((prev) => ({ ...prev, selectedCallableOwners }));
        }}
        selectedStages={filter.selectedStages}
        onSelectStages={(selectedStages) => {
          setFilter((prev) => ({ ...prev, selectedStages }));
        }}
        selectedEmailableOwners={filter.selectedEmailableOwners}
        onSelectEmailableOwners={(selectedEmailableOwners) => {
          setFilter((prev) => ({ ...prev, selectedEmailableOwners }));
        }}
        selectedDoNotMails={filter.selectedDoNotMail}
        onSelectDoNotMails={(selectedDoNotMails) => {
          setFilter((prev) => ({
            ...prev,
            selectedDoNotMail: selectedDoNotMails,
          }));
        }}
        selectedUserId={filter.selectedUserId}
        onSelectedUserId={(selectedUserId) => {
          setFilter((prev) => ({ ...prev, selectedUserId }));
        }}
      />
      <MapTasksContainer />
      <MarketOpportunityMap
        onMapLoaded={() => setMapLoaded(true)}
        properties={mapLoaded ? properties : []}
        filter={filter}
        onPropertyClick={onPropertyClick}
      />
      <PropertyDetailsDialog
        users={userList?.data ?? []}
        open={propertyDetailsDialog.open}
        mapProperty={propertyDetailsDialog.mapProperty}
        salesPipeline={selectedSalesPipeline}
        onOpenChange={closeDialog}
        changeStage={changeStage}
        closeAsLost={closeAsLost}
        updateSalesPipeline={updateSalesPipeline}
        reactivate={reactivate}
        updateSalesPipelineOwner={updateSalesPipelineOwner}
      />
    </div>
  );
};
