export const LogoutIcon = () => (
  <svg
    className="stroke-light-tint hover:stroke-orange-shade"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0482 22.4231L18.0482 24.9615L11.31 27.5L4.57178 24.9615L4.57178 8.03846L11.31 5.5L18.0482 8.03846L18.0482 10.5769"
      strokeWidth="3"
    />
    <path
      d="M21.5547 11.1082L26.5718 16.2644L21.5547 21.4207"
      strokeWidth="3"
    />
    <path d="M24.9844 16.2644L10.2409 16.2644" strokeWidth="3" />
  </svg>
);
