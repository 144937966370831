export const NoteIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2663_38912)">
      <path
        d="M5.71928 0.25H14.5829V2.02273H18.1284V19.75H2.17383V2.02273H5.71928V0.25ZM5.71928 3.79545H3.94656V17.9773H16.3556V3.79545H14.5829V5.56818H5.71928V3.79545ZM12.8102 2.02273H7.49201V3.79545H12.8102V2.02273ZM7.49201 9.11364H12.8102V10.8864H7.49201V9.11364ZM7.49201 12.6591H12.8102V14.4318H7.49201V12.6591Z"
        fill="#192A68"
      />
    </g>
    <defs>
      <clipPath id="clip0_2663_38912">
        <rect
          width="19.5"
          height="19.5"
          fill="white"
          transform="translate(0.400391 0.25)"
        />
      </clipPath>
    </defs>
  </svg>
);
