import { Sidebar } from '@/pages/settings/common/sidebar';
import { RunHistoryContainer } from '@/pages/data-pipeline/run-history/run-history-container';

export const RunHistory = () => {
  return (
    <Sidebar currentPage="run-history">
      <div className="flex flex-col space-y-4 bg-white">
        <div>
          <div className="text-heading-01">Run History</div>
        </div>
        <RunHistoryContainer />
      </div>
    </Sidebar>
  );
};
