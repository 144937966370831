import { AuthLayout } from '@/components/layouts/auth';
import { useRouteError } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';

export const ErrorPage = () => {
  const error = useRouteError() as { statusText?: string; message: string };
  console.error(error);

  return (
    <AuthLayout>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">
              Error
            </h1>
            <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
              Sorry, an unexpected error has occurred.
            </p>
            <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
              {error.statusText || error.message}
            </p>
            <a href="/" className={cn(buttonVariants())}>
              Back to Homepage
            </a>
          </div>
        </div>
      </section>
    </AuthLayout>
  );
};
