import { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { axisLabel, textStyle } from '@/lib/chart';

interface ApiUsageChartProps {
  data: { month: string; value: number }[];
  title: string;
  subtitle?: string;
}

export const ApiUsageChart = ({
  title,
  subtitle,
  data,
}: ApiUsageChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);

      const option = {
        textStyle,
        tooltip: {},
        grid: {
          left: '8%',
          right: '2%',
          top: '5%',
        },
        xAxis: {
          type: 'category',
          data: data.map((item) => item.month),
          axisLabel,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (value: number) => {
              if (value >= 1000) {
                return `${value / 1000}k`;
              }
              return value.toString();
            },
            ...axisLabel,
          },
        },
        series: [
          {
            name: 'API Call Volume',
            type: 'bar',
            data: data.map((item) => item.value),
            itemStyle: {
              color: '#00C49F',
            },
          },
        ],
      };

      chart.setOption(option);

      return () => {
        chart.dispose();
      };
    }
  }, [data]);

  return (
    <div className="flex flex-col">
      <div className="text-heading-05 text-navy-navy text-center font-semibold">
        {title}
      </div>
      <div className="text-heading-05 text-navy-navy text-center">
        ({subtitle})
      </div>
      <div className="flex">
        <div className="text-heading-04 text-navy-navy rotate-vertical">
          API Call Volume
        </div>
        <div ref={chartRef} className="grow" style={{ height: '400px' }} />
      </div>
    </div>
  );
};
