/* eslint-disable @typescript-eslint/no-explicit-any */
import { ZodType, z } from 'zod';

type PreferenceKey =
  | 'lastManualImportState'
  | 'boardSortState'
  | 'leadStagesTaskState'
  | 'mapTaskState'
  | 'mapFiltersState'
  | 'propertyTableColumnVisibilityState';

export const storePreference = <T extends ZodType<any, any, any>>(
  key: PreferenceKey,
  schema: T,
  params: z.infer<T>
) => {
  try {
    schema.parse(params);
    localStorage.setItem(key, JSON.stringify({ ...params }));
  } catch (e) {
    console.error(`Try to store invalid data into ${key} in localStorage:`, e);
  }
};

export const loadPreference = <T extends ZodType<any, any, any>>(
  key: PreferenceKey,
  schema: T,
  defaultParams: z.infer<T>
) => {
  const savedState = localStorage.getItem(key);
  if (savedState) {
    try {
      return schema.parse(JSON.parse(savedState));
    } catch (e) {
      storePreference(key, schema, defaultParams);
      console.error(`Invalid ${key} in localStorage:`, e);
    }
  }
  return defaultParams;
};
