export const RunHistoryIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 14C6.46667 14 5.13067 13.4918 3.992 12.4753C2.85333 11.4589 2.20044 10.1893 2.03333 8.66667H3.4C3.55556 9.82222 4.06956 10.7778 4.942 11.5333C5.81445 12.2889 6.83378 12.6667 8 12.6667C9.3 12.6667 10.4029 12.214 11.3087 11.3087C12.2144 10.4033 12.6671 9.30044 12.6667 8C12.6662 6.69956 12.2136 5.59689 11.3087 4.692C10.4038 3.78711 9.30089 3.33422 8 3.33333C7.23333 3.33333 6.51667 3.51111 5.85 3.86667C5.18333 4.22222 4.62222 4.71111 4.16667 5.33333H6V6.66667H2V2.66667H3.33333V4.23333C3.9 3.52222 4.59178 2.97222 5.40867 2.58333C6.22556 2.19444 7.08933 2 8 2C8.83333 2 9.614 2.15844 10.342 2.47533C11.07 2.79222 11.7033 3.21978 12.242 3.758C12.7807 4.29622 13.2084 4.92956 13.5253 5.658C13.8422 6.38645 14.0004 7.16711 14 8C13.9996 8.83289 13.8413 9.61356 13.5253 10.342C13.2093 11.0704 12.7816 11.7038 12.242 12.242C11.7024 12.7802 11.0691 13.208 10.342 13.5253C9.61489 13.8427 8.83422 14.0009 8 14ZM9.86667 10.8L7.33333 8.26667V4.66667H8.66667V7.73333L10.8 9.86667L9.86667 10.8Z"
      fill="#192A68"
    />
  </svg>
);
