export const TickButtonIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.52734 8.91602L7.52734 10.916L11.5273 6.91602"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M14.7773 8.91602C14.7773 12.3678 11.9791 15.166 8.52734 15.166C5.07556 15.166 2.27734 12.3678 2.27734 8.91602C2.27734 5.46424 5.07556 2.66602 8.52734 2.66602C11.9791 2.66602 14.7773 5.46424 14.7773 8.91602Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
