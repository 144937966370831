interface TimeDropdownProps {
  selectedTime?: string;
  onChange: (time: string) => void;
}

const generateTimeSlots = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const time = new Date();
      time.setHours(hour, minute, 0, 0);
      const formattedTime = time
        .toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
        .replace(' am', ' AM')
        .replace(' pm', ' PM');
      const valueTime = time.toTimeString().split(' ')[0];
      times.push({ display: formattedTime, value: valueTime });
    }
  }
  return times;
};

export const TimeDropdown = ({ selectedTime, onChange }: TimeDropdownProps) => {
  const timeSlots = generateTimeSlots();

  return (
    <select value={selectedTime} onChange={(e) => onChange(e.target.value)}>
      {timeSlots.map((time, index) => (
        <option key={index} value={time.value}>
          {time.display}
        </option>
      ))}
    </select>
  );
};
