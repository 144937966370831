// <div className="relative">
//   <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="group">
//     <path
//       d="M0.398438 20.7506V18.3506H2.79844V9.95059C2.79844 8.29059 3.29844 6.81579 4.29844 5.52619C5.29844 4.23659 6.59844 3.39139 8.19844 2.99059V0.350586H11.7984V2.99059C13.3984 3.39059 14.6984 4.23579 15.6984 5.52619C16.6984 6.81659 17.1984 8.29139 17.1984 9.95059V18.3506H19.5984V20.7506H0.398438ZM9.99844 24.3506C9.33844 24.3506 8.77364 24.1158 8.30404 23.6462C7.83444 23.1766 7.59924 22.6114 7.59844 21.9506H12.3984C12.3984 22.6106 12.1636 23.1758 11.694 23.6462C11.2244 24.1166 10.6592 24.3514 9.99844 24.3506Z"
//       className="fill-light-tint group-hover:fill-orange-shade"></path>
//   </svg>
//   <div className="px-1 bg-red-red rounded-full text-center text-white text-sm absolute -top-3 -end-2">1
//     <div className="absolute top-0 start-0 rounded-full -z-10 animate-ping bg-teal-200 w-full h-full"></div>
//   </div>
// </div>

interface TaskIconProps {
  dueDatetime?: string;
  colorClassName?: string;
}

export const TaskIcon = ({
  dueDatetime,
  colorClassName = 'text-navy-navy',
}: TaskIconProps) => {
  const icon = (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={colorClassName}
    >
      <path
        d="M7.0548 14.4L12.1398 9.315L10.8348 8.01L7.03231 11.8125L5.1423 9.9225L3.8598 11.205L7.0548 14.4ZM0.799805 18V0H9.7998L15.1998 5.4V18H0.799805ZM8.89981 6.3H13.3998L8.89981 1.8V6.3Z"
        fill="currentColor"
      />
    </svg>
  );

  if (dueDatetime && new Date() > new Date(dueDatetime)) {
    return (
      <div className="relative">
        {icon}
        <div className="px-1 absolute -top-2.5 -end-3">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
              fill="#D30026"
            />
            <path
              d="M8.59824 4.77V7.63C8.59824 7.93333 8.58324 8.23167 8.55324 8.525C8.52324 8.815 8.48324 9.12333 8.43324 9.45H7.59824C7.54824 9.12333 7.50824 8.815 7.47824 8.525C7.44824 8.23167 7.43324 7.93333 7.43324 7.63V4.77H8.59824ZM7.24324 11.32C7.24324 11.2167 7.26158 11.1183 7.29824 11.025C7.33824 10.9317 7.39158 10.8517 7.45824 10.785C7.52824 10.7183 7.60991 10.665 7.70324 10.625C7.79658 10.585 7.89658 10.565 8.00324 10.565C8.10658 10.565 8.20491 10.585 8.29824 10.625C8.39158 10.665 8.47158 10.7183 8.53824 10.785C8.60491 10.8517 8.65824 10.9317 8.69824 11.025C8.73824 11.1183 8.75824 11.2167 8.75824 11.32C8.75824 11.4267 8.73824 11.5267 8.69824 11.62C8.65824 11.71 8.60491 11.7883 8.53824 11.855C8.47158 11.9217 8.39158 11.9733 8.29824 12.01C8.20491 12.05 8.10658 12.07 8.00324 12.07C7.89658 12.07 7.79658 12.05 7.70324 12.01C7.60991 11.9733 7.52824 11.9217 7.45824 11.855C7.39158 11.7883 7.33824 11.71 7.29824 11.62C7.26158 11.5267 7.24324 11.4267 7.24324 11.32Z"
              fill="#F7F8FC"
            />
          </svg>

          <div className="absolute top-0 start-0 rounded-full -z-10 bg-teal-200 w-full h-full"></div>
        </div>
      </div>
    );
  }

  return icon;
};
