export const BankIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6563 3.4375L3.51341 7.72321V9.4375H19.7991V7.72321M15.5134 11.1518V17.1518H18.0848V11.1518M3.51341 21.4375H19.7991V18.8661H3.51341M10.3706 11.1518V17.1518H12.942V11.1518M5.2277 11.1518V17.1518H7.79913V11.1518H5.2277Z"
      fill="#192A68"
    />
  </svg>
);
