import { useCallback, useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  SalesPipeline,
  UpdateSalesPipeline,
} from '@/pages/crm/lead-stages/types';
import { CalendarIcon, EditIcon } from '@/components/icons';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { TickButtonIcon } from '@/components/map/property-details/icons/tick-button-icon';
import { useApi } from '@/hooks/use-api';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { DueDateSetting } from '@/components/map/property-details/due-date-setting';
import { DueDatetimeLabel } from '@/components/map/property-details/due-datetime-label';
import { areDatetimeEqual, getPtDatetimeFormattedString } from '@/utils/date';
import { useNotificationContext } from '@/context';

interface NotesDetailsProps {
  salesPipeline: SalesPipeline;
  updateSalesPipeline?(
    salesPipelineId: number,
    data: UpdateSalesPipeline
  ): Promise<void>;
}

interface State {
  editingNotes: boolean;
  editingTask: boolean;
  updating: boolean;
  notes: string;
  notesUpdatedAt?: string;
  task: string;
  taskCompleted: boolean;
  taskDueDatetime?: string;
}

const CHARACTER_LIMIT = 1500;

const defaultTaskDueDatetime = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1); // Set to tomorrow
  date.setHours(13, 0, 0, 0); // Set time to 1:00 PM
  return date.toISOString();
};

export const NotesTaskDetails = ({
  salesPipeline,
  updateSalesPipeline,
}: NotesDetailsProps) => {
  const [state, setState] = useState<State>({
    editingNotes: false,
    editingTask: false,
    updating: false,
    notes: '',
    task: '',
    taskCompleted: false,
  });
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const { sendEvent } = useApi();
  const { refetchNotifications } = useNotificationContext();

  const handleUpdate = useCallback(
    async (data: UpdateSalesPipeline) => {
      setState((prevState) => ({ ...prevState, updating: true }));

      try {
        await updateSalesPipeline?.(salesPipeline.id, data);
        if (data.notes !== undefined) {
          setState((prevState) => ({ ...prevState, editingNotes: false }));
          await sendEvent('edit_notes', { salesPipelineId: salesPipeline.id });
        }

        if (data.task !== undefined) {
          setState((prevState) => ({ ...prevState, editingTask: false }));
          await sendEvent('edit_task', { salesPipelineId: salesPipeline.id });
        }
      } finally {
        setState((prevState) => ({ ...prevState, updating: false }));
      }

      refetchNotifications();
    },
    [refetchNotifications, salesPipeline.id, sendEvent, updateSalesPipeline]
  );

  const setDatetime = (datetime: string) => {
    setState((prevState) => ({ ...prevState, taskDueDatetime: datetime }));
  };

  return (
    <Tabs defaultValue="notes" className="w-full">
      <TabsList>
        <TabsTrigger value="notes">Notes</TabsTrigger>
        <TabsTrigger value="task">Task</TabsTrigger>
      </TabsList>
      <TabsContent value="notes">
        {!state.editingNotes && (
          <div className="bg-light-light p-6 rounded space-y-2 text-paragraph-md">
            <div className="flex justify-end">
              <button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    editingNotes: true,
                    notes: salesPipeline.notes ?? '',
                  }))
                }
              >
                <EditIcon />
              </button>
            </div>

            {!salesPipeline.notes && (
              <div className="min-h-[180px] text-navy-tint">
                Add Your First Note
              </div>
            )}

            {salesPipeline.notes && (
              <>
                <div className="min-h-[180px] text-navy-navy whitespace-pre-wrap">
                  {salesPipeline.notes}
                </div>
                {salesPipeline.notesUpdatedAt && (
                  <div className="text-paragraph-md text-navy-navy">
                    Last Updated:{' '}
                    {getPtDatetimeFormattedString(salesPipeline.notesUpdatedAt)}
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {state.editingNotes && (
          <div className="space-y-3">
            <Textarea
              value={state.notes}
              onChange={(e) => {
                const notes = e.currentTarget.value;
                if (notes.length <= CHARACTER_LIMIT) {
                  setState((prevState) => ({
                    ...prevState,
                    notes,
                  }));
                }
              }}
              className="bg-light-light p-6 rounded space-y-9 text-paragraph-md min-h-[230px]"
            />
            <div className="text-right text-paragraph-sm text-gray-500">
              {state.notes.length}/{CHARACTER_LIMIT} characters
            </div>
            <div className="flex justify-end space-x-3">
              <Button
                variant="ghost"
                disabled={state.updating}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    editingNotes: false,
                  }))
                }
              >
                Cancel
              </Button>
              <Button
                disabled={state.updating}
                onClick={() => handleUpdate({ notes: state.notes })}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </TabsContent>
      <TabsContent value="task">
        {!state.editingTask && (
          <div className="space-y-3">
            {salesPipeline.taskCompleted && (
              <div className="uppercase text-label-md text-green-shade bg-green-tint flex items-center space-x-1.5 p-2 rounded">
                <span>
                  <TickButtonIcon />
                </span>
                <span>Completed</span>
              </div>
            )}

            <div className="bg-light-light p-6 rounded space-y-2 text-paragraph-md">
              <div className="flex justify-end">
                <button
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      editingTask: true,
                      task: salesPipeline.task ?? '',
                      taskCompleted: salesPipeline.taskCompleted,
                      taskDueDatetime: salesPipeline.taskDueDatetime,
                    }))
                  }
                >
                  <EditIcon />
                </button>
              </div>

              {!salesPipeline.task && (
                <div className="min-h-[180px] text-navy-tint">
                  Add Your First Task
                </div>
              )}

              {salesPipeline.task && (
                <div className="min-h-[180px] text-navy-navy whitespace-pre-wrap">
                  {salesPipeline.task}
                </div>
              )}
              {!salesPipeline.taskCompleted && (
                <DueDatetimeLabel
                  taskDueDatetime={salesPipeline.taskDueDatetime}
                />
              )}
            </div>
            {!salesPipeline.taskCompleted && (
              <div className="flex justify-end">
                <Button
                  onClick={async () =>
                    handleUpdate({
                      taskCompleted: true,
                    })
                  }
                  variant="green"
                >
                  <span>
                    <TickButtonIcon />
                  </span>
                  <span>Complete</span>
                </Button>
              </div>
            )}
          </div>
        )}

        {state.editingTask && (
          <div className="space-y-3">
            <Textarea
              value={state.task}
              onChange={(e) => {
                const task = e.currentTarget.value;
                if (task.length <= CHARACTER_LIMIT) {
                  setState((prevState) => ({
                    ...prevState,
                    task,
                  }));
                }
              }}
              className="bg-light-light p-6 rounded space-y-9 text-paragraph-md min-h-[230px]"
            />
            <div className="text-right text-sm text-gray-500">
              {state.task.length}/{CHARACTER_LIMIT} characters
            </div>
            <DueDatetimeLabel taskDueDatetime={state.taskDueDatetime} />
            <div className="flex justify-between items-center">
              <div>
                <Popover
                  modal
                  open={isPopoverOpen}
                  onOpenChange={setPopoverOpen}
                >
                  <PopoverTrigger asChild>
                    <Button
                      variant="yellow"
                      disabled={state.updating}
                      onClick={() => setPopoverOpen(true)}
                    >
                      <span>
                        <CalendarIcon />
                      </span>
                      <span>Due Date</span>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <DueDateSetting
                      dueDatetime={
                        state.taskDueDatetime ?? defaultTaskDueDatetime()
                      }
                      setDueDatetime={(datetime) => {
                        setPopoverOpen(false);
                        setDatetime(datetime);
                      }}
                      onCancel={() => setPopoverOpen(false)}
                    />
                  </PopoverContent>
                </Popover>
              </div>
              <div className="flex justify-end items-center space-x-3">
                <Button
                  variant="ghost"
                  disabled={state.updating}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      editingTask: false,
                    }))
                  }
                >
                  Cancel
                </Button>
                <Button
                  disabled={state.updating}
                  onClick={() =>
                    handleUpdate({
                      task: state.task,
                      taskDueDatetime: state.taskDueDatetime,
                      taskCompleted:
                        state.task !== salesPipeline.task ||
                        !areDatetimeEqual(
                          state.taskDueDatetime,
                          salesPipeline.taskDueDatetime
                        )
                          ? false
                          : salesPipeline.taskCompleted,
                    })
                  }
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}
      </TabsContent>
    </Tabs>
  );
};
