export const ShieldIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 28.25C13.22 27.55 10.9248 25.9548 9.11443 23.4644C7.30403 20.974 6.39922 18.2092 6.40002 15.17V7.85L16 4.25L25.6 7.85V15.17C25.6 18.21 24.6952 20.9752 22.8856 23.4656C21.076 25.956 18.7808 27.5508 16 28.25ZM16 25.73C17.94 25.13 19.56 23.9448 20.86 22.1744C22.16 20.404 22.92 18.4292 23.14 16.25H16V6.8L8.80002 9.5V15.71C8.80002 15.85 8.82003 16.03 8.86003 16.25H16V25.73Z"
      fill="currentColor"
    />
  </svg>
);
