import {
  MapProperty,
  Property,
} from '@/pages/admin/property-data/property-table';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { PropertyDetails } from '@/components';
import { useApi } from '@/hooks/use-api';
import {
  BoardType,
  SalesPipeline,
  UpdateSalesPipeline,
} from '@/pages/crm/lead-stages/types';
import { useCallback, useEffect, useState } from 'react';
import { OwnerForm } from '@/components/map/property-details/types';
import { User } from '@/pages';

interface PropertyDetailsDialogProps {
  boardType?: BoardType;
  mapProperty?: MapProperty;
  salesPipeline?: SalesPipeline;
  users: User[];
  open: boolean;
  onOpenChange(open: boolean): void;
  changeStage(
    salesPipelineId: number,
    fromStage: string,
    toStage: string
  ): Promise<void>;
  closeAsLost(
    salesPipelineId: number,
    fromStage: string,
    lostReasonCode: number,
    lostReasonDescription: string
  ): Promise<void>;
  reactivate(salesPipelineId: number): Promise<void>;
  updateSalesPipeline(
    salesPipelineId: number,
    data: UpdateSalesPipeline
  ): Promise<void>;
  updateSalesPipelineOwner(params: {
    position: 1 | 2;
    salesPipelineId: number;
    ownerForm: OwnerForm;
  }): Promise<void>;
}

export const PropertyDetailsDialog = ({
  salesPipeline,
  mapProperty,
  users,
  boardType,
  open,
  onOpenChange,
  changeStage,
  closeAsLost,
  reactivate,
  updateSalesPipeline,
  updateSalesPipelineOwner,
}: PropertyDetailsDialogProps) => {
  const [propertyDetails, setPropertyDetails] = useState<Property | undefined>(
    undefined
  );

  const { getRequest, sendEvent } = useApi();

  const handleOpenChange = useCallback(
    (open: boolean) => {
      onOpenChange(open);
      setPropertyDetails(undefined);
    },
    [onOpenChange]
  );

  useEffect(() => {
    if (open) {
      (async () =>
        await sendEvent('view_property_card', {
          propertyId: mapProperty?.id ?? salesPipeline?.property?.id,
          salesPipelineId: salesPipeline?.id,
        }))();
    }
  }, [
    mapProperty?.id,
    open,
    salesPipeline?.id,
    salesPipeline?.property?.id,
    sendEvent,
  ]);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      if (salesPipeline) {
        return;
      }

      if (!mapProperty?.salesPipelineId && mapProperty?.id) {
        try {
          const response = await getRequest(
            `/api/properties/${mapProperty.id}`
          );
          setPropertyDetails(response);
        } catch (error) {
          console.error('Failed to fetch property details', error);
          setPropertyDetails(undefined);
        }
      }
    };

    (async () => await fetchPropertyDetails())();
  }, [
    salesPipeline,
    mapProperty?.id,
    getRequest,
    mapProperty?.salesPipelineId,
  ]);

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogContent
        className="w-auto overflow-y-scroll max-h-screen max-w-fit"
        hideCloseButton
      >
        <PropertyDetails
          boardType={boardType}
          users={users}
          changeStage={changeStage}
          onOpenChange={onOpenChange}
          closeAsLost={closeAsLost}
          reactivate={reactivate}
          updateSalesPipeline={updateSalesPipeline}
          salesPipeline={salesPipeline}
          mapProperty={mapProperty}
          property={propertyDetails}
          updateSalesPipelineOwner={async (params: {
            position: 1 | 2;
            ownerForm: OwnerForm;
          }): Promise<void> =>
            await updateSalesPipelineOwner?.({
              ...params,
              salesPipelineId:
                mapProperty?.salesPipelineId ?? salesPipeline?.id ?? 0,
            })
          }
        />
      </DialogContent>
    </Dialog>
  );
};
