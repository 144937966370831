import { FilterContainer } from '@/components/filters/sections/filter-container';
import { Slider } from '@/components/ui/slider';

export interface Range {
  from: number;
  to: number;
}

export interface BuildYearFilterProps {
  selectedBuildYearRange: Range;
  onSelectBuildYearRange: (selectedBuildYearRange: Range) => void;
  defaultClose?: boolean;
  onChange?: (isOpen: boolean) => void;
}

const mapSliderValueToYear = (value: number): number => {
  if (value <= 10) {
    return Math.round(1800 + value * 10);
  } else if (value <= 30) {
    return Math.round(1900 + (value - 10) * 2.5);
  } else if (value <= 50) {
    return Math.round(1950 + (value - 30) * 1.5);
  } else {
    const currentYear = new Date().getFullYear();
    return Math.round(1980 + ((value - 50) * (currentYear - 1980)) / 50);
  }
};

const mapYearToSliderValue = (year: number): number => {
  if (year <= 1900) {
    return (year - 1800) / 10;
  } else if (year <= 1950) {
    return 10 + (year - 1900) / 2.5;
  } else if (year <= 1980) {
    return 30 + (year - 1950) / 1.5;
  } else {
    const currentYear = new Date().getFullYear();
    return 50 + (year - 1980) / (((currentYear - 1980) / 50) * 0.88);
  }
};

export const BuildYearFilter = ({
  selectedBuildYearRange,
  onSelectBuildYearRange,
  defaultClose,
  onChange,
}: BuildYearFilterProps) => {
  return (
    <FilterContainer
      value="state"
      title={
        <div className="text-heading-05 text-dark-dark">Build Year Range</div>
      }
      defaultClose={defaultClose}
      onChange={onChange}
    >
      <div className="flex items-center justify-center space-x-2 px-6">
        <div className="text-eyebrow-md text-navy-tint border-[1px] px-auto w-[90px] border-navy-tint rounded py-1.5 flex items-center justify-center">
          {selectedBuildYearRange.from}
        </div>
        <Slider
          defaultValue={[
            mapYearToSliderValue(selectedBuildYearRange.from),
            mapYearToSliderValue(selectedBuildYearRange.to),
          ]}
          min={0}
          max={100}
          step={0.1}
          onValueChange={(values) => {
            onSelectBuildYearRange({
              from: mapSliderValueToYear(values[0]),
              to: mapSliderValueToYear(values[1]),
            });
          }}
        />
        <div className="text-eyebrow-md text-navy-tint border-[1px] px-auto w-[90px] border-navy-tint rounded py-1.5 flex items-center justify-center">
          {selectedBuildYearRange.to}
        </div>
      </div>
    </FilterContainer>
  );
};
