import { ReactElement } from 'react';
import { InfoIcon } from '@/components/icons';
import { Skeleton } from '@/components/ui/skeleton';

interface ItemProps {
  icon?: ReactElement;
  label: string;
  value?: string | number;
  loading?: boolean;
}

export const Item = ({ icon, label, value, loading }: ItemProps) => {
  return (
    <li className="flex justify-between items-center px-3 py-1.5 even:bg-white odd:bg-slate-50 space-x-3">
      <div className="flex space-x-2 items-center">
        <div className="w-6 text-dark-dark">{icon ?? <InfoIcon />}</div>
        <div className="text-dark-tint text-paragraph-sm">{label}</div>
      </div>
      <div className="text-dark-dark text-paragraph-sm text-right">
        {loading ? <Skeleton className="h-6 w-[150px]" /> : value}
      </div>
    </li>
  );
};
