import { FilterContainer } from '@/components/filters/sections/filter-container';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';
import { StageEnum } from '@/pages/crm/lead-stages/types';
import { titleMap } from '@/components/crm/board/board-title';
import { useAuth } from '@/context';
import { useCallback } from 'react';

export type AllStageEnum = 'available_lead' | StageEnum;

export const aeStages: StageEnum[] = [
  'potential_lead',
  'first_call',
  'second_call',
  'third_call',
  'interested_party',
  'ae_lost',
];

export const loStages: StageEnum[] = [
  'assigned_lead',
  'follow_up',
  'front_line_processing',
  'refinancing',
  'refinanced',
  'lost',
];

export const allStages: AllStageEnum[] = [
  'available_lead',
  'assigned_lead',
  'follow_up',
  'front_line_processing',
  'refinancing',
  'refinanced',
  'potential_lead',
  'first_call',
  'second_call',
  'third_call',
  'interested_party',
  'lost',
];

export interface LeadStageFilterProps {
  selectedStages: AllStageEnum[];
  onSelectStages: (selectedStages: AllStageEnum[]) => void;
  defaultClose?: boolean;
  onChange?: (isOpen: boolean) => void;
}

export const LeadStageFilter = ({
  selectedStages,
  onSelectStages,
  defaultClose,
  onChange,
}: LeadStageFilterProps) => {
  const onSelectStagesWithoutAELost = useCallback(
    (selectedStages: AllStageEnum[]) => {
      if (
        selectedStages.includes('ae_lost') &&
        !selectedStages.includes('lost')
      ) {
        onSelectStages(selectedStages.filter((item) => item !== 'ae_lost'));
      }

      if (
        !selectedStages.includes('ae_lost') &&
        selectedStages.includes('lost')
      ) {
        onSelectStages([...selectedStages, 'ae_lost']);
      }

      onSelectStages(selectedStages);
    },
    [onSelectStages]
  );

  const handleStageChange = (stage: AllStageEnum) => {
    if (selectedStages.includes(stage)) {
      const newSelectedStages = selectedStages.filter((item) => item !== stage);
      if (newSelectedStages.length > 0) {
        onSelectStagesWithoutAELost(newSelectedStages);
      }
    } else {
      onSelectStagesWithoutAELost([...selectedStages, stage]);
    }
  };

  const { userDetails } = useAuth();

  let availableStages: AllStageEnum[] = allStages;
  if (!userDetails.isSuperuser) {
    availableStages = userDetails.userType === 'LO' ? loStages : aeStages;
  }

  return (
    <FilterContainer
      value="stage"
      title={<div className="text-heading-05 text-dark-dark">Lead Stage</div>}
      defaultClose={defaultClose}
      onChange={onChange}
    >
      <div className="flex-col items-center justify-center space-y-2 px-6">
        {availableStages.map((stage) => (
          <CheckboxWithLabel
            key={stage}
            label={(titleMap[stage as StageEnum] ?? 'Available').replace(
              'Lead',
              ''
            )}
            checked={selectedStages.includes(stage)}
            onClick={() => handleStageChange(stage)}
          />
        ))}
      </div>
    </FilterContainer>
  );
};
