export const DollarIcon = ({ className }: { className?: string }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="48" height="48" rx="24" fill="transparent" />
    <path
      d="M33.4693 29.0286C33.4693 33.034 30.9441 35.1673 25.8067 35.5156V40H22.3237V35.4721C16.7945 34.8626 14.6176 31.7279 14.5305 27.5918H17.7523C18.0135 30.5524 19.4938 32.9034 24.37 32.9034C28.5496 32.9034 30.0298 31.5973 30.0298 29.2027C30.0298 26.9823 28.8108 25.8939 26.0679 25.502L21.0176 24.7619C17.2298 24.2395 15.0965 22.2803 15.0965 18.8844C15.0965 15.3143 17.8394 12.9197 22.3237 12.4408V8H25.8067V12.4408C30.3781 13.0068 32.8162 15.6626 33.0339 19.3197H29.5945C29.3768 17.0558 27.94 15.0095 23.9346 15.0095C20.2339 15.0095 18.4924 16.4898 18.4924 18.6231C18.4924 20.1469 19.2761 21.4531 22.019 21.8449L26.9387 22.5415C31.6407 23.2381 33.4693 25.415 33.4693 29.0286Z"
      fill="currentColor"
    />
  </svg>
);
