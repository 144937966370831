export const DoNotMailIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.459 17.1716L1.57327 0.200195L0.458984 1.31448L1.7447 2.6002C1.40184 2.94305 1.23041 3.37162 1.23041 3.88591V14.1716C1.23041 15.1145 2.00184 15.8859 2.9447 15.8859H15.0304L17.3447 18.2002L18.459 17.1716ZM2.9447 5.6002V3.88591L3.20184 4.05734L7.7447 8.6002L2.9447 5.6002ZM11.0018 7.48591L5.68756 2.17162H16.659C17.6018 2.17162 18.3733 2.94305 18.3733 3.88591V14.1716C18.3733 14.3431 18.3733 14.6002 18.2876 14.7716L12.0304 8.51448L16.659 5.6002V3.88591L11.0018 7.48591Z"
      fill="#192A68"
    />
  </svg>
);
