import { Progress } from '@/components/ui/progress';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

interface ApiQuotaDisplayProps {
  title: string;
  apiName: string;
  monthlyQuota: number;
  currentUsage: number;
}

export function ApiQuotaDisplay({
  title,
  apiName,
  monthlyQuota,
  currentUsage,
}: ApiQuotaDisplayProps) {
  const usagePercentage = Math.min((currentUsage / monthlyQuota) * 100, 100);

  let indicatorColor = '[&>div]:bg-green-green';
  let statusText = 'Low usage';

  if (usagePercentage >= 90) {
    indicatorColor = '[&>div]:bg-red-red';
    statusText = 'Critical usage';
  } else if (usagePercentage >= 75) {
    indicatorColor = '[&>div]:bg-yellow-yellow';
    statusText = 'High usage';
  } else if (usagePercentage >= 50) {
    indicatorColor = '[&>div]:bg-blue-blue';
    statusText = 'Moderate usage';
  }

  return (
    <Card className="w-full max-w-md">
      <CardHeader>
        <CardTitle>
          <div className="text-heading-04 text-sm text-navy-navy font-semibold">
            {title}
          </div>
          <div className="text-heading-04 text-sm text-navy-navy">
            {apiName}
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          <div className="flex justify-between">
            <span className="text-navy-navy text-label-sm">Monthly Quota:</span>
            <span className="text-navy-navy text-label-sm font-bold">
              {monthlyQuota.toLocaleString()}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="text-navy-navy text-label-sm">Current Usage:</span>
            <span className="text-navy-navy text-label-sm font-bold">
              {currentUsage.toLocaleString()}
            </span>
          </div>
          <Progress
            value={usagePercentage}
            className={`h-2 w-full bg-gray-200 ${indicatorColor}`}
          />
          <div className="flex justify-between text-label-sm">
            <span
              className={`font-medium ${indicatorColor.replace('[&>div]:bg-', 'text-')}`}
            >
              {statusText}
            </span>
            <span className="font-bold">
              {usagePercentage.toFixed(1)}% Used
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
