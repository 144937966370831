import { Sidebar } from '@/pages/settings/common/sidebar';
import { ApiUsageContainer } from '@/pages/data-pipeline/api-usage/api-usage-container';

export const ApiUsage = () => {
  return (
    <Sidebar currentPage="api-usage">
      <div className="flex flex-col space-y-4 bg-white">
        <div>
          <div className="text-heading-01">API Usage Monitor</div>
        </div>
        <ApiUsageContainer />
      </div>
    </Sidebar>
  );
};
