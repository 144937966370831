export const AvatarIcon = ({ active }: { active?: boolean }) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className="group"
  >
    <circle
      cx="16"
      cy="16.5"
      r="12.5"
      transform="rotate(-90 16 16.5)"
      className={`${active ? 'stroke-orange-shade' : 'stroke-light-tint'} group-hover:stroke-orange-shade`}
      strokeWidth="3"
    />
    <g clipPath="url(#clip0_1291_6294)">
      <path
        d="M16.0009 18.6875C9.35991 18.6875 5.90479 22.6138 5.90479 24.8574V25.9792H26.0971V24.8574C26.0971 22.6138 22.642 18.6875 16.0009 18.6875Z"
        className={`${active ? 'fill-orange-shade' : 'fill-light-tint'} group-hover:fill-orange-shade`}
      />
      <path
        d="M16 17.375C18.4162 17.375 20.375 15.4162 20.375 13C20.375 10.5838 18.4162 8.625 16 8.625C13.5838 8.625 11.625 10.5838 11.625 13C11.625 15.4162 13.5838 17.375 16 17.375Z"
        className={`${active ? 'fill-orange-shade' : 'fill-light-tint'} group-hover:fill-orange-shade`}
      />
    </g>
    <defs>
      <clipPath id="clip0_1291_6294">
        <rect
          x="7.25"
          y="7.75"
          width="17.5"
          height="17.5"
          rx="8.75"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
