import { LeaderCompetitivenessFilter } from '@/components/filters/sections/lender-ranking-filter';
import {
  CallableOwnersFilter,
  CallableOwnersFilterProps,
} from '@/components/filters/sections/callable-owners-filter';
import {
  MortgageTypeFilter,
  MortgageTypeFilterProps,
} from '@/components/filters/sections/mortgage-type-filter';
import { FilterContainer } from '@/components/filters/sections/filter-container';
import {
  StateFilter,
  StateFilterProps,
} from '@/components/filters/sections/state-filter';
import {
  InterestRateFilter,
  AprFilterProps,
} from '@/components/filters/sections/interest-rate-filter';
import {
  MortgageBalanceFilter,
  MortgageBalanceFilterProps,
} from '@/components/filters/sections/mortgage-balance-filter';
import {
  BuildYearFilter,
  BuildYearFilterProps,
} from '@/components/filters/sections/build-year-filter';
import {
  SaleDateFilter,
  SaleDateFilterProps,
} from '@/components/filters/sections/sale-date-filter';
import {
  EmailableOwnersFilter,
  EmailableOwnersFilterProps,
} from '@/components/filters/sections/emailable-owners-filter';
import {
  DoNotMailFilter,
  DoNotMailFilterProps,
} from '@/components/filters/sections/do-not-mail-filter';
import { LeaderCompetitiveness } from '@/pages/admin/property-data/property-table';

interface MarketOpportunityFilterProps
  extends MortgageTypeFilterProps,
    StateFilterProps,
    AprFilterProps,
    CallableOwnersFilterProps,
    MortgageBalanceFilterProps,
    BuildYearFilterProps,
    SaleDateFilterProps,
    EmailableOwnersFilterProps,
    DoNotMailFilterProps {
  selectedRankings: LeaderCompetitiveness[];
  onSelectRankings: (selectedRankings: LeaderCompetitiveness[]) => void;
}

export const MarketOpportunityFilter = ({
  selectedStates,
  onSelectStates,
  selectedMortgageTypes,
  onSelectMortgageTypes,
  selectedRankings,
  onSelectRankings,
  selectedCallableOwners,
  onSelectCallableOwners,
  selectedAprRange,
  onSelectAprRange,
  selectedMortgageBalanceRange,
  onSelectMortgageBalanceRange,
  selectedBuildYearRange,
  onSelectBuildYearRange,
  selectedSaleDateRange,
  onSelectSaleDateRange,
  selectedEmailableOwners,
  onSelectEmailableOwners,
  selectedDoNotMails,
  onSelectDoNotMails,
}: MarketOpportunityFilterProps) => {
  return (
    <div
      className="text-left overflow-y-auto"
      style={{ maxHeight: 'calc(-125px + 100vh)' }}
    >
      <FilterContainer
        value="filter"
        title={<div className="text-heading-04 text-dark-dark">Filter</div>}
        triggerClassName="bg-light-light rounded-t-lg"
        itemClassName={`rounded-b-lg`}
      >
        <MortgageTypeFilter
          selectedMortgageTypes={selectedMortgageTypes}
          onSelectMortgageTypes={onSelectMortgageTypes}
        />

        <SaleDateFilter
          selectedSaleDateRange={selectedSaleDateRange}
          onSelectSaleDateRange={onSelectSaleDateRange}
        />

        <InterestRateFilter
          selectedAprRange={selectedAprRange}
          onSelectAprRange={onSelectAprRange}
        />

        <MortgageBalanceFilter
          selectedMortgageBalanceRange={selectedMortgageBalanceRange}
          onSelectMortgageBalanceRange={onSelectMortgageBalanceRange}
        />

        <BuildYearFilter
          selectedBuildYearRange={selectedBuildYearRange}
          onSelectBuildYearRange={onSelectBuildYearRange}
        />

        <StateFilter
          selectedStates={selectedStates}
          onSelectStates={onSelectStates}
        />

        <CallableOwnersFilter
          selectedCallableOwners={selectedCallableOwners}
          onSelectCallableOwners={onSelectCallableOwners}
        />

        <EmailableOwnersFilter
          selectedEmailableOwners={selectedEmailableOwners}
          onSelectEmailableOwners={onSelectEmailableOwners}
        />

        <DoNotMailFilter
          selectedDoNotMails={selectedDoNotMails}
          onSelectDoNotMails={onSelectDoNotMails}
        />

        <LeaderCompetitivenessFilter
          selectedLeaderCompetitiveness={selectedRankings}
          onSelectLeaderCompetitiveness={onSelectRankings}
        />
      </FilterContainer>
    </div>
  );
};
