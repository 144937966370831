import axios, { AxiosError } from 'axios';
import React, { useCallback, useState } from 'react';
import { LoginForm } from './login-form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/user-context';
import { camelizeKeys } from '@/utils';

export const LoginFormContainer = () => {
  const [form, setForm] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setForm((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
      setError(undefined);
    },
    []
  );

  const rememberMeClick = useCallback(() => {
    setForm((prev) => ({
      ...prev,
      rememberMe: !prev.rememberMe,
    }));
  }, []);

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await axios.post('/api/login', form);

      if (response.data.error) {
        setError(response.data.error);
      } else {
        login(camelizeKeys(response.data));
        await axios.post(
          '/api/events',
          { event_name: 'user_login' },
          {
            headers: {
              Authorization: `Bearer ${response.data.token}`,
            },
          }
        );
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        if (error?.response?.data.detail) {
          setError(error.response.data.detail as string);
        }
      }
    }
  };

  return (
    <LoginForm
      form={form}
      handleInputChange={handleInputChange}
      rememberMeClick={rememberMeClick}
      handleLogin={handleLogin}
      error={error}
    />
  );
};
