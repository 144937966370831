import axios, { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { ResetPasswordForm } from '@/pages/auth/reset-password-page/reset-password-form';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '@/components';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Link } from '@/components/ui/link';

export const ResetPasswordFormContainer = () => {
  const [form, setForm] = useState({
    password: '',
    passwordConfirm: '',
  });
  const [error, setError] = useState<string | undefined>(undefined);
  const { toast } = useToast();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const {
    isPending: isValidatingResetToken,
    error: validateResetTokenError,
    data,
  } = useQuery<{ data: { email: string } }>({
    queryKey: ['validate_reset_token', token],
    queryFn: () => axios.get(`/api/validate_reset_token?token=${token}`),
    retry: (failureCount, error) => {
      const axiosError = error as AxiosError;
      console.log(axiosError.response?.status);
      if (axiosError.response?.status === 400) {
        return false;
      }
      return failureCount < 3;
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
    setError(undefined);
  };

  const handleForgotPassword = async (event: React.FormEvent) => {
    event.preventDefault();

    if (form.password !== form.passwordConfirm) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`/api/change_password?token=${token}`, {
        new_password: form.password,
      });

      if (response.data.error) {
        setError(response.data.error);
      } else {
        toast({
          variant: 'success',
          title: 'Password Changed',
          description: 'Password has been changed successfully.',
        });
      }
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        if (error?.response?.data.detail) {
          setError(error.response.data.detail as string);
        }
      }
    }
  };

  if (isValidatingResetToken) {
    return <Loader />;
  }

  if (validateResetTokenError) {
    const axiosError = validateResetTokenError as AxiosError;
    const errorResponse = axiosError.response?.data as { detail: string };

    return (
      <div className="mx-auto w-[400px]">
        <Alert variant="destructive" className="mb-6">
          <AlertDescription>{errorResponse?.detail}</AlertDescription>
        </Alert>

        <Link
          href="/login"
          className="w-full text-center uppercase ml-auto inline-block text-paragraph-sm text-blue-blue"
          tabIndex={-1}
        >
          Back to login page
        </Link>
      </div>
    );
  }

  return (
    <ResetPasswordForm
      email={data?.data.email}
      form={form}
      handleInputChange={handleInputChange}
      handleLogin={handleForgotPassword}
      error={error}
    />
  );
};
