import { Sidebar } from '@/pages/settings/common/sidebar';
import { EmailTemplatesContainer } from '@/pages/admin/email-templates/email-templates-container';

export const EmailTemplates = () => {
  return (
    <Sidebar currentPage="email-templates">
      <EmailTemplatesContainer />
    </Sidebar>
  );
};
