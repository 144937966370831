import { InternalDnc } from '@/pages/admin/idnc-list/types';
import { getPtDatetimeFormattedString } from '@/utils/date';

export const searchInternalDnc = (
  searchString: string,
  data: InternalDnc[]
): InternalDnc[] => {
  if (searchString.trim() === '') {
    return data;
  }

  const searchWords = searchString.toLowerCase().split(' ');

  return data.filter((internalDnc) => {
    return searchWords.every((word) => {
      return (
        (internalDnc.phoneNumber ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (internalDnc.userFullName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (getPtDatetimeFormattedString(internalDnc.timestamp) ?? '')
          .toLowerCase()
          .includes(word.toLowerCase())
      );
    });
  });
};
