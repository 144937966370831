import { MapContainer } from '@/pages/map/map/map-container';
import { useAuth } from '@/context';
import { MapFilterState } from '@/pages/map/map/types';
import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ProfileForm } from '@/pages';
import { useApi } from '@/hooks/use-api';

export const Map = () => {
  const { patchRequest } = useApi();
  const { userDetails, updateUserDetails } = useAuth();

  const { mutateAsync: updateProfile } = useMutation({
    mutationFn: ({ form }: { form: ProfileForm }) =>
      patchRequest('/api/me', form),
  });

  const updateMapFilter = useCallback(
    (filter: Partial<MapFilterState>) => {
      if (
        userDetails &&
        JSON.stringify(userDetails.latestMapFilter) !== JSON.stringify(filter)
      ) {
        updateUserDetails({
          ...userDetails,
          latestMapFilter: filter,
        });
        (async () =>
          await updateProfile({ form: { latestMapFilter: filter } }))();
      }
    },
    [userDetails, updateUserDetails, updateProfile]
  );

  return (
    <div className="-m-3 -mt-[96px]">
      <MapContainer
        defaultFilter={userDetails.latestMapFilter}
        updateMapFilter={updateMapFilter}
      />
    </div>
  );
};
