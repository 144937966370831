import { IconProps } from '@/components/ui/icons/types';

export const ShakeHandIcon = ({ className }: IconProps) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="48" height="48" rx="24" fill="#FEFEFF" />
    <path
      d="M19.9669 10L23.9999 14.033L28.0329 10L40.0164 21.9835L23.9999 38L7.9834 21.9835L19.9669 10ZM22.099 15.9339L19.9669 13.8018L11.7852 21.9835L23.9999 34.1982L25.2824 32.9157L22.099 29.7309L23.9999 27.8314L27.1833 31.0161L28.4672 29.7323L25.2824 26.5489L27.1833 24.648L30.3681 27.8314L32.1816 26.0179L28.0329 21.8679L23.9999 25.9009L18.066 19.967L22.099 15.9339ZM34.0825 24.1156L36.2146 21.9835L28.0329 13.8018L21.8678 19.967L23.9999 22.0991L28.0329 18.0661L34.0825 24.1156Z"
      fill="currentColor"
    />
  </svg>
);
