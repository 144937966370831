import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { SalesPipeline, StageEnum } from '@/pages/crm/lead-stages/types';
import { capitalize } from '@/utils';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ProfileForm, User } from '@/pages';
import { useApi } from '@/hooks/use-api';
import { useAuth } from '@/context';
import { useMutation } from '@tanstack/react-query';

export interface AssignCardConfirmDialogProps {
  open: boolean;

  onConfirm?(assignUserId: number): Promise<void>;
  users?: User[];
  onCancel?(): void;
  fromStage?: StageEnum;
  toStage?: StageEnum;
  salesPipeline?: SalesPipeline;
}

export const AssignCardConfirmDialog = ({
  open,
  users,
  salesPipeline,
  fromStage,
  toStage,
  onConfirm,
  onCancel,
}: AssignCardConfirmDialogProps) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const { patchRequest } = useApi();
  const { userDetails, updateUserDetails } = useAuth();

  const { mutateAsync: updateProfile } = useMutation({
    mutationFn: ({ form }: { form: ProfileForm }) =>
      patchRequest('/api/me', form),
  });

  const [selectedOption, setSelectedOption] = useState<{
    loading: boolean;
    userId: number;
    setAsDefault: boolean;
  }>({
    loading: false,
    userId: -1,
    setAsDefault: false,
  });

  const isSelectAE = toStage === 'follow_up';
  const isSelectLO = !isSelectAE;
  const role = isSelectAE ? 'Account Executive' : 'Loan Officer';
  const userPreferenceFieldName = isSelectAE
    ? 'lastAssignedToAeUserId'
    : 'lastAssignedToLoUserId';
  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectedOption((prev) => ({
      ...prev,
      userId: parseInt(selectedValue),
    }));
  };

  useEffect(() => {
    if (open) {
      if (salesPipeline && !userDetails[userPreferenceFieldName]) {
        setSelectedOption({
          loading: false,
          userId: -1,
          setAsDefault: false,
        });
        return;
      }

      setSelectedOption({
        loading: false,
        userId: userDetails[userPreferenceFieldName] ?? 0,
        setAsDefault: false,
      });
    }
  }, [
    open,
    toStage,
    isSelectAE,
    salesPipeline,
    isSelectLO,
    userDetails,
    userPreferenceFieldName,
  ]);

  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Passing to {role}</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to move the card from {capitalize(fromStage)}{' '}
            to {capitalize(toStage)}?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div>
          <label
            htmlFor="stage-select"
            className="text-label-md font-bold mr-3"
          >
            Assign to {role}
          </label>
          <select
            id="user-select"
            ref={selectRef}
            onChange={handleSelectChange}
            value={selectedOption.userId?.toString()}
          >
            <option value="0" disabled>
              Select an {role}
            </option>
            {salesPipeline && !isSelectAE && (
              <option value="-1">Original Owner (Default)</option>
            )}

            {salesPipeline && isSelectAE && (
              <option value="-1">Skip AE Assignment</option>
            )}
            {users
              ?.filter(
                (u) =>
                  u.isActive &&
                  !u.isSuperuser &&
                  u.userType === (isSelectAE ? 'AE' : 'LO')
              )
              .sort((a, b) => a.lastName.localeCompare(b.lastName))
              ?.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                  {' - '}
                  {user.isSuperuser ? 'Admin' : user.userType}
                </option>
              ))}
          </select>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => onCancel?.()}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={!selectedOption.userId || selectedOption.loading}
            onClick={async () => {
              if (salesPipeline && selectedOption.userId) {
                const isSelectedOriginalOwner = selectedOption.userId === -1;
                let userId = isSelectedOriginalOwner
                  ? salesPipeline.loAssignedUserId
                  : selectedOption.userId;
                if (isSelectAE) {
                  userId = selectedOption.userId;
                }
                setSelectedOption((prev) => ({
                  ...prev,
                  loading: true,
                }));
                await onConfirm?.(userId);

                updateUserDetails({
                  ...userDetails,
                  [userPreferenceFieldName]: isSelectedOriginalOwner
                    ? null
                    : userId,
                });
                await updateProfile({
                  form: {
                    [userPreferenceFieldName]: isSelectedOriginalOwner
                      ? null
                      : userId,
                  },
                });
              }
            }}
          >
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
