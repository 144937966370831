import { ReactElement, ReactNode } from 'react';
import { InfoIcon } from '@/components/icons';
import { Skeleton } from '@/components/ui/skeleton';

interface OwnerItemProps {
  icon?: ReactElement;
  value?: ReactNode;
  loading?: boolean;
}

export const OwnerItem = ({ icon, value, loading }: OwnerItemProps) => {
  return (
    <li className="flex justify-between py-1.5 bg-white space-x-3">
      <div className="flex space-x-2 items-center">
        <div className="w-6 text-dark-dark">{icon ?? <InfoIcon />}</div>
        <div className="text-dark-tint text-paragraph-sm">
          {loading ? <Skeleton className="h-6 w-[150px]" /> : value}
        </div>
      </div>
    </li>
  );
};
