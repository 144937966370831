import { ExternalLinkIcon, TaskIcon } from '@/components/icons';
import { DueDatetimeLabel } from '@/components/map/property-details/due-datetime-label';
import { NotificationItem } from '@/context';
import { useNavigate } from 'react-router-dom';

interface TaskItemProps {
  item: NotificationItem;
}
export const TaskItem = ({ item }: TaskItemProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex-col border-t border-navy-navy py-3 space-y-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start space-x-2">
          <div>
            <TaskIcon dueDatetime={item.taskDueDatetime} />
          </div>
          <div className="text-navy-navy text-heading-05">
            {item.ownerFullName}
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              const currentUrl =
                window.location.pathname + window.location.search;
              const targetUrl = `/lead-stages/${item.boardType.toLowerCase()}?id=${item.id}`;

              if (currentUrl !== targetUrl) {
                navigate(targetUrl);
                return;
              }

              navigate(`/lead-stages/${item.boardType.toLowerCase()}`);
              setTimeout(() => {
                navigate(
                  `/lead-stages/${item.boardType.toLowerCase()}?id=${item.id}`
                );
              }, 100);
            }}
            className="m-auto"
          >
            <ExternalLinkIcon />
          </button>
        </div>
      </div>
      <div className="line-clamp-2">{item.task}</div>
      <div>
        {item.taskDueDatetime && (
          <DueDatetimeLabel
            taskDueDatetime={new Date(item.taskDueDatetime).toISOString()}
            hideLabel
          />
        )}
      </div>
    </div>
  );
};
