export const PhoneIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 16.42V19.956C21.0001 20.2092 20.9042 20.453 20.7316 20.6382C20.559 20.8234 20.3226 20.9363 20.07 20.954C19.6333 20.9847 19.2767 21 19 21C10.163 21 3 13.837 3 5C3 4.724 3.01533 4.36733 3.046 3.93C3.06372 3.67744 3.17658 3.44101 3.3618 3.26841C3.54703 3.09581 3.79082 2.99989 4.044 3H7.58C7.70404 2.99987 7.8237 3.04586 7.91573 3.12902C8.00776 3.21218 8.0656 3.32658 8.078 3.45C8.10067 3.67933 8.122 3.86333 8.142 4.002C8.34072 5.38893 8.74799 6.73784 9.35 8.003C9.445 8.203 9.383 8.442 9.203 8.57L7.045 10.112C8.36471 13.1863 10.8147 15.6363 13.889 16.956L15.429 14.802C15.4917 14.7137 15.5835 14.6503 15.6883 14.6231C15.7932 14.5958 15.9042 14.6064 16.002 14.653C17.267 15.2539 18.6156 15.6601 20.002 15.858C20.1407 15.878 20.324 15.8993 20.552 15.922C20.6752 15.9346 20.7894 15.9926 20.8724 16.0846C20.9553 16.1766 21.0002 16.2961 21 16.42Z"
      fill="#192A68"
    />
  </svg>
);
