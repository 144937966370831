import * as React from 'react';

interface LinkProps {
  href: string;
  className?: string;
  tabIndex?: number;
  children: React.ReactNode;
}

export const Link = ({ href, className, tabIndex, children }: LinkProps) => {
  return (
    <a href={href} className={className} tabIndex={tabIndex}>
      {children}
    </a>
  );
};
