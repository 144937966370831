import { FilterContainer } from '../sections/filter-container';
import {
  MortgageTypeFilter,
  MortgageTypeFilterProps,
} from '@/components/filters/sections/mortgage-type-filter';
import {
  CallableOwnersFilter,
  CallableOwnersFilterProps,
} from '@/components/filters/sections/callable-owners-filter';
import {
  LeaderCompetitivenessFilter,
  LeaderCompetitivenessFilterProps,
} from '@/components/filters/sections/lender-ranking-filter';
import {
  OpportunityValueFilter,
  OpportunityValueFilterProps,
} from '@/components/filters/sections/opportunity-value-filter';
import {
  StateFilter,
  StateFilterProps,
} from '@/components/filters/sections/state-filter';
import {
  InterestRateFilter,
  AprFilterProps,
} from '@/components/filters/sections/interest-rate-filter';
import {
  MortgageBalanceFilter,
  MortgageBalanceFilterProps,
} from '@/components/filters/sections/mortgage-balance-filter';
import {
  BuildYearFilter,
  BuildYearFilterProps,
} from '@/components/filters/sections/build-year-filter';
import {
  SaleDateFilter,
  SaleDateFilterProps,
} from '@/components/filters/sections/sale-date-filter';
import {
  LeadStageFilter,
  LeadStageFilterProps,
} from '@/components/filters/sections/lead-stage-filter';
import {
  EmailableOwnersFilter,
  EmailableOwnersFilterProps,
} from '@/components/filters/sections/emailable-owners-filter';
import {
  DoNotMailFilter,
  DoNotMailFilterProps,
} from '@/components/filters/sections/do-not-mail-filter';
import {
  AssignedToFilter,
  AssignedToFilterProps,
} from '@/components/filters/sections/assigned-to-filter';
import { useAuth } from '@/context';
import { z } from 'zod';
import { useCallback, useState } from 'react';
import { loadPreference, storePreference } from '@/utils/preference';

export const mapFiltersPreferenceSchema = z.object({
  root: z.boolean(),
  assignedTo: z.boolean(),
  leadStage: z.boolean(),
  mortgageType: z.boolean(),
  saleDateRange: z.boolean(),
  interestRateRange: z.boolean(),
  mortgageBalanceRange: z.boolean(),
  buildYearRange: z.boolean(),
  opportunityValue: z.boolean(),
  region: z.boolean(),
  callableOwners: z.boolean(),
  emailableOwners: z.boolean(),
  mailableOwners: z.boolean(),
  lenderRanking: z.boolean(),
});

export type MapFiltersPreference = z.infer<typeof mapFiltersPreferenceSchema>;

export const defaultMapFiltersPreference: MapFiltersPreference = {
  root: true,
  assignedTo: true,
  leadStage: true,
  mortgageType: true,
  saleDateRange: true,
  interestRateRange: true,
  mortgageBalanceRange: true,
  buildYearRange: true,
  opportunityValue: true,
  region: true,
  callableOwners: true,
  emailableOwners: true,
  mailableOwners: true,
  lenderRanking: true,
};

export interface MapFilterProps
  extends MortgageTypeFilterProps,
    CallableOwnersFilterProps,
    LeaderCompetitivenessFilterProps,
    StateFilterProps,
    OpportunityValueFilterProps,
    AprFilterProps,
    MortgageBalanceFilterProps,
    BuildYearFilterProps,
    SaleDateFilterProps,
    LeadStageFilterProps,
    EmailableOwnersFilterProps,
    DoNotMailFilterProps,
    AssignedToFilterProps {}

export const MapFilter = ({
  selectedMortgageTypes,
  onSelectMortgageTypes,
  selectedAprRange,
  onSelectAprRange,
  selectedMortgageBalanceRange,
  onSelectMortgageBalanceRange,
  selectedBuildYearRange,
  onSelectBuildYearRange,
  selectedCallableOwners,
  onSelectCallableOwners,
  selectedLeaderCompetitiveness,
  onSelectLeaderCompetitiveness,
  selectedStates,
  onSelectStates,
  selectedOpportunityValues,
  onSelectOpportunityValues,
  selectedSaleDateRange,
  onSelectSaleDateRange,
  selectedStages,
  onSelectStages,
  selectedEmailableOwners,
  onSelectEmailableOwners,
  selectedDoNotMails,
  onSelectDoNotMails,
  selectedUserId,
  onSelectedUserId,
}: MapFilterProps) => {
  const { userDetails } = useAuth();
  const [mapFiltersPreference, setMapFilters] = useState<MapFiltersPreference>(
    loadPreference(
      'mapFiltersState',
      mapFiltersPreferenceSchema,
      defaultMapFiltersPreference
    )
  );

  const updateMapFilters = useCallback(
    (key: keyof MapFiltersPreference, isOpen: boolean) => {
      setMapFilters((prev) => {
        const newState = { ...prev, [key]: isOpen };
        storePreference(
          'mapFiltersState',
          mapFiltersPreferenceSchema,
          newState
        );
        return newState;
      });
    },
    []
  );

  return (
    <div className="w-[280px] rounded-lg shadow">
      <FilterContainer
        value="filter"
        title={<div className="text-heading-04 text-dark-dark">Filter</div>}
        triggerClassName="bg-light-light rounded-t-lg"
        itemClassName="rounded-b-lg"
        defaultClose={!mapFiltersPreference.root}
        onChange={(isOpen) => updateMapFilters('root', isOpen)}
      >
        {userDetails.isSuperuser && (
          <AssignedToFilter
            selectedUserId={selectedUserId}
            onSelectedUserId={onSelectedUserId}
            defaultClose={!mapFiltersPreference.assignedTo}
            onChange={(isOpen) => updateMapFilters('assignedTo', isOpen)}
          />
        )}
        <LeadStageFilter
          selectedStages={selectedStages}
          onSelectStages={onSelectStages}
          defaultClose={!mapFiltersPreference.leadStage}
          onChange={(isOpen) => updateMapFilters('leadStage', isOpen)}
        />
        <MortgageTypeFilter
          selectedMortgageTypes={selectedMortgageTypes}
          onSelectMortgageTypes={onSelectMortgageTypes}
          defaultClose={!mapFiltersPreference.mortgageType}
          onChange={(isOpen) => updateMapFilters('mortgageType', isOpen)}
        />

        <SaleDateFilter
          selectedSaleDateRange={selectedSaleDateRange}
          onSelectSaleDateRange={onSelectSaleDateRange}
          defaultClose={!mapFiltersPreference.saleDateRange}
          onChange={(isOpen) => updateMapFilters('saleDateRange', isOpen)}
        />

        <InterestRateFilter
          selectedAprRange={selectedAprRange}
          onSelectAprRange={onSelectAprRange}
          defaultClose={!mapFiltersPreference.interestRateRange}
          onChange={(isOpen) => updateMapFilters('interestRateRange', isOpen)}
        />

        <MortgageBalanceFilter
          selectedMortgageBalanceRange={selectedMortgageBalanceRange}
          onSelectMortgageBalanceRange={onSelectMortgageBalanceRange}
          defaultClose={!mapFiltersPreference.mortgageBalanceRange}
          onChange={(isOpen) =>
            updateMapFilters('mortgageBalanceRange', isOpen)
          }
        />

        <BuildYearFilter
          selectedBuildYearRange={selectedBuildYearRange}
          onSelectBuildYearRange={onSelectBuildYearRange}
          defaultClose={!mapFiltersPreference.buildYearRange}
          onChange={(isOpen) => updateMapFilters('buildYearRange', isOpen)}
        />

        <OpportunityValueFilter
          selectedOpportunityValues={selectedOpportunityValues}
          onSelectOpportunityValues={onSelectOpportunityValues}
          defaultClose={!mapFiltersPreference.opportunityValue}
          onChange={(isOpen) => updateMapFilters('opportunityValue', isOpen)}
        />

        <StateFilter
          selectedStates={selectedStates}
          onSelectStates={onSelectStates}
          defaultClose={!mapFiltersPreference.region}
          onChange={(isOpen) => updateMapFilters('region', isOpen)}
        />

        <CallableOwnersFilter
          selectedCallableOwners={selectedCallableOwners}
          onSelectCallableOwners={onSelectCallableOwners}
          defaultClose={!mapFiltersPreference.callableOwners}
          onChange={(isOpen) => updateMapFilters('callableOwners', isOpen)}
        />

        <EmailableOwnersFilter
          selectedEmailableOwners={selectedEmailableOwners}
          onSelectEmailableOwners={onSelectEmailableOwners}
          defaultClose={!mapFiltersPreference.emailableOwners}
          onChange={(isOpen) => updateMapFilters('emailableOwners', isOpen)}
        />

        <DoNotMailFilter
          selectedDoNotMails={selectedDoNotMails}
          onSelectDoNotMails={onSelectDoNotMails}
          defaultClose={!mapFiltersPreference.mailableOwners}
          onChange={(isOpen) => updateMapFilters('mailableOwners', isOpen)}
        />

        <LeaderCompetitivenessFilter
          selectedLeaderCompetitiveness={selectedLeaderCompetitiveness}
          onSelectLeaderCompetitiveness={onSelectLeaderCompetitiveness}
          defaultClose={!mapFiltersPreference.lenderRanking}
          onChange={(isOpen) => updateMapFilters('lenderRanking', isOpen)}
        />
      </FilterContainer>
    </div>
  );
};
