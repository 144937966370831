import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { PropsWithChildren, ReactElement } from 'react';

interface FilterContainerProps {
  title: ReactElement;
  value: string;
  triggerClassName?: string;
  itemClassName?: string;
  defaultClose?: boolean;
  onChange?: (isOpen: boolean) => void;
}

export const FilterContainer = ({
  title,
  value,
  triggerClassName,
  itemClassName,
  children,
  defaultClose,
  onChange,
}: PropsWithChildren<FilterContainerProps>) => {
  return (
    <Accordion
      type="single"
      collapsible
      defaultValue={defaultClose ? '' : value}
      onValueChange={(val) => onChange?.(val === value)}
    >
      <AccordionItem value={value} className={`border-b-0 ${itemClassName}`}>
        <AccordionTrigger
          className={`w-full px-6 py-2 h-14 flex items-center hover:no-underline ${triggerClassName}`}
        >
          {title}
        </AccordionTrigger>
        <AccordionContent>{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
