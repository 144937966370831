import { useCallback, useState, useEffect, useRef } from 'react';
import { useApi } from '@/hooks/use-api';
import {
  ManualDataImportFormData,
  ManualDataImportWithCountFormData,
} from '@/pages/data-pipeline/manual-data-import/types';
import { format } from 'date-fns';
import { DataImport } from '@/pages/data-pipeline/run-history/types';
import { useNavigate } from 'react-router-dom';

interface Task {
  taskId?: string;
  status?: 'PENDING' | 'SUCCESS' | 'FAILED';
  result?: { count?: number; error?: string };
}

interface RemoteManualDataImportFormData {
  states: string[];
  mortgageAmountFrom: number;
  mortgageAmountTo: number;
  interestRateFrom: number;
  interestRateTo: number;
  lastSaleDateFrom: string; // ISO date string
  lastSaleDateTo: string; // ISO date string
  mortgageType: string[];
  ownershipType: number;
  sellerCarryback: number;
  count?: number;
}

export const useManualDataImport = () => {
  const [task, setTask] = useState<Task>({});
  const navigate = useNavigate();

  const { postRequest, getRequest } = useApi();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const pollStatus = useCallback(
    (taskId: string) => {
      intervalRef.current = setInterval(async () => {
        try {
          const data = (await getRequest(
            `/api/data_pipeline/job-status/${taskId}`
          )) as {
            status: 'PENDING' | 'SUCCESS' | 'FAILED';
            result?: { count: number };
          };

          if (data.status === 'SUCCESS' || data.status === 'FAILED') {
            clearInterval(intervalRef.current!);
            setTask((prev) => ({ ...prev, ...data }));
          }
        } catch (error) {
          setTask((prev) => ({ ...prev, status: 'FAILED' }));
          console.error('Error fetching task status:', error);
          clearInterval(intervalRef.current!);
        }
      }, 1000);
    },
    [getRequest]
  );

  const convertToRequestBody = useCallback(
    (value: ManualDataImportFormData): RemoteManualDataImportFormData => {
      return {
        states: value.states,
        mortgageType: value.mortgageType,
        ownershipType: value.ownershipType,
        sellerCarryback: value.sellerCarryback,
        mortgageAmountFrom: value.mortgageAmount.from,
        mortgageAmountTo: value.mortgageAmount.to,
        interestRateFrom: value.interestRate.from,
        interestRateTo: value.interestRate.to,
        lastSaleDateFrom: format(value.lastSaleDate.from, 'yyyy-MM-dd'),
        lastSaleDateTo: format(value.lastSaleDate.to, 'yyyy-MM-dd'),
        count: value.count,
      };
    },
    []
  );

  const getCount = useCallback(
    async (value: ManualDataImportFormData) => {
      try {
        const data = (await postRequest(
          '/api/data_pipeline/get_count',
          convertToRequestBody(value)
        )) as {
          taskId: string;
        };
        setTask({ taskId: data.taskId, status: 'PENDING' });
        pollStatus(data.taskId);
      } catch (error) {
        console.error('Error fetching count:', error);
      }
    },
    [convertToRequestBody, pollStatus, postRequest]
  );

  const importData = useCallback(
    async (value: ManualDataImportWithCountFormData) => {
      try {
        const dataImport = (await postRequest(
          '/api/data_pipeline/import_data',
          convertToRequestBody(value)
        )) as DataImport;

        navigate(`/admin/manual-data-imports/${dataImport.id}`);
      } catch (error) {
        console.error('Error importing data:', error);
      }
    },
    [convertToRequestBody, navigate, postRequest]
  );

  const clearCount = useCallback(() => {
    setTask({});
  }, []);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return {
    task,
    getCount,
    importData,
    clearCount,
  };
};
