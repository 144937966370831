import {
  createContext,
  useContext,
  useState,
  useCallback,
  PropsWithChildren,
  useEffect,
} from 'react';
import axios from 'axios';
import { useAuth } from '@/context/user-context';
import { camelizeKeys } from '@/utils';

export interface NotificationItem {
  id: number;
  ownerFullName: string;
  task: string;
  taskDueDatetime?: string;
  boardType: 'LO' | 'AE';
}

export interface NotificationItems {
  due: NotificationItem[];
  upcoming: NotificationItem[];
}

interface NotificationContextProps {
  notifications: NotificationItems;
  refetchNotifications: () => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(
  undefined
);

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotificationContext must be used within a NotificationProvider'
    );
  }
  return context;
};

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const [notifications, setNotifications] = useState<NotificationItems>({
    due: [],
    upcoming: [],
  });
  const { userDetails } = useAuth();
  const token = userDetails?.token;

  const fetchNotifications = useCallback(async () => {
    if (!token) {
      return;
    }

    try {
      const response = await axios.get('/api/sales_pipelines/notifications', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNotifications(camelizeKeys(response.data));
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  }, [token]);

  useEffect(() => {
    (async () => await fetchNotifications())();
    const intervalId = setInterval(fetchNotifications, 60000);

    return () => clearInterval(intervalId);
  }, [fetchNotifications]);

  return (
    <NotificationContext.Provider
      value={{ notifications, refetchNotifications: fetchNotifications }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
