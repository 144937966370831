import {
  ApiInterface,
  APIUsageResponse,
} from '@/pages/data-pipeline/api-usage/types';
import { format } from 'date-fns';

const getLast12Months = () => {
  const months = [];
  const date = new Date();
  for (let i = 0; i < 12; i++) {
    months.push(format(date, 'MMM'));
    date.setMonth(date.getMonth() - 1);
  }
  return months.reverse();
};

export const generateDataForChart = (
  data: APIUsageResponse | undefined | null,
  apiInterface: ApiInterface,
  apiName: string | null = null
) => {
  if (!data) {
    return [];
  }

  const last12Months = getLast12Months();
  return last12Months.map((monthName) => {
    const monthNumber =
      new Date(Date.parse(monthName + ' 1, 2022')).getMonth() + 1;
    const monthData = data.find(
      (item) =>
        item.month === monthNumber &&
        item.apiInterface === apiInterface &&
        item.apiName === apiName
    );
    return {
      month: monthName,
      value: monthData ? monthData.totalDataRetrieved : 0,
    };
  });
};
