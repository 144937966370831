export const ApiUsageIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.65637 9.68143L5.6475 9.68143L3.64307 6.34072L5.64751 3L9.65637 3L11.6608 6.34072L9.65637 9.68143ZM9.65637 9.68143L12.3555 14"
      stroke="#192A68"
      strokeWidth="1.5"
    />
  </svg>
);
