export const EditIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2529_25228)">
      <path
        d="M4.50098 16.3744V19.499H7.62555L16.841 10.2836L13.7164 7.15903L4.50098 16.3744ZM19.2573 7.86727C19.3345 7.79019 19.3958 7.69862 19.4376 7.59783C19.4795 7.49703 19.501 7.38897 19.501 7.27985C19.501 7.17072 19.4795 7.06267 19.4376 6.96187C19.3958 6.86107 19.3345 6.76951 19.2573 6.69243L17.3076 4.74269C17.2305 4.66545 17.1389 4.60417 17.0381 4.56236C16.9373 4.52055 16.8293 4.49902 16.7202 4.49902C16.611 4.49902 16.503 4.52055 16.4022 4.56236C16.3014 4.60417 16.2098 4.66545 16.1327 4.74269L14.6079 6.26749L17.7325 9.39206L19.2573 7.86727Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2529_25228">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(4.5 4.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
