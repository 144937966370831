import { Sidebar } from '@/pages/settings/common/sidebar';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/hooks/use-api';
import { useToast } from '@/components/ui/use-toast';

interface PasswordForm {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

const defaultPasswordForm: PasswordForm = {
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
};

export const Password = () => {
  const { putRequest } = useApi();
  const { toast } = useToast();
  const [passwordForm, setPasswordForm] =
    useState<PasswordForm>(defaultPasswordForm);

  const { isPending: isUpdating, mutateAsync: updatePassword } = useMutation({
    mutationFn: ({ form }: { form: PasswordForm }) =>
      putRequest('/api/change_password', {
        currentPassword: form.currentPassword,
        password: form.password,
      }),
    onSuccess: () => {
      toast({
        variant: 'success',
        description: 'Your profile has been updated successfully.',
        title: 'Password Updated',
      });
      setPasswordForm(defaultPasswordForm);
    },
    onError: (error) => {
      console.error('Failed to update profile', error);

      const errorMessage =
        (error as Error).message || 'An unknown error occurred';

      toast({
        variant: 'destructive',
        description: errorMessage,
        title: 'Failed to Update Profile',
      });
    },
  });

  const handleSubmit = useCallback(async () => {
    if (passwordForm.password !== passwordForm.passwordConfirmation) {
      toast({
        variant: 'destructive',
        description: 'Passwords do not match.',
        title: 'Failed to Update Password',
      });
      return;
    }

    await updatePassword({ form: passwordForm });
  }, [passwordForm, updatePassword, toast]);

  return (
    <Sidebar currentPage="password">
      <div>
        <div className="text-heading-01">Security &amp; Password</div>
      </div>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await handleSubmit();
        }}
      >
        <div className="my-12">
          <div className="space-y-6">
            <div>
              <Input
                id="currentPassword"
                name="currentPassword"
                placeholder="Enter current password"
                type="password"
                required
                className="w-[376px] h-10 px-3 border-0 rounded-none border-b border-[#192a68] justify-start items-center gap-2.5 inline-flex text-label-md normal-case focus-visible:ring-0 focus-visible:ring-offset-0"
                value={passwordForm.currentPassword ?? ''}
                onChange={(e) => {
                  setPasswordForm((prevState) => ({
                    ...prevState,
                    currentPassword: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="space-y-6">
              <div className="space-x-6 flex">
                <div>
                  <Input
                    id="password"
                    name="password"
                    placeholder="Enter new password"
                    type="password"
                    required
                    className="w-[376px] h-10 px-3 border-0 rounded-none border-b border-[#192a68] justify-start items-center gap-2.5 inline-flex text-label-md normal-case focus-visible:ring-0 focus-visible:ring-offset-0"
                    value={passwordForm.password ?? ''}
                    onChange={(e) => {
                      setPasswordForm((prevState) => ({
                        ...prevState,
                        password: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div>
                  <Input
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    placeholder="Confirm new password"
                    type="password"
                    required
                    className="w-[376px] h-10 px-3 border-0 rounded-none border-b border-[#192a68] justify-start items-center gap-2.5 inline-flex text-label-md normal-case focus-visible:ring-0 focus-visible:ring-offset-0"
                    value={passwordForm.passwordConfirmation ?? ''}
                    onChange={(e) => {
                      setPasswordForm((prevState) => ({
                        ...prevState,
                        passwordConfirmation: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr className="border-navy-navy my-4" />
        </div>
        <div className="flex justify-end space-x-6 items-center">
          <Button
            disabled={isUpdating}
            className="disabled:cursor-not-allowed"
            type="submit"
          >
            {isUpdating ? 'CHANGING PASSWORD' : 'CHANGE PASSWORD'}
          </Button>
        </div>
      </form>
    </Sidebar>
  );
};
