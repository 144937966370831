import { Sidebar } from '@/pages/settings/common/sidebar';
import { EmailOptOutListContainer } from '@/pages/admin/email-opt-out-list/email-opt-out-list-container';

export const EmailOptOutList = () => {
  return (
    <Sidebar currentPage="email-opt-out-list">
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-heading-01">Email opt-out list</div>
        </div>
        <EmailOptOutListContainer />
      </div>
    </Sidebar>
  );
};
