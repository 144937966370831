export const BuildYearIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9 20.6246L13.425 15.1496L15.525 13.0496L21 18.5246L18.9 20.6246ZM5.1 20.6246L3 18.5246L9.9 11.6246L8.2 9.92461L7.5 10.6246L6.225 9.34961V11.3996L5.525 12.0996L2.5 9.07461L3.2 8.37461H5.25L4 7.12461L7.55 3.57461C7.88333 3.24128 8.24167 2.99961 8.625 2.84961C9.00833 2.69961 9.4 2.62461 9.8 2.62461C10.2 2.62461 10.5917 2.69961 10.975 2.84961C11.3583 2.99961 11.7167 3.24128 12.05 3.57461L9.75 5.87461L11 7.12461L10.3 7.82461L12 9.52461L14.25 7.27461C14.1833 7.09128 14.1293 6.89961 14.088 6.69961C14.0467 6.49961 14.0257 6.29961 14.025 6.09961C14.025 5.11628 14.3627 4.28694 15.038 3.61161C15.7133 2.93628 16.5423 2.59894 17.525 2.59961C17.775 2.59961 18.0127 2.62461 18.238 2.67461C18.4633 2.72461 18.6923 2.79961 18.925 2.89961L16.45 5.37461L18.25 7.17461L20.725 4.69961C20.8417 4.93294 20.921 5.16194 20.963 5.38661C21.005 5.61128 21.0257 5.84894 21.025 6.09961C21.025 7.08294 20.6877 7.91228 20.013 8.58761C19.3383 9.26294 18.509 9.60028 17.525 9.59961C17.325 9.59961 17.125 9.58294 16.925 9.54961C16.725 9.51628 16.5333 9.45794 16.35 9.37461L5.1 20.6246Z"
      fill="#192A68"
    />
  </svg>
);
