import { Sidebar } from '@/pages/settings/common/sidebar';

export const NotAvailablePage = () => {
  return (
    <Sidebar currentPage="not-available">
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-heading-01">Not Available</div>
        </div>
        <div className="py-6">Coming Soon</div>
      </div>
    </Sidebar>
  );
};
