import { TeamContainer } from '@/pages/crm/team/team/team-container';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@/context';
import { useEffect } from 'react';
import { Loader } from '@/components';

export const Team = () => {
  const { type } = useParams<{ type: string }>();
  const navigate = useNavigate();
  const { userDetails } = useAuth();

  useEffect(() => {
    if (!type) {
      if (userDetails.isSuperuser) {
        navigate('/team/lo', { replace: true });
      } else {
        navigate(`/team/${userDetails.userType.toLocaleLowerCase()}`, {
          replace: true,
        });
      }
    }
  }, [type, userDetails, navigate]);

  if (!['lo', 'ae'].includes(type ?? '')) {
    return <Loader />;
  }

  return <TeamContainer boardType={type === 'ae' ? 'AE' : 'LO'} />;
};
