import { FilterContainer } from '@/components/filters/sections/filter-container';
import {
  defaultTaskPreference,
  TaskItems,
  TaskPreference,
  taskPreferenceSchema,
} from '@/pages/crm/lead-stages/task-items';
import { useState } from 'react';
import { loadPreference, storePreference } from '@/utils/preference';

export const MapTasksContainer = () => {
  const [taskPreference, setTaskPreference] = useState<TaskPreference>(
    loadPreference('mapTaskState', taskPreferenceSchema, defaultTaskPreference)
  );

  return (
    <nav
      className="fixed m-4 bg-white bg-opacity-95 z-50 shadow-lg rounded-lg mt-24 overflow-y-auto right-0"
      style={{ maxHeight: 'calc(-250px + 100vh)' }}
    >
      <div className="w-[280px] rounded-lg shadow">
        <FilterContainer
          value="tasks"
          title={<div className="text-heading-04 text-dark-dark">Tasks</div>}
          triggerClassName="bg-light-light rounded-t-lg"
          itemClassName="rounded-b-lg"
          defaultClose={!taskPreference.root}
          onChange={(isOpen) => {
            setTaskPreference((prev) => {
              const newState = { ...prev, root: isOpen };
              storePreference('mapTaskState', taskPreferenceSchema, newState);
              return newState;
            });
          }}
        >
          <TaskItems
            preferenceKey="mapTaskState"
            setTaskPreference={setTaskPreference}
            taskPreference={taskPreference}
          />
        </FilterContainer>
      </div>
    </nav>
  );
};
