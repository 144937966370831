import {
  AccessorKeyColumnDef,
  Header,
  Row,
  RowData,
  Table,
} from '@tanstack/react-table';
import { ColumnHeader, download, generateCsv, mkConfig } from 'export-to-csv';

const getHeaderNames = (headers: Header<RowData, unknown>[]): ColumnHeader[] =>
  headers
    .filter(
      (header) =>
        !(header.column.columnDef as AccessorKeyColumnDef<RowData>).meta
          ?.ignoreExport
    )
    .map((header) => ({
      key: (header.column.columnDef as AccessorKeyColumnDef<RowData>)
        .accessorKey,
      displayLabel: header.column.columnDef?.meta?.title ?? '',
    }));

const getRowsData = (rows: Row<RowData>[]): Record<string, string>[] => {
  return rows.map((row: Row<RowData>) => {
    const cells = row.getAllCells();
    return cells.reduce(
      (acc, cell) => {
        const columnDef = cell.column
          .columnDef as AccessorKeyColumnDef<RowData>;
        if (!columnDef.meta?.ignoreExport) {
          const key = columnDef.accessorKey;
          acc[key] = cell.getValue() === null ? '' : String(cell.getValue());
        }
        return acc;
      },
      {} as Record<string, string>
    );
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const exportCsv = (filename: string, table: Table<any>) => {
  const headers = table
    .getHeaderGroups()
    .map((x) => x.headers)
    .flat();
  const rows = table.getCoreRowModel().rows;

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    filename,
    decimalSeparator: '.',
    columnHeaders: getHeaderNames(headers),
  });

  const csv = generateCsv(csvConfig)(getRowsData(rows));
  download(csvConfig)(csv);
};
