export const TagIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0593 13.6875L13.6968 19.05C13.5468 19.2 13.378 19.3125 13.1905 19.3875C13.003 19.4625 12.8155 19.5 12.628 19.5C12.4405 19.5 12.253 19.4625 12.0655 19.3875C11.878 19.3125 11.7093 19.2 11.5593 19.05L4.94053 12.4312C4.80303 12.2937 4.69678 12.1345 4.62178 11.9535C4.54678 11.7725 4.50928 11.5817 4.50928 11.3813V6C4.50928 5.5875 4.65628 5.2345 4.95028 4.941C5.24428 4.6475 5.59728 4.5005 6.00928 4.5H11.3905C11.5905 4.5 11.7843 4.54075 11.9718 4.62225C12.1593 4.70375 12.3218 4.813 12.4593 4.95L19.0593 11.5688C19.2093 11.7188 19.3188 11.8875 19.3878 12.075C19.4568 12.2625 19.491 12.45 19.4905 12.6375C19.49 12.825 19.4558 13.0095 19.3878 13.191C19.3198 13.3725 19.2103 13.538 19.0593 13.6875ZM7.88428 9C8.19678 9 8.46253 8.89075 8.68153 8.67225C8.90053 8.45375 9.00978 8.188 9.00928 7.875C9.00878 7.562 8.89953 7.2965 8.68153 7.0785C8.46353 6.8605 8.19778 6.751 7.88428 6.75C7.57078 6.749 7.30528 6.8585 7.08778 7.0785C6.87028 7.2985 6.76078 7.564 6.75928 7.875C6.75778 8.186 6.86728 8.45175 7.08778 8.67225C7.30828 8.89275 7.57378 9.002 7.88428 9Z"
      fill="#192A68"
    />
  </svg>
);
