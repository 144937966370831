import { Badge } from '@/components/ui/badge';
import { useCallback, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { EditIcon } from '@/components/icons';
import { EmailTemplateForm } from '@/pages/admin/email-templates/email-template-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/use-api';
import {
  EmailTemplate,
  EmailTemplateListResponse,
  UpdateEmailTemplate,
} from '@/pages/admin/email-templates/types';
import { Loader } from '@/components';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';

type MortgageType = 'FHA' | 'VA' | 'CNV';

const AllMortgageTypes = ['FHA', 'VA', 'CNV'];

export const EmailTemplatesContainer = () => {
  const { getRequest, patchRequest } = useApi();
  const { toast } = useToast();
  const [mortgageType, setMortgageType] = useState<MortgageType>('FHA');
  const [editMode, setEditMode] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState<
    Record<MortgageType, EmailTemplate | undefined>
  >({
    FHA: undefined,
    VA: undefined,
    CNV: undefined,
  });

  const [editEmailTemplate, setEditEmailTemplate] = useState<
    EmailTemplate | undefined
  >(undefined);

  const { isPending, data, error } = useQuery<EmailTemplateListResponse>({
    queryKey: ['email_templates'],
    queryFn: () => getRequest('/api/email_templates'),
  });

  const { mutateAsync: updateEmailTemplate, isPending: isUpdating } =
    useMutation({
      mutationFn: (updatedEmailTemplate: UpdateEmailTemplate) => {
        const { id, ...props } = updatedEmailTemplate;
        return patchRequest(`/api/email_templates/${id}`, props);
      },
      onSuccess: (emailTemplate: EmailTemplate) => {
        setEmailTemplates((prevTemplates) => ({
          ...prevTemplates,
          [emailTemplate.mortgageType as MortgageType]: emailTemplate,
        }));
        toast({
          variant: 'success',
          description: `${emailTemplate.mortgageType} email template has been updated successfully.`,
          title: 'Email Template Updated',
        });
      },
    });

  useEffect(() => {
    if (data) {
      setEmailTemplates(
        data.data.reduce(
          (acc, template) => {
            acc[template.mortgageType as MortgageType] = template;
            return acc;
          },
          {} as Record<MortgageType, EmailTemplate>
        )
      );
    }
  }, [data]);

  const onEditCancel = useCallback(() => {
    if (data) {
      const originalTemplate = data.data.find(
        (template) => template.mortgageType === mortgageType
      );
      if (originalTemplate) {
        setEmailTemplates((prev) => ({
          ...prev,
          [mortgageType]: originalTemplate,
        }));
      }
    }
    setEditMode(false);
  }, [data, mortgageType]);

  const onEdit = useCallback(() => {
    setEditEmailTemplate(emailTemplates[mortgageType]);
    setEditMode(true);
  }, [emailTemplates, mortgageType]);

  const onSave = useCallback(async () => {
    await updateEmailTemplate({
      id: editEmailTemplate!.id,
      subjectText: editEmailTemplate!.subjectText,
      subjectJson: editEmailTemplate!.subjectJson,
      bodyJson: editEmailTemplate!.bodyJson,
      bodyText: editEmailTemplate!.bodyText,
    });
    setEditEmailTemplate(undefined);
    setEditMode(false);
  }, [editEmailTemplate, updateEmailTemplate]);

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-between items-center">
        <div className="text-heading-01">Client email templates</div>
        <div className="flex items-center space-x-2">
          {editMode ? (
            <>
              <Button
                variant="ghost"
                onClick={onEditCancel}
                disabled={isUpdating}
              >
                Cancel
              </Button>
              <Button onClick={onSave} disabled={isUpdating}>
                <span>{isUpdating ? 'Saving Template' : 'Save Template'}</span>
              </Button>
            </>
          ) : (
            <Button onClick={onEdit}>
              <span>
                <EditIcon />
              </span>
              <span>Edit Template</span>
            </Button>
          )}
        </div>
      </div>
      <div className="flex justify-between items-top w-full py-2 rounded-lg space-x-12 bg-transparent">
        <div className="space-y-3 bg-transparent">
          <div className="text-navy-navy text-heading-04">Mortgage type</div>
          <div className="space-x-3">
            {AllMortgageTypes.map((type) => (
              <button
                key={type}
                onClick={() => setMortgageType(type as MortgageType)}
                disabled={editMode}
              >
                <Badge
                  variant="blue"
                  className={
                    mortgageType === type ? 'bg-blue-shade text-blue-tint' : ''
                  }
                >
                  {type}
                </Badge>
              </button>
            ))}
          </div>
        </div>
        <div className="flex-1 space-y-6 bg-transparent">
          {isPending && <Loader />}
          {emailTemplates[mortgageType] && (
            <EmailTemplateForm
              emailTemplate={
                editMode ? editEmailTemplate! : emailTemplates[mortgageType]!
              }
              onChange={(emailTemplate) => {
                setEditEmailTemplate(emailTemplate);
              }}
              editMode={editMode}
            />
          )}
        </div>
      </div>
    </div>
  );
};
