export const RankIcon = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8594 0C9.54616 0 8.2458 0.258658 7.03254 0.761205C5.81928 1.26375 4.71689 2.00035 3.78831 2.92893C1.91294 4.8043 0.859375 7.34784 0.859375 10C0.859375 12.6522 1.91294 15.1957 3.78831 17.0711C4.71689 17.9997 5.81928 18.7362 7.03254 19.2388C8.2458 19.7413 9.54616 20 10.8594 20C13.5115 20 16.0551 18.9464 17.9304 17.0711C19.8058 15.1957 20.8594 12.6522 20.8594 10C20.8594 8.84 20.6494 7.69 20.2494 6.61L18.6494 8.21C18.7894 8.8 18.8594 9.4 18.8594 10C18.8594 12.1217 18.0165 14.1566 16.5162 15.6569C15.0159 17.1571 12.9811 18 10.8594 18C8.73764 18 6.70281 17.1571 5.20252 15.6569C3.70223 14.1566 2.85938 12.1217 2.85938 10C2.85938 7.87827 3.70223 5.84344 5.20252 4.34315C6.70281 2.84285 8.73764 2 10.8594 2C11.4594 2 12.0594 2.07 12.6494 2.21L14.2594 0.6C13.1694 0.21 12.0194 0 10.8594 0ZM17.8594 0L13.8594 4V5.5L11.3094 8.05C11.1594 8 11.0094 8 10.8594 8C10.3289 8 9.82023 8.21071 9.44516 8.58579C9.07009 8.96086 8.85938 9.46957 8.85938 10C8.85938 10.5304 9.07009 11.0391 9.44516 11.4142C9.82023 11.7893 10.3289 12 10.8594 12C11.3898 12 11.8985 11.7893 12.2736 11.4142C12.6487 11.0391 12.8594 10.5304 12.8594 10C12.8594 9.85 12.8594 9.7 12.8094 9.55L15.3594 7H16.8594L20.8594 3H17.8594V0ZM10.8594 4C9.26808 4 7.74195 4.63214 6.61673 5.75736C5.49152 6.88258 4.85938 8.4087 4.85938 10C4.85938 11.5913 5.49152 13.1174 6.61673 14.2426C7.74195 15.3679 9.26808 16 10.8594 16C12.4507 16 13.9768 15.3679 15.102 14.2426C16.2272 13.1174 16.8594 11.5913 16.8594 10H14.8594C14.8594 11.0609 14.4379 12.0783 13.6878 12.8284C12.9377 13.5786 11.9202 14 10.8594 14C9.79851 14 8.78109 13.5786 8.03095 12.8284C7.2808 12.0783 6.85938 11.0609 6.85938 10C6.85938 8.93913 7.2808 7.92172 8.03095 7.17157C8.78109 6.42143 9.79851 6 10.8594 6V4Z"
      fill="#192A68"
    />
  </svg>
);
