import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import {
  Market,
  LoginPage,
  NotFoundPage,
  Map,
  LeadStages,
  SalesFunnel,
  Profile,
  Password,
  UserList,
  Team,
  ForgotPasswordPage,
  ResetPasswordPage,
  EmailTemplates,
  EmailOptOutList,
  IdncList,
  AppSettings,
  ManualDataImport,
  RunHistory,
  DataImportDetails,
  JobDashboard,
  ApiUsage,
  LenderList,
} from '@/pages';
import { ErrorPage } from '@/pages';
import { PrivateRoute } from '@/private-route';
import { AuthProvider } from '@/context/user-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from '@/components/ui/toaster';
import { NotificationProvider } from '@/context';
import { PropertyData } from '@/pages/admin/property-data';
import { DncComplianceLog } from '@/pages/admin/dnc-compliance-log/dnc-compliance-log';
import { NotAvailablePage } from '@/pages/settings/not-available-page';
import { UserPerformance } from '@/pages/admin/user-performance/user-performance';
import { WholesaleInterestRates } from '@/pages/admin/wholesale-interest-rates';

const queryClient = new QueryClient();

export const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/',
    element: <PrivateRoute />,
    children: [
      {
        path: '/',
        element: <Navigate to="/map" replace />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/map',
        element: <Map />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/lead-stages',
        element: <LeadStages />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/lead-stages/:type',
        element: <LeadStages />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/sales-funnel',
        element: <SalesFunnel />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/sales-funnel/:type',
        element: <SalesFunnel />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/team',
        element: <Team />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/team/:type',
        element: <Team />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/market',
        element: <Market />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/users',
        element: <UserList />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/user-performance',
        element: <UserPerformance />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/property-data',
        element: <PropertyData />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/lenders',
        element: <LenderList />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/idnc',
        element: <IdncList />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/dnc-compliance-log',
        element: <DncComplianceLog />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/platform-setting',
        element: <AppSettings />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/wholesale-interest-rates',
        element: <WholesaleInterestRates />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/api-usage',
        element: <ApiUsage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/not-available',
        element: <NotAvailablePage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/email-templates',
        element: <EmailTemplates />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/email-opt-out-list',
        element: <EmailOptOutList />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/manual-data-import',
        element: <ManualDataImport />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/run-history',
        element: <RunHistory />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/manual-data-imports/:id',
        element: <DataImportDetails />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/job-dashboard',
        element: <JobDashboard />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/settings/profile',
        element: <Profile />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/settings/password',
        element: <Password />,
        errorElement: <ErrorPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <NotificationProvider>
          <RouterProvider router={router} />
        </NotificationProvider>
        <Toaster />
      </AuthProvider>
    </QueryClientProvider>
  );
};
