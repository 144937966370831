import axios, { AxiosError } from 'axios';
import React, { useState } from 'react';
import { ForgotPasswordForm } from '@/pages/auth/forgot-password-page/forgot-password-form';
import { useToast } from '@/components/ui/use-toast';

export const ForgotPasswordFormContainer = () => {
  const [form, setForm] = useState({ email: '' });
  const [error, setError] = useState<string | undefined>(undefined);
  const { toast } = useToast();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
    setError(undefined);
  };

  const handleForgotPassword = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await axios.post('/api/forgot_password', form);

      if (response.data.error) {
        setError(response.data.error);
      } else {
        toast({
          variant: 'success',
          title: 'Password Reset Email Sent',
          description:
            'Password Reset Request Complete. If your account exists, a rest link is on its way to your email inbox.',
        });
      }
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        if (error?.response?.data.detail) {
          setError(error.response.data.detail as string);
        }
      }
    }
  };

  return (
    <div className="space-y-12">
      <div className="text-paragraph-sm text-navy-navy mx-auto w-[400px]">
        Enter the email address associated with your account. We will send you
        an email to reset your password.
      </div>
      <ForgotPasswordForm
        form={form}
        handleInputChange={handleInputChange}
        handleLogin={handleForgotPassword}
        error={error}
      />
    </div>
  );
};
