const fontFamily =
  'Lato, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';

export const textStyle = {
  fontFamily,
};

export const axisLabel = {
  fontFamily,
  color: '#66719A',
  fontSize: 14,
  fontWeight: 400,
};
