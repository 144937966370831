export const CloseStopIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16.582C0 7.74548 7.16344 0.582031 16 0.582031C24.8366 0.582031 32 7.74548 32 16.582C32 25.4186 24.8366 32.582 16 32.582C7.16344 32.582 0 25.4186 0 16.582Z"
      fill="#D30026"
    />
    <path
      d="M23.4456 9.51172L8.55469 24.4026"
      stroke="#FBCCD4"
      strokeWidth="2.25"
      strokeMiterlimit="10"
    />
    <path
      d="M25.375 16.957C25.375 22.1347 21.1777 26.332 16 26.332C10.8223 26.332 6.625 22.1347 6.625 16.957C6.625 11.7794 10.8223 7.58203 16 7.58203C21.1777 7.58203 25.375 11.7794 25.375 16.957Z"
      stroke="#FBCCD4"
      strokeWidth="2.25"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
