import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Plus } from 'lucide-react';
import { Checkbox } from '@/components/ui/checkbox';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useToast } from '@/components/ui/use-toast';
import { useApi } from '@/hooks/use-api';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

interface FormProps {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phone?: string;
  userType: string;
  isSuperuser: boolean;
  isActive: boolean;
}

interface CreateUserButtonProps {
  onUserCreated: () => void;
}

export const CreateUserButton = ({ onUserCreated }: CreateUserButtonProps) => {
  const { postRequest } = useApi();
  const [isOpen, setIsOpen] = React.useState(false);
  const { toast } = useToast();

  const {
    isError,
    isPending,
    mutateAsync: createUser,
  } = useMutation({
    mutationFn: (newUser: FormProps) => postRequest('/api/users', newUser),
    onSuccess: () => {
      onUserCreated();
      setIsOpen(false);
    },
  });

  const onSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const form = e.currentTarget as HTMLFormElement;
      const formData = new FormData(form);
      const isSuperuser = formData.get('userType') === 'ADMIN';
      const newUser: FormProps = {
        email: formData.get('email') as string,
        firstName: formData.get('firstName') as string,
        lastName: formData.get('lastName') as string,
        password: formData.get('password') as string,
        phone: formData.get('phone') as string,
        userType: isSuperuser ? 'LO' : (formData.get('userType') as string),
        isSuperuser,
        isActive: formData.get('isActive') === 'on',
      };
      await createUser(newUser);
      toast({
        variant: 'success',
        description: `User '${newUser.firstName} ${newUser.lastName}' was successfully created`,
        title: 'User Created',
      });
    },
    [createUser, toast]
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="ml-auto gap-1">
          <span>Create User</span>
          <Plus className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create User</DialogTitle>
        </DialogHeader>
        {isError && <p className="text-red-500">An error occurred</p>}
        <form onSubmit={onSubmit} className="space-y-4">
          <div className="grid gap-4 py-4">
            <div className="space-y-1">
              <Label htmlFor="email" className="text-right">
                Email
              </Label>
              <Input
                id="email"
                name="email"
                type="email"
                required
                defaultValue=""
              />
            </div>

            <div className="space-y-1">
              <Label htmlFor="firstName" className="text-right">
                First name
              </Label>
              <Input id="firstName" name="firstName" required defaultValue="" />
            </div>
            <div className="space-y-1">
              <Label htmlFor="lastName" className="text-right">
                Last name
              </Label>
              <Input id="lastName" name="lastName" required defaultValue="" />
            </div>
            <div className="space-y-1">
              <Label htmlFor="phone" className="text-right">
                Phone
              </Label>
              <Input id="phone" name="phone" defaultValue="" />
            </div>
            <div className="space-y-1">
              <Label htmlFor="password" className="text-right">
                Password
              </Label>
              <Input
                id="password"
                name="password"
                type="password"
                required
                defaultValue=""
              />
            </div>

            <div className="space-y-1">
              <Label htmlFor="userType" className="text-right">
                User type
              </Label>
              <Select defaultValue="LO" name="userType">
                <SelectTrigger>
                  <SelectValue placeholder="Select a user type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="LO">Loan Officer</SelectItem>
                  <SelectItem value="AE">Account Executive</SelectItem>
                  <SelectItem value="ADMIN">Admin</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="flex space-x-5 items-center justify-start">
              <div className="flex space-x-2 col-span-3">
                <Checkbox id="isActive" name="isActive" defaultChecked={true} />
                <label
                  htmlFor="isActive"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Is Active
                </label>
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button type="submit" disabled={isPending}>
              {isPending ? 'Creating' : 'Create'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
