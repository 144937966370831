import { flexRender, Table } from '@tanstack/react-table';
import { TableHead, TableHeader, TableRow } from '@/components/ui/table';

interface ReactTableHeaderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>;
}

export const ReactTableHeader = ({ table }: ReactTableHeaderProps) => {
  return (
    <TableHeader>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            return (
              <TableHead
                key={header.id}
                colSpan={header.colSpan}
                className="uppercase"
                style={{
                  minWidth: header.column.columnDef.meta?.minWidth ?? 'auto',
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </TableHead>
            );
          })}
        </TableRow>
      ))}
    </TableHeader>
  );
};
