import { FilterContainer } from '@/components/filters/sections/filter-container';
import { StateSelector } from '@/components/filters/sections/state-selector';

export interface StateFilterProps {
  selectedStates: string[];
  onSelectStates: (selectedCounties: string[]) => void;
  defaultClose?: boolean;
  onChange?: (isOpen: boolean) => void;
}

export const StateFilter = ({
  selectedStates,
  onSelectStates,
  defaultClose,
  onChange,
}: StateFilterProps) => {
  return (
    <FilterContainer
      value="state"
      title={<div className="text-heading-05 text-dark-dark">Region</div>}
      defaultClose={defaultClose}
      onChange={onChange}
    >
      <StateSelector
        selectedStates={selectedStates}
        onSelectStates={onSelectStates}
      />
    </FilterContainer>
  );
};
