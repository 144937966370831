import { Sidebar } from '@/pages/settings/common/sidebar';
import { IdncListContainer } from '@/pages/admin/idnc-list/idnc-list-container';

export const IdncList = () => {
  return (
    <Sidebar currentPage="idnc">
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-heading-01">Internal dnc list</div>
        </div>
        <IdncListContainer />
      </div>
    </Sidebar>
  );
};
