interface SidebarLinkProps {
  active: boolean;
  title: React.ReactNode;
  icon: React.ReactNode;
  href: string;
}

export const SidebarLink = ({
  active,
  title,
  icon,
  href,
}: SidebarLinkProps) => (
  <a
    className={`${active ? 'bg-orange-shade select-none' : ''} flex p-3 text-paragraph-md text-navy-navy space-x-1.5 items-center`}
    href={href}
  >
    <span className="w-6 h-6 inline-flex items-center justify-center">
      {icon}
    </span>
    <span>{title}</span>
  </a>
);
