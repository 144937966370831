import { AuthLayout } from '@/components/layouts/auth';
import { LoginFormContainer } from '@/pages/auth/login-page/login-form-container';

export const LoginPage = () => {
  return (
    <AuthLayout title="Log in to your account">
      <LoginFormContainer />
    </AuthLayout>
  );
};
