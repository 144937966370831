import {
  MarketContainer,
  MarketFilterState,
} from '@/pages/market/market/market-container';
import { useAuth } from '@/context';
import { useCallback } from 'react';
import { useApi } from '@/hooks/use-api';
import { useMutation } from '@tanstack/react-query';
import { ProfileForm } from '@/pages';

export const Market = () => {
  const { patchRequest } = useApi();
  const { userDetails, updateUserDetails } = useAuth();

  const { mutateAsync: updateProfile } = useMutation({
    mutationFn: ({ form }: { form: ProfileForm }) =>
      patchRequest('/api/me', form),
  });

  const updateMarketFilter = useCallback(
    (filter: Partial<MarketFilterState>) => {
      if (
        userDetails &&
        JSON.stringify(userDetails.latestMarketFilter) !==
          JSON.stringify(filter)
      ) {
        updateUserDetails({
          ...userDetails,
          latestMarketFilter: filter,
        });
        (async () =>
          await updateProfile({ form: { latestMarketFilter: filter } }))();
      }
    },
    [userDetails, updateUserDetails, updateProfile]
  );

  return (
    <MarketContainer
      defaultFilter={userDetails.latestMarketFilter}
      updateMarketFilter={updateMarketFilter}
    />
  );
};
