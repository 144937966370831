import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Checkbox } from '@/components/ui/checkbox';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useToast } from '@/components/ui/use-toast';
import { useApi } from '@/hooks/use-api';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

interface UpdateUserFormProps {
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  assignedLeadsCap?: number;
  userType?: string;
  isSuperuser: boolean;
  isActive: boolean;
}

interface EditUserFormProps extends UpdateUserFormProps {
  id: number;
}

interface EditUserDialogProps {
  onUserUpdated: () => void;
  open: boolean;
  isLoggedInUser: boolean;

  onOpenChange(open: boolean): void;

  editUserForm: EditUserFormProps;
}

export const EditUserDialog = ({
  onUserUpdated,
  open,
  isLoggedInUser,
  onOpenChange,
  editUserForm,
}: EditUserDialogProps) => {
  const { patchRequest } = useApi();
  const { toast } = useToast();

  const {
    isError,
    isPending,
    mutateAsync: updateUser,
  } = useMutation({
    mutationFn: (updatedUser: UpdateUserFormProps) =>
      patchRequest(`/api/users/${editUserForm.id}`, updatedUser),
    onSuccess: () => {
      onUserUpdated();
      onOpenChange(false);
    },
  });

  const onSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const form = e.currentTarget as HTMLFormElement;
      const formData = new FormData(form);
      const user: UpdateUserFormProps = {
        email: formData.get('email') as string,
        firstName: formData.get('firstName') as string,
        lastName: formData.get('lastName') as string,
        phone: formData.get('phone') as string,
        assignedLeadsCap: parseInt(
          formData.get('assignedLeadsCap') as string,
          10
        ),
        ...(isLoggedInUser
          ? {
              isSuperuser: true,
              isActive: true,
            }
          : {
              isSuperuser: formData.get('isSuperuser') === 'on',
              isActive: formData.get('isActive') === 'on',
            }),
      };
      await updateUser(user);
      toast({
        variant: 'success',
        description: `User '${user.firstName} ${user.lastName}' was successfully updated`,
        title: 'User Updated',
      });
    },
    [isLoggedInUser, updateUser, toast]
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit User</DialogTitle>
        </DialogHeader>
        {isError && <p className="text-red-500">An error occurred</p>}
        <form onSubmit={onSubmit} className="space-y-4">
          <div className="grid gap-4 py-4">
            <div className="space-y-1">
              <Label htmlFor="email" className="text-right">
                Email
              </Label>
              <Input
                id="email"
                name="email"
                type="email"
                required
                defaultValue={editUserForm.email}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="firstName" className="text-right">
                First name
              </Label>
              <Input
                id="firstName"
                name="firstName"
                required
                defaultValue={editUserForm.firstName}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="lastName" className="text-right">
                Last name
              </Label>
              <Input
                id="lastName"
                name="lastName"
                required
                defaultValue={editUserForm.lastName}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="phone" className="text-right">
                Phone
              </Label>
              <Input
                id="phone"
                name="phone"
                defaultValue={editUserForm.phone}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="phone" className="text-right">
                {editUserForm.userType === 'LO'
                  ? 'Assigned Leads Cap'
                  : 'AE Potential Leads Cap'}
              </Label>
              <Input
                id="assignedLeadsCap"
                name="assignedLeadsCap"
                type="number"
                min={0}
                max={5000}
                step={1}
                defaultValue={editUserForm.assignedLeadsCap}
              />
            </div>
            {!isLoggedInUser && (
              <>
                <div className="space-y-1">
                  <Label htmlFor="userType" className="text-right">
                    User type
                  </Label>
                  <Select
                    defaultValue={
                      editUserForm.isSuperuser ? 'ADMIN' : editUserForm.userType
                    }
                    name="userType"
                    disabled
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select a user type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="LO">Loan Officer</SelectItem>
                      <SelectItem value="AE">Account Executive</SelectItem>
                      <SelectItem value="ADMIN">Admin</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex space-x-5 items-center justify-start">
                  <div className="flex space-x-2 col-span-3">
                    <Checkbox
                      id="isActive"
                      name="isActive"
                      defaultChecked={editUserForm.isActive}
                    />
                    <label
                      htmlFor="isActive"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Is Active
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
          <DialogFooter>
            <Button type="submit" disabled={isPending}>
              {isPending ? 'Updating' : 'Update'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
