import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { useApi } from '@/hooks/use-api';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '@/components';
import { Sidebar } from '@/pages/settings/common/sidebar';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';
import { WholesaleInterestRateSlider } from '@/pages/admin/wholesale-interest-rates/wholesale-interest-rate-slider';
import { AppSettingResponse } from '@/pages/data-pipeline/api-usage/types';
import { capitalize } from '@/utils';
import { useCallback, useEffect, useState } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { debounce } from '@/utils/debounce';

export interface FormState {
  interestRateWholesaleCnv: number;
  interestRateWholesaleFha: number;
  interestRateWholesaleVa: number;
}

export const WholesaleInterestRates = () => {
  const { getRequest, patchRequest } = useApi();
  const [formState, setFormState] = useState<FormState>({
    interestRateWholesaleCnv: 0,
    interestRateWholesaleFha: 0,
    interestRateWholesaleVa: 0,
  });

  const { isPending, data, error } = useQuery<AppSettingResponse>({
    queryKey: ['app-setting'],
    queryFn: () => getRequest('/api/app/setting'),
  });
  const [loaded, setLoaded] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    if (data) {
      setFormState({
        interestRateWholesaleCnv: data.interestRateWholesaleCnv ?? 0,
        interestRateWholesaleFha: data.interestRateWholesaleFha ?? 0,
        interestRateWholesaleVa: data.interestRateWholesaleVa ?? 0,
      });
      setLoaded(true);
    }
  }, [data]);

  const updateAppSetting = useCallback(
    async (state: FormState) => {
      await patchRequest('/api/admin/app/setting', state);
      toast({
        variant: 'success',
        title: 'Setting Updated',
        description: 'Interest rate has been updated successfully.',
      });
    },
    [patchRequest, toast]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateAppSetting = useCallback(
    debounce(updateAppSetting, 800),
    [updateAppSetting]
  );

  return (
    <Sidebar currentPage="wholesale-interest-rates">
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-heading-01">Wholesale Interest Rates</div>
        </div>
        <Card>
          <CardContent>
            <div className="pt-6">
              {isPending && <Loader />}
              {error && <div>{error.message}</div>}
              {loaded && (
                <div>
                  <ul className="max-w-[880px] space-y-12">
                    {Object.keys(MORTGAGE_TYPE_MAP).map((name) => {
                      const key =
                        `interestRateWholesale${capitalize(MORTGAGE_TYPE_MAP[name])}` as keyof FormState;
                      return (
                        <li key={key}>
                          <div className="space-y-4">
                            <div className="text-heading-05 text-dark-dark">
                              {name} ({MORTGAGE_TYPE_MAP[name]})
                            </div>
                            <div>
                              <WholesaleInterestRateSlider
                                selectedInterestRate={formState[key] ?? 0}
                                onSelectInterestRate={async (
                                  selectedInterestRate
                                ) => {
                                  const newState = {
                                    ...formState,
                                    [key]: selectedInterestRate,
                                  };

                                  await debouncedUpdateAppSetting(newState);
                                  setFormState(newState);
                                }}
                              />
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </CardContent>
          <CardFooter></CardFooter>
        </Card>
      </div>
    </Sidebar>
  );
};
