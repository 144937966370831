export const capitalize = (str: string | null | undefined) =>
  str
    ?.replace(/_/g, ' ')
    ?.split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ') ?? '';

export const humanize = (str: string | null | undefined) =>
  capitalize(str?.replace(/[_-]/g, ' '));

export const isBlank = (str: string | null | undefined) =>
  !str || str.trim() === '';
