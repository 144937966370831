export const PinIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.59955 9.23496L13.9995 0.834961L22.3995 9.23496L17.1495 14.485L13.9995 21.835L10.8495 14.485L5.59955 9.23496Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.11003 20.8597L10.0994 18.064L10.9269 19.9947L6.3272 21.8346L14 24.9037L21.6727 21.8346L17.0736 19.995L17.9011 18.0642L24.8899 20.8597L27.3272 21.8346L24.8899 22.8095L14.3899 27.0095L14 27.1655L13.61 27.0095L3.11003 22.8095L0.672791 21.8346L3.11003 20.8597Z"
      fill="currentColor"
    />
  </svg>
);
