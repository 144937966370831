import { CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

export interface TwoFieldsDateRangeSelectorProps {
  selectedDateRange: DateRange;
  onSelectDateRange: (selectedDateRange: DateRange) => void;
  hideIcon?: boolean;
}

export const TwoFieldsDateRangeSelector = ({
  selectedDateRange,
  onSelectDateRange,
  hideIcon,
}: TwoFieldsDateRangeSelectorProps) => {
  return (
    <div className="flex items-center justify-start space-x-2 px-6">
      <div className="flex space-x-2 items-center">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              id="fromDate"
              variant={'outline'}
              className={cn(
                'justify-start text-left font-normal w-full text-eyebrow-md px-2.5',
                !selectedDateRange && 'text-muted-foreground'
              )}
            >
              {!hideIcon && <CalendarIcon className="mr-2 h-4 w-4" />}
              {selectedDateRange?.from ? (
                <>{format(selectedDateRange.from, 'LLL dd, y')}</>
              ) : (
                <span>Pick a date</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="single"
              captionLayout="dropdown-buttons"
              fromDate={new Date('2010-01-01')}
              toDate={selectedDateRange.to}
              defaultMonth={selectedDateRange?.from}
              selected={selectedDateRange.from}
              onSelect={(selectedDay: Date | undefined) => {
                if (selectedDay) {
                  onSelectDateRange({
                    from: selectedDay,
                    to: selectedDateRange.to,
                  });
                }
              }}
              numberOfMonths={1}
            />
          </PopoverContent>
        </Popover>
        <div>-</div>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              id="toDate"
              variant={'outline'}
              className={cn(
                'justify-start text-left font-normal w-full text-eyebrow-md px-2.5',
                !selectedDateRange && 'text-muted-foreground'
              )}
            >
              {!hideIcon && <CalendarIcon className="mr-2 h-4 w-4" />}
              {selectedDateRange?.to ? (
                <>{format(selectedDateRange.to, 'LLL dd, y')}</>
              ) : (
                <span>Pick a date</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="single"
              captionLayout="dropdown-buttons"
              fromDate={selectedDateRange.from}
              toDate={new Date()}
              defaultMonth={selectedDateRange?.to}
              selected={selectedDateRange.to}
              onSelect={(selectedDay: Date | undefined) => {
                if (selectedDay) {
                  onSelectDateRange({
                    from: selectedDateRange.from,
                    to: selectedDay,
                  });
                }
              }}
              numberOfMonths={1}
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};
