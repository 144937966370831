import type { EChartsOption } from 'echarts';
import { ECharts } from '@/components/charts/market-opportunity-chart/e-charts';
import {
  LostReason,
  LostReasonCode,
  LostReasonCodeMap,
  LostReasonMap,
  LostReasonColorMap,
} from '@/components/crm/types';

interface LostReasonStatsByMonth {
  date: string;
  lostReasons: Record<LostReasonCode, number>;
}

interface TeamLossReasonChartProps {
  data: LostReasonStatsByMonth[];
}

export const TeamLossReasonByMonthChart = ({
  data,
}: TeamLossReasonChartProps) => {
  const seriesData: EChartsOption['series'] = Object.keys(LostReasonMap).map(
    (code) => {
      const reasonCode = parseInt(code) as LostReasonCode;
      return {
        name: LostReasonMap[reasonCode],
        type: 'line',
        data: data.map((month) => {
          const total = Object.values(month.lostReasons).reduce(
            (sum, value) => sum + value,
            0
          );
          const value = month.lostReasons[reasonCode] || 0;
          return total === 0 ? 0 : (value / total) * 100;
        }),
        itemStyle: {
          color: LostReasonColorMap[LostReasonMap[reasonCode]],
        },
      };
    }
  );

  const option: EChartsOption = {
    xAxis: {
      type: 'category',
      data: data.map((month) => month.date),
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}%',
      },
    },
    series: seriesData,
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const tooltipParams = params as unknown as {
          axisValue: string;
          marker: string;
          seriesName: LostReason;
          data: number;
        }[];
        let tooltipText = `${tooltipParams[0].axisValue}<br/>`;
        tooltipParams.forEach((item) => {
          const total =
            data.find((month) => month.date === item.axisValue)?.lostReasons[
              LostReasonCodeMap[item.seriesName]
            ] || 0;
          tooltipText += `${item.marker} ${item.seriesName}: ${item.data.toFixed(2)}% (${total})<br/>`;
        });
        return tooltipText;
      },
    },
    legend: {
      data: Object.values(LostReasonMap),
    },
  };

  return (
    <div className="h-[400px] mt-6">
      {data ? (
        <ECharts option={option} style={{ height: 450 }} />
      ) : (
        <div style={{ height: 450 }}>Loading...</div>
      )}
    </div>
  );
};
