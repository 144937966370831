import { ReactRenderer } from '@tiptap/react';
import tippy, { Instance as TippyInstance } from 'tippy.js';

import { MentionList } from './mention-list';
import { Mention } from '@tiptap/extension-mention';
import { SuggestionProps } from '@tiptap/suggestion';
import { RefAttributes } from 'react';
import { AllEmailFields } from '@/pages/admin/email-templates/email-fields';

interface MentionItemProps {
  query: string;
}

type MentionSuggestionProps = SuggestionProps<MentionItemProps>;

interface MentionListRef {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
}

interface MentionListProps extends RefAttributes<MentionListRef> {
  items: string[];
  command: (item: { id: string }) => void;
}

const FieldList = [...AllEmailFields].sort().map((item) => `${item}>`);

export const AutoCompleteField = Mention.configure({
  HTMLAttributes: {
    class: 'mention',
  },
  suggestion: {
    char: '<',
    items: ({ query }: { query: string }) => {
      return FieldList.filter((item) =>
        item.toLowerCase().startsWith(query.toLowerCase())
      );
    },
    render: () => {
      let component: ReactRenderer | null = null;
      let popup: TippyInstance[] | null = null;

      return {
        onStart: (props: MentionSuggestionProps) => {
          component = new ReactRenderer<MentionListRef, MentionListProps>(
            MentionList,
            {
              props,
              editor: props.editor,
            }
          );

          if (!props.clientRect) {
            return;
          }

          // @ts-expect-error - getReferenceClientRect is not in the types
          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          });
        },

        onUpdate(props: MentionSuggestionProps) {
          if (component) {
            component.updateProps(props);
          }

          if (!props.clientRect) {
            return;
          }

          if (popup) {
            popup[0].setProps({
              // @ts-expect-error - getReferenceClientRect is not in the types
              getReferenceClientRect: props.clientRect,
            });
          }
        },

        onKeyDown(props: { event: KeyboardEvent }) {
          if (props.event.key === 'Escape') {
            if (popup) {
              popup[0].hide();
            }

            return true;
          }

          // @ts-expect-error - onKeyDown is not in the types
          return component?.ref?.onKeyDown(props);
        },

        onExit() {
          if (popup) {
            popup[0].destroy();
          }
          component?.destroy();
        },
      };
    },
  },
});
