import { TickIcon } from '@/components/map/property-details/icons/tick-icon';
import { CloseStopIcon } from '@/components/map/property-details/icons/close-stop-icon';

interface StepIconProps {
  failed?: boolean;
  done?: boolean;
  active?: boolean;
  value: number;
}

export const StepIcon = ({ failed, done, active, value }: StepIconProps) => {
  if (failed) {
    return (
      <div className="h-8 w-8">
        <CloseStopIcon />
      </div>
    );
  }

  if (done) {
    return (
      <div className="h-8 w-8">
        <TickIcon />
      </div>
    );
  }

  if (active) {
    return (
      <div className="w-8 h-8 relative bg-navy-navy rounded-full">
        <div className="text-label-lg left-[11px] top-1.5 absolute text-light-tint leading-5">
          {value}
        </div>
      </div>
    );
  }

  return (
    <div className="w-8 h-8 relative bg-light-shade rounded-full">
      <div className="text-label-lg left-[11px] top-1.5 absolute text-navy-navy leading-5">
        {value}
      </div>
    </div>
  );
};
