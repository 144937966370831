interface DownloadIconProps {
  colorClassNames?: string;
}

export const DownloadIcon = ({
  colorClassNames = 'text-navy-navy',
}: DownloadIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={colorClassNames}
  >
    <path
      d="M3.4707 13.5H12.5295V12.2059H3.4707V13.5ZM12.5295 6.38235H9.94129V2.5H6.05894V6.38235H3.4707L8.00011 10.9118L12.5295 6.38235Z"
      fill="currentColor"
    />
  </svg>
);
