import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { EditIcon } from '@/components/icons';
import { Owner, OwnerForm } from '@/components/map/property-details/types';
import { useCallback, useEffect, useState } from 'react';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { USPhoneInput } from '@/components/ui/us-phone-input';

const nameRegex = /^[a-zA-Z\s]*$/;
const phoneRegex = /^\([2-9]\d{2}\) [2-9]\d{2}-\d{4}$/;

const formSchema = z.object({
  firstName: z
    .string()
    .min(1, { message: 'First name is required' })
    .max(30, { message: 'First name must be 30 characters or less' })
    .regex(nameRegex, {
      message: 'First name must contain only letters and spaces',
    }),
  lastName: z
    .string()
    .min(1, { message: 'Last name is required' })
    .max(30, { message: 'Last name must be 30 characters or less' })
    .regex(nameRegex, {
      message: 'Last name must contain only letters and spaces',
    }),
  phoneNumber: z
    .string()
    .regex(phoneRegex, { message: 'Invalid phone number format.' })
    .max(30, { message: 'Phone number must be 30 characters or less' })
    .optional(),
  phoneNumber2: z
    .string()
    .regex(phoneRegex, { message: 'Invalid phone number format.' })
    .max(30, { message: 'Phone number must be 30 characters or less' })
    .optional(),
  email: z
    .string()
    .max(50, { message: 'Email must be 50 characters or less' })
    .refine((value) => !value || z.string().email().safeParse(value).success, {
      message: 'Invalid email format',
    })
    .optional(),
});

interface EditContactDialogProps {
  owner: Owner;
  position: 1 | 2;
  updateSalesPipelineOwner?(params: {
    position: 1 | 2;
    ownerForm: OwnerForm;
  }): Promise<void>;
}

export const EditContactDialog = ({
  owner,
  position,
  updateSalesPipelineOwner,
}: EditContactDialogProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { ...owner },
  });

  const [updating, setUpdating] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (!updating) {
      form.reset({ ...owner });
    }
  }, [owner, updating, form]);

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      setUpdating(true);

      try {
        await updateSalesPipelineOwner?.({
          position,
          ownerForm: values,
        });
        setDialogOpen(false);
      } finally {
        setUpdating(false);
      }
    },
    [position, updateSalesPipelineOwner]
  );

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <button className="w-6 h-6" onClick={() => setDialogOpen(true)}>
          <EditIcon />
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Edit Contact Info</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-3" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-1">
              <Label htmlFor="firstName">First name</Label>
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input {...field} autoFocus />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="lastName">Last name</Label>
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="phoneNumber">Phone number</Label>
              <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <USPhoneInput {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="phoneNumber2">Phone number 2</Label>
              <FormField
                control={form.control}
                name="phoneNumber2"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <USPhoneInput {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="email">Email</Label>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        type="email"
                        maxLength={50}
                        placeholder="PERSON@EXAMPLE.COM"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button type="submit" disabled={updating}>
                {updating ? 'Updating' : 'Update'}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
