import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { StageEnum } from '@/pages/crm/lead-stages/types';
import { capitalize } from '@/utils';
import { ChangeEvent, useEffect, useState } from 'react';
import { AllLostReasons, LostReasonCodeMap } from '@/components/crm/types';

export interface MoveCardConfirmDialogProps {
  isSuperuser?: boolean;
  open: boolean;

  onConfirm?(
    lostReasonCode?: number,
    lostReasonDescription?: string
  ): Promise<void>;

  onCancel?(): void;

  fromStage?: StageEnum;
  toStage?: StageEnum;
}

export const MoveCardConfirmDialog = ({
  isSuperuser,
  open,
  fromStage,
  toStage,
  onConfirm,
  onCancel,
}: MoveCardConfirmDialogProps) => {
  const [selectedOption, setSelectedOption] = useState<{
    code: number;
    description: string;
  }>({
    code: 0,
    description: '',
  });

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    setSelectedOption({
      code: Number(selectedOption.value),
      description: selectedOption.text,
    });
  };

  useEffect(() => {
    if (open) {
      setSelectedOption({
        code: LostReasonCodeMap[AllLostReasons[0]],
        description: AllLostReasons[0],
      });
    }
  }, [open, toStage]);

  if (toStage === 'lost') {
    return (
      <AlertDialog open={open}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Confirm Close Card as Lost{' '}
              {isSuperuser ? 'Superuser' : 'Loan Officer'}
            </AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to move the card from{' '}
              {capitalize(fromStage)} to {capitalize(toStage)}?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <div>
            <label
              htmlFor="stage-select"
              className="text-label-md font-bold mr-3"
            >
              Select a reason:
            </label>
            <select id="stage-select" onChange={handleSelectChange}>
              <option value="0" disabled>
                Select a Reason
              </option>
              {AllLostReasons.map((reason) => (
                <option key={reason} value={LostReasonCodeMap[reason]}>
                  {reason}
                </option>
              ))}
            </select>
          </div>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => onCancel?.()}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                if (selectedOption.code !== 0) {
                  onConfirm?.(selectedOption.code, selectedOption.description);
                }
              }}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Confirm Move Card</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to move the card from {capitalize(fromStage)}{' '}
            to {capitalize(toStage)}?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => onCancel?.()}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction onClick={() => onConfirm?.()}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
