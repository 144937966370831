import { IconProps } from '@/components/ui/icons/types';

export const LogoIcon = ({ className }: IconProps) => (
  <svg
    width="172"
    height="36"
    viewBox="0 0 172 36"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 26.4375L25.3125 31.5H18H10.6875L18 26.4375Z" fill="#FEFEFF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4.5L16.875 5.625V25.1364L18.0003 24.3407L19.125 25.136V5.625L18 4.5ZM14.625 9V26.7273L12.375 28.3183V11.25L14.625 9ZM11.0573 29.25H10.125V15.75L7.875 18V31.5H7.87522L11.0573 29.25ZM28.125 31.5H28.1246L24.9431 29.25H25.875V15.75L28.125 18V31.5ZM23.625 11.25V28.318L21.375 26.727V9L23.625 11.25Z"
      fill="#FEFEFF"
    />
    <g clipPath="url(#clip0_1247_8550)">
      <path
        d="M42.0001 10.3687H45.4512V27.3514H54.8932V30.177H42.0001V10.3687Z"
        fill="#FEFEFF"
      />
      <path
        d="M56.527 22.9357C56.527 18.5912 59.0684 15.3525 63.3002 15.3525C67.532 15.3525 69.9591 18.2206 69.9591 22.7365V23.8161H59.9341C60.0191 26.0601 61.311 27.749 63.5273 27.749C64.9759 27.749 66.325 27.1104 66.6517 25.8316H69.7892C69.2502 28.6572 66.722 30.5453 63.413 30.5453C59.2237 30.5453 56.5256 27.3228 56.5256 22.9343L56.527 22.9357ZM66.5667 21.3889C66.4393 19.4862 65.1605 18.1517 63.287 18.1517C61.314 18.1517 60.107 19.5433 59.9356 21.3889H66.5667Z"
        fill="#FEFEFF"
      />
      <path
        d="M71.7927 26.4717C71.7927 23.7458 73.795 22.5813 77.0044 21.9983C79.2191 21.5867 80.5536 21.4446 80.5536 19.9681C80.5536 18.8036 79.6732 18.1503 78.2246 18.1503C76.6763 18.1503 75.5835 18.8885 75.5264 20.3372H72.2599C72.3171 17.2275 74.8585 15.3525 78.2802 15.3525C81.4324 15.3525 83.8742 16.9287 83.8742 19.9666V26.5831C83.8742 27.2217 84.031 27.6333 84.7824 27.6333C84.9816 27.6333 85.2511 27.6055 85.4504 27.5337V30.0898C85.194 30.204 84.5978 30.3593 83.9167 30.3593C82.1136 30.3593 81.1761 29.2944 80.9491 27.7886L81.2332 27.8457C80.2958 29.479 78.5204 30.5439 76.3057 30.5439C73.7071 30.5439 71.7897 29.1098 71.7897 26.4688L71.7927 26.4717ZM80.5536 25.8185V22.9211C79.8578 23.589 79.1767 23.8439 77.6006 24.213C75.9966 24.5968 75.1441 25.0656 75.1441 26.2301C75.1441 27.3228 75.8824 27.7783 77.0044 27.7783C78.5658 27.7783 79.9017 26.983 80.5536 25.8185Z"
        fill="#FEFEFF"
      />
      <path
        d="M86.8875 23.0352C86.8875 18.3068 89.5285 15.3538 92.9502 15.3538C94.9379 15.3538 96.3588 16.4465 96.9549 17.9245L96.7982 18.1516V10.2271H100.135V30.1775H96.8685V27.5511L97.138 27.5936C96.5419 29.2122 94.7812 30.5466 92.6514 30.5466C89.2721 30.5466 86.886 27.5218 86.886 23.0352H86.8875ZM96.7997 23.0498V22.8506C96.7997 20.0953 95.4799 18.1794 93.5625 18.1794C91.4898 18.1794 90.2828 20.1246 90.2828 22.9927C90.2828 25.8607 91.462 27.721 93.5493 27.721C95.4946 27.721 96.8011 25.8183 96.8011 23.0498H96.7997Z"
        fill="#FEFEFF"
      />
      <path
        d="M103.234 24.0151H105.349C105.548 26.9256 107.678 28.6731 110.788 28.6731C113.457 28.6731 115.631 27.3812 115.631 24.9526C115.631 22.5239 113.387 21.73 110.306 20.8629C107.608 20.1246 103.817 19.13 103.817 15.1121C103.817 11.4487 106.941 10 110.434 10C114.523 10 116.965 11.9877 117.221 15.6936H115.106C114.864 13.1669 113.133 11.8735 110.35 11.8735C107.823 11.8735 105.948 12.953 105.948 15.0682C105.948 17.4543 108.32 18.093 110.847 18.8166C115.049 20.0382 117.762 21.1734 117.762 24.8237C117.762 28.9134 114.368 30.5466 110.789 30.5466C106.26 30.5466 103.392 28.0198 103.236 24.0151H103.234Z"
        fill="#FEFEFF"
      />
      <path
        d="M121.281 10.3687H123.496V12.9101H121.281V10.3687ZM121.353 15.7225H123.426V30.177H121.353V15.7225Z"
        fill="#FEFEFF"
      />
      <path
        d="M127.076 22.865C127.076 18.6332 129.533 15.3535 133.538 15.3535C137.271 15.3535 139.827 18.2787 139.827 22.5105V23.4055H129.206C129.263 26.1182 130.797 28.6875 133.693 28.6875C135.511 28.6875 137.057 27.7222 137.541 26.0611H139.558C139.032 28.7168 136.66 30.5478 133.622 30.5478C129.405 30.5478 127.076 27.0118 127.076 22.8664V22.865ZM137.698 21.7136C137.57 19.1297 135.922 17.2123 133.523 17.2123C130.981 17.2123 129.405 19.3143 129.234 21.7136H137.698Z"
        fill="#FEFEFF"
      />
      <path
        d="M143.05 31.726H145.165C145.435 33.2596 147.011 34.1399 148.901 34.1399C151.342 34.1399 152.891 32.5785 152.891 30.0795V26.9419L153.075 27.0562C152.223 29.1582 150.462 30.1659 148.461 30.1659C144.868 30.1659 142.469 27.0416 142.469 22.8669C142.469 18.4799 145.039 15.3555 148.589 15.3555C150.776 15.3555 152.508 16.5771 153.175 18.6776H152.948V15.7246H154.978V30.0663C154.978 33.6873 152.494 36.0017 148.901 36.0017C145.748 36.0017 143.362 34.3259 143.05 31.7274V31.726ZM152.891 22.9358V22.5666C152.891 19.6693 151.357 17.2128 148.786 17.2128C146.088 17.2128 144.584 19.826 144.584 22.8068C144.584 25.7877 146.118 28.3306 148.731 28.3306C151.344 28.3306 152.891 25.6471 152.891 22.9343V22.9358Z"
        fill="#FEFEFF"
      />
      <path
        d="M158.556 22.865C158.556 18.6332 161.013 15.3535 165.018 15.3535C168.751 15.3535 171.307 18.2787 171.307 22.5105V23.4055H160.686C160.743 26.1182 162.277 28.6875 165.173 28.6875C166.991 28.6875 168.537 27.7222 169.021 26.0611H171.038C170.512 28.7168 168.14 30.5478 165.102 30.5478C160.885 30.5478 158.556 27.0118 158.556 22.8664V22.865ZM169.178 21.7136C169.05 19.1297 167.402 17.2123 165.003 17.2123C162.461 17.2123 160.885 19.3143 160.714 21.7136H169.178Z"
        fill="#FEFEFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_1247_8550">
        <rect
          width="129.307"
          height="26"
          fill="white"
          transform="translate(42 10)"
        />
      </clipPath>
    </defs>
  </svg>
);
