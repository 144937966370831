type Variant = 'orange' | 'green-light' | 'green' | 'green-lightest';

interface StatsProps {
  variant?: Variant;
  icon: React.ReactNode;
  value: React.ReactNode;
  label: React.ReactNode;
}

const VARIANT_WRAPPER_CLASS: Record<Variant, string> = {
  orange: 'bg-orange-tint text-navy-navy',
  'green-light': 'bg-green-shade text-green-tint',
  'green-lightest': 'bg-green-tint text-green-shade',
  green: 'bg-green-green text-green-tint',
};

const VARIANT_ICON_CLASS: Record<Variant, string> = {
  'green-lightest': 'bg-green-shade text-green-tint',
  orange: 'bg-navy-navy',
  'green-light': 'bg-green-tint',
  green: 'bg-green-tint',
};

export const Stats = ({ variant, icon, value, label }: StatsProps) => {
  return (
    <div
      className={`px-6 py-3 space-y-3 rounded-lg shadow ${VARIANT_WRAPPER_CLASS[variant ?? 'orange'] ?? ''}`}
    >
      <div className="flex space-x-3 items-center">
        <div
          className={`min-w-12 min-h-12 py-2 rounded-3xl justify-center items-center gap-2.5 inline-flex ${VARIANT_ICON_CLASS[variant ?? 'orange'] ?? ''}`}
        >
          {icon}
        </div>

        <div className="text-display-01">{value}</div>
      </div>
      <div className="text-label-md">{label}</div>
    </div>
  );
};
