type Variant =
  | 'orange'
  | 'orange-tint'
  | 'orange-shade'
  | 'green-shade'
  | 'green'
  | 'green-tint'
  | 'navy'
  | 'navy-tint'
  | 'navy-shade'
  | 'blue'
  | 'blue-tint'
  | 'blue-shade';

interface StatsProps {
  variant?: Variant;
  icon: React.ReactNode;
  value: React.ReactNode;
  label: React.ReactNode;
  small?: boolean;
}

const VARIANT_WRAPPER_CLASS: Record<Variant, string> = {
  'orange-tint': 'bg-orange-tint text-navy-navy',
  'orange-shade': 'bg-orange-shade text-navy-navy',
  orange: 'bg-orange-orange text-navy-navy',
  'green-shade': 'bg-green-shade text-green-tint',
  'green-tint': 'bg-green-tint text-green-shade',
  green: 'bg-green-green text-green-tint',
  'navy-tint': 'bg-navy-tint text-light-tint',
  'navy-shade': 'bg-navy-shade text-light-tint',
  navy: 'bg-navy-navy text-light-tint',
  'blue-tint': 'bg-blue-tint text-navy-navy',
  'blue-shade': 'bg-blue-shade text-light-tint',
  blue: 'bg-blue-blue text-light-tint',
};

const VARIANT_ICON_CLASS: Record<Variant, string> = {
  'orange-tint': 'bg-navy-navy',
  'orange-shade': 'bg-navy-navy',
  orange: 'bg-navy-navy',
  'green-tint': 'bg-green-shade text-green-tint',
  'green-shade': 'bg-green-tint',
  green: 'bg-green-tint',
  'navy-tint': 'bg-light-tint',
  'navy-shade': 'bg-light-tint',
  navy: 'bg-light-tint',
  'blue-tint': 'bg-navy-navy',
  'blue-shade': 'bg-light-tint',
  blue: 'bg-light-tint',
};

export const MiniStats = ({
  variant,
  icon,
  value,
  label,
  small,
}: StatsProps) => {
  return (
    <div
      className={`flex space-x-3 items-center px-3 py-1.5 space-y-3 rounded-lg shadow ${VARIANT_WRAPPER_CLASS[variant ?? 'orange'] ?? ''}`}
    >
      <div>
        <div
          className={`rounded-full justify-center items-center p-1 inline-flex ${VARIANT_ICON_CLASS[variant ?? 'orange'] ?? ''}`}
        >
          {icon}
        </div>
      </div>
      <div>
        <div className={small ? 'text-display-02' : 'text-display-01'}>
          {value}
        </div>
        <div className={small ? 'text-label-sm' : 'text-label-md'}>{label}</div>
      </div>
    </div>
  );
};
