import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { EditIcon } from '@/components/icons';
import { useCallback, useEffect, useState } from 'react';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { AppSettingResponse } from '@/pages/data-pipeline/api-usage/types';

const formSchema = z.object({
  endatoQuota: z
    .number()
    .min(0, { message: 'Quota must be a positive number' }),
  firstAmericanQuota: z
    .number()
    .min(0, { message: 'Quota must be a positive number' }),
  dncQuota: z.number().min(0, { message: 'Quota must be a positive number' }),
  firstAmericanCountQuota: z
    .number()
    .min(0, { message: 'Quota must be a positive number' }),
  fredQuota: z.number().min(0, { message: 'Quota must be a positive number' }),
});

interface EditQuotaDialogProps {
  appSetting: AppSettingResponse;
  updateAppSetting(newAppSetting: Partial<AppSettingResponse>): Promise<void>;
}

export const EditQuotaDialog = ({
  appSetting,
  updateAppSetting,
}: EditQuotaDialogProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { ...appSetting },
  });

  const [updating, setUpdating] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (!updating) {
      form.reset({ ...appSetting });
    }
  }, [appSetting, updating, form]);

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      setUpdating(true);

      try {
        await updateAppSetting(values);
        setDialogOpen(false);
      } finally {
        setUpdating(false);
      }
    },
    [updateAppSetting]
  );

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <button className="w-6 h-6" onClick={() => setDialogOpen(true)}>
          <EditIcon />
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Edit Quotas</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-3" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-1">
              <Label htmlFor="dncQuota">Real Phone Validator Quota</Label>
              <FormField
                control={form.control}
                name="dncQuota"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        onChange={(e) => field.onChange(Number(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="endatoQuota">Endato Quota</Label>
              <FormField
                control={form.control}
                name="endatoQuota"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        onChange={(e) => field.onChange(Number(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="firstAmericanQuota">First American Quota</Label>
              <FormField
                control={form.control}
                name="firstAmericanQuota"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        onChange={(e) => field.onChange(Number(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="firstAmericanCountQuota">
                First American Count Quota
              </Label>
              <FormField
                control={form.control}
                name="firstAmericanCountQuota"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        onChange={(e) => field.onChange(Number(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="fredQuota">Fred Quota</Label>
              <FormField
                control={form.control}
                name="fredQuota"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        onChange={(e) => field.onChange(Number(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button type="submit" disabled={updating}>
                {updating ? 'Updating' : 'Update'}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
