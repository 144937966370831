import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { useApi } from '@/hooks/use-api';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '@/components';
import { Item } from '@/components/map/property-details/item';
import { Sidebar } from '@/pages/settings/common/sidebar';

interface AppConfig {
  environment: string;
  dncEnabled: boolean;
  firstAmericanLimit: string;
}
export const AppSettings = () => {
  const { getRequest } = useApi();
  const { isPending, error, data } = useQuery<AppConfig>({
    queryKey: ['appConfig'],
    queryFn: () => getRequest('/api/admin/app/config'),
  });

  return (
    <Sidebar currentPage="platform-setting">
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-heading-01">General Setting</div>
        </div>
        <Card>
          <CardContent>
            <div className="pt-6">
              {isPending && <Loader />}
              {error && <div>{error.message}</div>}
              {data && (
                <div>
                  <ul className="max-w-[380px]">
                    <Item label="Environment" value={data.environment} />
                    <Item
                      label="DNC Check Enabled"
                      value={data.dncEnabled ? 'Yes' : 'No'}
                    />
                    <Item
                      label="First American Report Limit"
                      value={data.firstAmericanLimit}
                    />
                  </ul>
                </div>
              )}
            </div>
          </CardContent>
          <CardFooter></CardFooter>
        </Card>
      </div>
    </Sidebar>
  );
};
