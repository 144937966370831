export const DropdownButtonIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.48333 5.2583L2.5 6.24163L8 11.7416L13.5 6.24163L12.5167 5.2583L8 9.77494L3.48333 5.2583Z"
      fill="currentColor"
    />
  </svg>
);
