export const quartileColors: Record<number, string> = {
  1: '#D30026',
  2: '#E4A701',
  3: '#0885BA',
  4: '#08A057',
};

export const quartileImages: Record<number, string> = {
  1: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABVCAYAAADXN8NkAAACYklEQVR4Xu2Zy3HCMBRFVUJKYAP2ZEUJlJASKCEl0AElJB1QAiV4gtlTAiUkesCb2M+SLX/kj3TPzN0Q2bFPriU5KAUAAAAAAAAAAAAAAABgNtzU6o0iPwcdIJFXtckokNoTlpmrzS8FUnsgZUJqD2wyIbUDTTIhtQWuMiHVgbYyIbWGrjIh1UBfmZBaYCiZnKilDi2TE6VUXzI5UUn1LZMThdSxZHKCljq2TE6QUqeSyQlOqr6hL3mTY4euQV7XYsl0O/KJG0rXIK9r0UwlNUiZzNhSg5bJjCU1CpmMb6lRyWR8SY1SJjO01KhlMkNJhcwCfaVCpoGuUiGzhpfUirS6ZCpdyfOAAlrSSUqzJaj3c1/kKt1JcfakO3k8MJCr5FyVVw7NnfI4YOGi0r0UKHNR6708DligxemiNncpsdDOmzwGNKDFHaTIf6HJUY4HL2hhuar1UT7CdVsouVWiKYJW/Kt63xY/jwb6fudHJZ/lxSc5y3G5YQtl2ioVz0OLFf1xgvoOyQa3MbfOj+VtkHkLVR6jz7etjnnkHmRrzW20JTnJ42X75M9JWvU85QTR2uY2lkJjDqZ38uIWSs6zBM2nekfwrReqm+G8Mstqbbs2Umhc/dsOb6FctkpP+W6/e9atHaqNNmh8m63SYlubPRYEt0bQheub/JDncIHky62SK88npnItxjz/FTix2Lm2wfdTMwpTz2E+5vBZMHZrg2ijK75aG2wbXRmqtVG10ZUurY26ja60bG1T4mijK21aW06kbXTFsbVoYxeqrUUbB4FaizYCAAAAAAAAAAAAAAB68gcdG8+T6CKwvAAAAABJRU5ErkJggg==',
  2: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABVCAYAAADXN8NkAAACYklEQVR4Xu2Zy3GDMBRFBXb2KSEluASXkBIoISW4g5RANrGYbFyCS0gJzMR4sqSEBBFkw5MUxNdYumfmbggi1pkLfh4YAwAAAAAAAAAAAAAAALAY0gN7FKHHQQ+EyCwJPkUgdSAXmTz4KQOp/VFkQmp/jDIhtTutMiHVHmuZkNpOZ5mQaqa3TEhVGSwTUq+MJlPGZ6mjy5TxUepkMmV8kjq5TBkfpM4mU8ZlqbPLlHFR6s1kyrgm9czDWNnk3EnCmH6uuyWNi4bu0dBRuZlUF2VKZpfqskzJbFJ9kCmZXKpPMiWTSfVRpmR0qT7LlIwmFTKvDJYKmSq9pUKmmVIqFdaSb86e6HVAjXMSHKg0Y1z6fT4VJ862ijhDxLl0PdBQyDpSeUqKZyddBwxknEWKQCUsouuAgfLLKQlyVeIlKV0DWjjxcKcR+ZckfKXng4oTX2+FoIyvovrx/0YoOiqJtRkP4+zjYVM/7g3lO6Z9+JI1v3yO9DztCKUZlRo/CMp3V6vIi2H/0kbD85GOQboRSj1nrZxTic2dbK2hjdqIRtL1jXWaUcnqBaALrW1rI9lwXjRvp9twc4RSRyXxPC2a+Fb8PVWuS3Nvre3SxmqDR3oLU2ojVOuoVMpPrP/3cls7VhtNlCNUh1HpblubvbNNZt/G/GvPnuk1bBAtpaOSLdUdo34eXURriz3Ra8zKUtsw9V0zC7d+hk3xDF8Ec7fWiTbaMlVrnW2jLWO11qs22tKntV630ZZOrW2LL220pVNrmyL9bKMtVq1FG/uhtBZtHAfRWrQRAAAAAAAAAAAAAAAwkF8i5UQq11ofSQAAAABJRU5ErkJggg==',
  3: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABVCAYAAADXN8NkAAACaUlEQVR4Xu2Z3W3CMBSF+1JhygsjdARGYISOkBG6QZFogtQXRqAb8FgleWCEjsAIjND6RlgyNw5xfpwE+3zSkRC1A3w6CTf06QkAAAAAAAAAAAAAAADAZFh+nJYU/jxoAYmcx9kvBVI7omSKOPujQGoHuExI7UCVTEhtQZ1MSG2ArUxItaCpTEi9Q1uZkGqgq0xI1ehLpkrQUvuWqRKkVFcyVYKS6lqmShBSh5Kp4rXUoWWqeCl1LJkq3kmVH+rAP+QIOfD39bBMo6FHfxpKjCXVS5mKoaV6LVMxlNQgZCpcSw1KpsKV1CBlKvqWGrRMRV9SIVOjq1TINNBWKmTegaRyYbXZ/bzy4wCNeZIfS9Kq48/9uSvENl8bxJkj1/L9wIBIslNJHstMXjv5PlCBFBZxgYZEfB+ogL6cZAMvBokqZ74H1CDidGMQWWSW5Hu+HlwRu3w9T9I9P4XvjlBsVKK9MofFV7bSnw+G4pRO8vebLx/5mK+rGKFKo5J+Q3B9HHn1P6QqVBtncW6+PrIxyDhC8TU7w5qYpoDiNfxrrbGNFaFG8v36PtOoRNL4cXi8aG1tG7UUa7bpxnRPTiK0tVHp7/J6Kj7Tbyn+zI/L83CtbdLGIrSu5m5HG6FqRyVB8i1fe9Kt7auNVdAI1WRUetjWLuJs1aARl5dt+saPYUMxQrX8Vak4YwzvxxRq7XM8stiptsH1WTMIYuRrmItr+CQYurVetNEW4ai13rbRlr5aG1QbbREtWht0G21p0tq6BNNGW0SD1t4k1DbaYtNatLElgrcWbewHai3aCAAAAAAAAAAAAAAA6Mg/420eaGpZfZgAAAAASUVORK5CYII=',
  4: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABVCAYAAADXN8NkAAACVUlEQVR4Xu2ZzVHDMBCFuTAYckkJlJASKCEXEsOJEiiBDlKC3AElpARKSAkpAVZOdlDWUizbku1I75t5lyDl55tneUPu7gAAAAAAAAAAAAAAAADMhqVaL3Xk46AHWuRjtfnRgdSBsMyi2v7qQOoApExIHYBLJqT2oE0mpHbAVyaketBVJqReoa9MSLUwVCakGoSSyclaamiZnCylxpLJyUpqbJmcLKSOJZOTtNSxZXKSlDqVTE5yUouqVPJDThAl39fNcmroFg0NyVRSk5TJjC01aZnMWFKzkMnElpqVTCaW1CxlMqGlZi2TCSUVMg2GSoVMC32lQuYVtBgprDWqfJbPAwyopd8Nae6k8/08FtS4F4s4e2it3A8skKx9Q57IA523ch9wQM37kAIboTVyH3Cgb07UwGND4n8Ocg9ogRr4ZRFZh2Tv5HpwRt9Y6M6+k5fw1RFKjErnI0It1PvKfDwb6ktavX0WlzefvVznGKEao5L5haD+7YoEZzHscxud56MYg6wjlM+aqj4W9Guk11pHG63RjZT7zX22UcnnB8AkWtvaRiP1GroJ2T7wxQhlGZX0eUqp6O8H+bwyN9faLm08pdzLS1hijFCto9JJfun12rNubag2utDru4xKN9vahXpdFZ5tJOHHJ7VZy+fwoR6hev5X6XzFNN6PLbq191OLnWsbYl81ozD1GRbjDJ8FY7c2iTb6Equ1ybbRl1CtzaqNvvRpbdZt9KVLa9uSTRt96dLay2TaRl98Wos29qTZWrQxCLq1aCMAAAAAAAAAAAAAAGAgf/Y16UmffS4GAAAAAElFTkSuQmCC',
};
