export const CheckedIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_19424)">
      <circle cx="8" cy="8" r="4" fill="#000723" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.22222 1H1V6.22222H0V1V0H1H6.22222V1ZM9.77778 15H15V9.77778H16V6.22222H15V1H9.77778V0H15H16V1V15V16H15H1H0V15V9.77778H1V15H6.22222V16H9.77778V15Z"
        fill="#000723"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_19424">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
