import { Slider } from '@/components/ui/slider';

export interface Range {
  from: number;
  to: number;
}

export interface InterestRateSliderProps {
  selectedInterestRateRange: Range;
  onSelectInterestRateRange: (selectedInterestRateRange: Range) => void;
}

export const InterestRateSlider = ({
  selectedInterestRateRange,
  onSelectInterestRateRange,
}: InterestRateSliderProps) => {
  return (
    <div className="flex items-center justify-center space-x-2 px-6">
      <div className="text-eyebrow-md text-navy-tint border-[1px] px-auto w-[90px] border-navy-tint rounded py-1.5 flex items-center justify-center">
        {selectedInterestRateRange.from}%
      </div>
      <Slider
        defaultValue={[
          selectedInterestRateRange.from,
          selectedInterestRateRange.to,
        ]}
        min={0}
        max={20}
        step={0.1}
        onValueChange={(values) => {
          onSelectInterestRateRange({ from: values[0], to: values[1] });
        }}
      />
      <div className="text-eyebrow-md text-navy-tint border-[1px] px-auto w-[90px] border-navy-tint rounded py-1.5 flex items-center justify-center">
        {selectedInterestRateRange.to}%
      </div>
    </div>
  );
};
