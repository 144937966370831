import { Slider } from '@/components/ui/slider';

export interface WholesaleInterestRateSliderProps {
  selectedInterestRate: number;
  onSelectInterestRate: (selectedInterestRate: number) => void;
}

export const WholesaleInterestRateSlider = ({
  selectedInterestRate,
  onSelectInterestRate,
}: WholesaleInterestRateSliderProps) => {
  const formattedInterestRate = parseFloat(selectedInterestRate.toString());

  return (
    <div className="flex flex-col items-center space-y-2 px-6">
      <div className="flex items-center justify-center space-x-2 px-6 w-full">
        <div className="text-eyebrow-md text-navy-tint border-[1px] px-auto w-[90px] border-navy-tint rounded py-1.5 flex items-center justify-center">
          0%
        </div>
        <Slider
          defaultValue={[formattedInterestRate]}
          min={0}
          max={20}
          step={0.125}
          onValueChange={(values) => {
            onSelectInterestRate(values[0]);
          }}
        />
        <div className="text-eyebrow-md text-navy-tint border-[1px] px-auto w-[90px] border-navy-tint rounded py-1.5 flex items-center justify-center">
          20%
        </div>
      </div>

      <div className="text-eyebrow-md text-navy-tint">
        {formattedInterestRate}%
      </div>
    </div>
  );
};
