import { DncComplianceLogEntry } from '@/pages/admin/dnc-compliance-log/types';

export const searchDncComplianceLog = (
  searchString: string,
  data: DncComplianceLogEntry[]
): DncComplianceLogEntry[] => {
  if (searchString.trim() === '') {
    return data;
  }

  const searchWords = searchString.toLowerCase().split(' ');

  return data.filter((dncComplianceLog) => {
    return searchWords.every((word) => {
      return (
        (dncComplianceLog.phone ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (dncComplianceLog.incomingIpAddress ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (dncComplianceLog.outgoingIpAddress ?? '')
          .toLowerCase()
          .includes(word.toLowerCase())
      );
    });
  });
};
