import { MarketFilter } from '@/components/market/assign-leads-dialog/type';
import {
  defaultAprRange,
  defaultBuildYearRange,
  defaultMortgageBalanceRange,
  defaultSaleDateRange,
} from '@/components/filters/sections/constants';
import { toISODate } from '@/utils/date';

export const generateQueryParams = (
  filter: MarketFilter,
  forceFilter = true
): string => {
  const params = new URLSearchParams();

  if (
    filter.selectedAprRange.from !== defaultAprRange.from ||
    filter.selectedAprRange.to !== defaultAprRange.to ||
    forceFilter
  ) {
    params.append('min_interest_rate', filter.selectedAprRange.from.toString());
    params.append('max_interest_rate', filter.selectedAprRange.to.toString());
  }

  if (
    filter.selectedMortgageBalanceRange.from !==
      defaultMortgageBalanceRange.from ||
    filter.selectedMortgageBalanceRange.to !== defaultMortgageBalanceRange.to ||
    forceFilter
  ) {
    params.append(
      'min_mortgage_amount',
      filter.selectedMortgageBalanceRange.from.toString()
    );
    params.append(
      'max_mortgage_amount',
      filter.selectedMortgageBalanceRange.to.toString()
    );
  }

  if (
    filter.selectedBuildYearRange.from !== defaultBuildYearRange.from ||
    filter.selectedBuildYearRange.to !== defaultBuildYearRange.to ||
    forceFilter
  ) {
    params.append(
      'min_year_built',
      filter.selectedBuildYearRange.from.toString()
    );
    params.append(
      'max_year_built',
      filter.selectedBuildYearRange.to.toString()
    );
  }

  if (
    filter.selectedSaleDateRange.from !== defaultSaleDateRange.from ||
    filter.selectedSaleDateRange.to !== defaultSaleDateRange.to ||
    forceFilter
  ) {
    params.append(
      'min_record_date_last_arms_length_sale',
      toISODate(filter.selectedSaleDateRange.from)
    );
    params.append(
      'max_record_date_last_arms_length_sale',
      toISODate(filter.selectedSaleDateRange.to)
    );
  }

  params.append(
    'lender_competitiveness_ranking',
    filter.selectedRankings.join(',')
  );
  params.append('callable_owners', filter.selectedCallableOwners.join(','));
  params.append('emailable_owners', filter.selectedEmailableOwners.join(','));
  if (filter.selectedDoNotMails.length === 1) {
    params.append('do_not_mail', filter.selectedDoNotMails[0].toString());
  }
  params.append(
    'original_mortgage_type',
    filter.selectedMortgageTypes.join(',')
  );

  return params.toString();
};
