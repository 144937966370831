import { AuthLayout } from '@/components/layouts/auth';
import { ForgotPasswordFormContainer } from '@/pages/auth/forgot-password-page/forgot-password-form-container';

export const ForgotPasswordPage = () => {
  return (
    <AuthLayout title="Forgot your password?">
      <ForgotPasswordFormContainer />
    </AuthLayout>
  );
};
