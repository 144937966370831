export const IdncIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.31828 11.9L10.9908 8.2275L10.0483 7.285L7.30203 10.0312L5.93703 8.66625L5.01078 9.5925L7.31828 11.9ZM2.80078 14.5V1.5H9.30078L13.2008 5.4V14.5H2.80078ZM8.65078 6.05H11.9008L8.65078 2.8V6.05Z"
      fill="#192A68"
    />
  </svg>
);
