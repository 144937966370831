import {
  DataImport,
  DataImportListResponse,
} from '@/pages/data-pipeline/run-history/types';
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import { Loader } from '@/components';
import { ReactTable } from '@/components/table/react-table';
import { useState } from 'react';
import { RunHistoryDetails } from '@/pages/data-pipeline/run-history/run-history-details';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/use-api';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';

export const RunHistoryContainer = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const navigate = useNavigate();

  const { getRequest } = useApi();

  const { isPending, error, data } = useQuery<DataImportListResponse>({
    queryKey: ['data-imports'],
    queryFn: () => getRequest('/api/data_pipeline/data_imports'),
  });

  const columns: ColumnDef<DataImport>[] = [
    {
      accessorKey: 'id',
      cell: ({ row }) => {
        const dataImport = row.original as DataImport;
        return (
          <div className="-m-4">
            <RunHistoryDetails dataImport={dataImport} />
            <div className="mb-6">
              <Button
                variant="default"
                onClick={() =>
                  navigate(`/admin/manual-data-imports/${dataImport.id}`)
                }
              >
                View Details
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  const table = useReactTable<DataImport>({
    data: data?.data ?? [],
    columns,
    state: {
      pagination,
    },
    enableRowSelection: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="no-border">
      {isPending && <Loader />}
      {!isPending && <ReactTable table={table} hideColumn />}
    </div>
  );
};
