import { Board, Loader } from '@/components';
import { useApi } from '@/hooks/use-api';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  BoardType,
  SalesPipeline,
  SalesPipelineListResponse,
  StageEnum,
} from '@/pages/crm/lead-stages/types';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import { PropertyDetailsDialog } from '@/pages/map/map/property-details-dialog';
import { useCallback, useEffect, useState } from 'react';
import { SearchIcon } from '@/pages/crm/lead-stages/icons/search-icon';
import { searchSalesPipeline } from '@/pages/crm/lead-stages/utils';
import { useToast } from '@/components/ui/use-toast';
import {
  getMortgageType,
  getOwnerEmails,
  isClose,
  isDemoteStage,
  isPassingToAE,
  isPassingToLO,
  isPromoteStage,
  isReactivate,
} from '@/components/crm/board/utils';
import {
  MoveCardConfirmDialog,
  MoveCardConfirmDialogProps,
} from '@/components/crm/board/move-card-confirm-dialog';
import { NewLeadDialogContainer } from '@/pages/crm/lead-stages/new-lead-dialog-container';
import { Button } from '@/components/ui/button';
import { PlusIcon } from '@/components/ui/icons/plus-icon';
import { ManualProperty } from '@/pages/admin/property-data/property-table';
import { useAuth } from '@/context';
import {
  EmailTemplate,
  EmailTemplateListResponse,
} from '@/pages/admin/email-templates/types';
import { useLocation } from 'react-router-dom';
import {
  generateEmailContent,
  getFieldMapping,
} from '@/pages/admin/email-templates/email-fields';
import { usePropertyCard } from '@/hooks/use-property-card';
import {
  AssignCardConfirmDialog,
  AssignCardConfirmDialogProps,
} from '@/components/crm/board/assign-card-confirm-dialog';
import { User, UserListResponse } from '@/pages';
import { UserDropdown } from '@/components/filters/common/user-dropdown';
import {
  defaultTaskPreference,
  TaskItems,
  TaskPreference,
  taskPreferenceSchema,
} from '@/pages/crm/lead-stages/task-items';
import { loadPreference } from '@/utils/preference';
import { AppSettingResponse } from '@/pages/data-pipeline/api-usage/types';

interface UIState {
  newLeadDialog: boolean;
  creatingNewLead: boolean;
}

interface LeadStagesContainerProps {
  type: BoardType;
}

export const LeadStagesContainer = ({ type }: LeadStagesContainerProps) => {
  const { getRequest, patchRequest, postRequest, putRequest } = useApi();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const salesPipelineId = queryParams.get('id');
  const { userDetails } = useAuth();
  const { toast } = useToast();
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

  const { isPending, error, data, refetch } =
    useQuery<SalesPipelineListResponse>({
      queryKey: ['salesPipelinesData', type],
      queryFn: () =>
        getRequest(`/api/sales_pipelines?board_type=${type.toLowerCase()}`),
    });
  const { isPending: isUsersPending, data: userList } =
    useQuery<UserListResponse>({
      queryKey: ['usersData'],
      queryFn: () => getRequest('/api/users'),
    });
  const {
    isPending: isAppSettingLoading,
    data: appSettingData,
    error: appSettingError,
  } = useQuery<AppSettingResponse>({
    queryKey: ['app-setting'],
    queryFn: () => getRequest('/api/app/setting'),
  });
  const {
    isPending: isEmailTemplatesPending,
    data: emailTemplates,
    error: emailTemplatesError,
  } = useQuery<EmailTemplateListResponse>({
    queryKey: ['email_templates'],
    queryFn: () => getRequest('/api/email_templates'),
  });
  const [propertyDetailsDialog, setPropertyDetailsDialog] = useState<{
    open: boolean;
    salesPipeline?: SalesPipeline;
  }>({
    open: false,
  });
  const [taskPreference, setTaskPreference] = useState<TaskPreference>(
    loadPreference(
      'leadStagesTaskState',
      taskPreferenceSchema,
      defaultTaskPreference
    )
  );
  const { isPending: isCreatingNewLead, mutateAsync: createNewLead } =
    useMutation({
      mutationFn: (newManualProperty: ManualProperty) =>
        postRequest('/api/crm/manual_properties', newManualProperty),
      onSuccess: async () => {
        await refetch();

        setUIState((prevState) => ({
          ...prevState,
          newLeadDialog: false,
          creatingNewLead: false,
        }));
        toast({
          variant: 'success',
          description: 'New lead was successfully created',
          title: 'Lead Created',
        });
      },
      onError: (error) => {
        console.error('Failed to create new lead', error);

        const errorMessage =
          (error as Error).message || 'An unknown error occurred';

        setUIState((prevState) => ({ ...prevState, creatingNewLead: false }));
        toast({
          variant: 'destructive',
          description: errorMessage,
          title: 'Failed to Create Lead',
        });
      },
    });

  const { mutateAsync: runDncCheck } = useMutation({
    mutationFn: ({ salesPipelineId }: { salesPipelineId: number }) =>
      putRequest(`/api/sales_pipelines/${salesPipelineId}/check_dnc`, {}),
    onSuccess: (salesPipeline: SalesPipeline) => {
      overrideSalesPipeline(salesPipeline);

      toast({
        variant: 'success',
        description: 'DNC was successfully checked',
        title: 'DNC Checked',
      });
    },
    onError: (error) => {
      console.error('Failed to check dnc', error);

      const errorMessage =
        (error as Error).message || 'An unknown error occurred';

      setUIState((prevState) => ({ ...prevState, creatingNewLead: false }));
      toast({
        variant: 'destructive',
        description: errorMessage,
        title: 'Failed to Check DNC',
      });
    },
  });

  const [moveCardConfirmDialog, setMoveCardConfirmDialog] =
    useState<MoveCardConfirmDialogProps>({ open: false });

  const [assignCardConfirmDialog, setAssignCardConfirmDialog] =
    useState<AssignCardConfirmDialogProps>({ open: false });

  const [uiState, setUIState] = useState<UIState>({
    newLeadDialog: false,
    creatingNewLead: false,
  });

  useEffect(() => {
    if (userList?.data) {
      const user = userList?.data.find(
        (user) => user.email === userDetails.email
      );

      setSelectedUser(user);
    }
  }, [userDetails.email, userList?.data, type]);

  const [search, setSearch] = useState<string>('');

  const [filteredData, setFilteredData] = useState<SalesPipeline[]>([]);

  const overrideSalesPipeline = useCallback(
    (salesPipeline: SalesPipeline) => {
      if (propertyDetailsDialog.salesPipeline?.id === salesPipeline.id) {
        setPropertyDetailsDialog((prev) => ({
          ...prev,
          salesPipeline: {
            ...prev.salesPipeline!,
            ...salesPipeline,
          },
        }));
      }

      setFilteredData((prevData) =>
        prevData.map((pipeline) =>
          pipeline.id === salesPipeline.id
            ? { ...pipeline, ...salesPipeline }
            : pipeline
        )
      );
    },
    [propertyDetailsDialog.salesPipeline]
  );

  const afterChangeStage = useCallback(
    async ({
      toStage,
      salesPipeline,
    }: {
      toStage: string;
      salesPipeline: SalesPipeline;
    }) => {
      await refetch();

      if (propertyDetailsDialog.salesPipeline) {
        setPropertyDetailsDialog((prev) => ({
          ...prev,
          salesPipeline: {
            ...prev.salesPipeline!,
            stage: toStage as StageEnum,
            loStage: salesPipeline.loStage,
            aeStage: salesPipeline.aeStage,
          },
        }));
      }
    },
    [propertyDetailsDialog.salesPipeline, refetch]
  );

  const afterCloseAsLost = useCallback(
    async ({
      lostReasonCode,
      lostReasonDescription,
    }: {
      lostReasonCode: number;
      lostReasonDescription: string;
    }) => {
      await refetch();

      if (propertyDetailsDialog.salesPipeline) {
        setPropertyDetailsDialog((prev) => ({
          ...prev,
          salesPipeline: {
            ...prev.salesPipeline!,
            stage: 'lost',
            lostReasonCode,
            lostReasonDescription,
          },
        }));
      }
    },
    [propertyDetailsDialog.salesPipeline, refetch]
  );

  const afterUpdateSalesPipeline = useCallback(
    async ({ salesPipeline }: { salesPipeline: SalesPipeline }) => {
      if (propertyDetailsDialog.salesPipeline) {
        setPropertyDetailsDialog((prev) => ({
          ...prev,
          salesPipeline: {
            ...prev.salesPipeline!,
            ...salesPipeline,
          },
        }));
      }

      await refetch();
    },
    [propertyDetailsDialog.salesPipeline, refetch]
  );

  const afterReactivate = useCallback(async () => {
    const newData = await refetch();

    if (propertyDetailsDialog.salesPipeline) {
      const stage = newData.data?.data.find(
        (s) => s.id === propertyDetailsDialog.salesPipeline?.id
      )?.stage;

      setPropertyDetailsDialog((prev) => ({
        ...prev,
        salesPipeline: {
          ...prev.salesPipeline!,
          stage: stage ?? 'assigned_lead',
        },
      }));
    }
  }, [propertyDetailsDialog.salesPipeline, refetch]);

  const afterUpdateSalesPipelineOwner = useCallback(async () => {
    const { data } = await refetch();

    if (propertyDetailsDialog.salesPipeline) {
      const salesPipeline = data?.data.find(
        (s) => s.id === propertyDetailsDialog.salesPipeline?.id
      );
      setPropertyDetailsDialog((prev) => ({
        ...prev,
        salesPipeline,
      }));
    }
  }, [propertyDetailsDialog.salesPipeline, refetch]);

  const {
    changeStage,
    closeAsLost,
    updateSalesPipeline,
    reactivate,
    updateSalesPipelineOwner,
  } = usePropertyCard({
    afterChangeStage,
    afterCloseAsLost,
    afterUpdateSalesPipeline,
    afterReactivate,
    afterUpdateSalesPipelineOwner,
  });

  const openEmailClient = useCallback(
    async (salesPipeline: SalesPipeline) => {
      const mortgageType = getMortgageType(salesPipeline);
      const emailTemplate = emailTemplates?.data?.find(
        (template) => template.mortgageType === mortgageType
      );

      if (!emailTemplate) {
        console.error('No Email Template Found');
        return;
      }

      const emailFieldMapping = getFieldMapping(
        `${userDetails.firstName} ${userDetails.lastName}`,
        salesPipeline,
        appSettingData
      );

      const emailSubject = generateEmailContent(
        emailTemplate.subjectText,
        emailFieldMapping
      );
      const emailBody = generateEmailContent(
        emailTemplate.bodyText,
        emailFieldMapping
      );
      const ownerEmails = getOwnerEmails(salesPipeline);
      const mailtoLink = `mailto:${ownerEmails.join(',')}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;

      if (!salesPipeline.emailClientOpened) {
        try {
          await patchRequest(
            `/api/sales_pipelines/${salesPipeline.id}/mark_email_opened`,
            {}
          );
        } catch (error) {
          console.error('Failed to mark email as opened', error);
        }
      }

      window.open(mailtoLink, '_blank');
      if (!salesPipeline.emailClientOpened) {
        await refetch();
      }
    },
    [
      appSettingData,
      emailTemplates?.data,
      patchRequest,
      refetch,
      userDetails.firstName,
      userDetails.lastName,
    ]
  );

  const moveCard = useCallback(
    async (salesPipeline: SalesPipeline, destinationStage: StageEnum) => {
      if (
        isPassingToAE(salesPipeline, destinationStage) ||
        isPassingToLO(destinationStage)
      ) {
        setAssignCardConfirmDialog({
          open: true,
          users: userList?.data ?? [],
          fromStage: salesPipeline.stage,
          toStage: destinationStage,
          salesPipeline: salesPipeline,
          onConfirm: async (passingToUserId: number): Promise<void> => {
            await changeStage(
              salesPipeline.id,
              salesPipeline.stage,
              destinationStage,
              passingToUserId
            );
            setAssignCardConfirmDialog({ open: false });
          },
          onCancel: () => setAssignCardConfirmDialog({ open: false }),
        });
        return;
      }

      if (isPromoteStage(salesPipeline.stage, destinationStage)) {
        await changeStage(
          salesPipeline.id,
          salesPipeline.stage,
          destinationStage
        );
        return;
      }

      if (isDemoteStage(salesPipeline.stage, destinationStage)) {
        setMoveCardConfirmDialog({
          open: true,
          fromStage: salesPipeline.stage,
          toStage: destinationStage,
          onConfirm: async (): Promise<void> => {
            await changeStage(
              salesPipeline.id,
              salesPipeline.stage,
              destinationStage
            );
            setMoveCardConfirmDialog({ open: false });
          },
          onCancel: () => setMoveCardConfirmDialog({ open: false }),
        });
        return;
      }

      if (isClose(destinationStage)) {
        setMoveCardConfirmDialog({
          open: true,
          fromStage: salesPipeline.stage,
          toStage: destinationStage,
          onConfirm: async (
            lostReasonCode: number,
            lostReasonDescription: string
          ): Promise<void> => {
            await closeAsLost(
              salesPipeline.id,
              salesPipeline.stage,
              lostReasonCode,
              lostReasonDescription
            );
            setMoveCardConfirmDialog({ open: false });
          },
          onCancel: () => setMoveCardConfirmDialog({ open: false }),
        });
        return;
      }

      if (isReactivate(salesPipeline.stage, destinationStage)) {
        setMoveCardConfirmDialog({
          open: true,
          fromStage: salesPipeline.stage,
          toStage: destinationStage,
          onConfirm: async (): Promise<void> => {
            await reactivate(salesPipeline.id, destinationStage);
            setMoveCardConfirmDialog({ open: false });
          },
          onCancel: () => setMoveCardConfirmDialog({ open: false }),
        });
        return;
      }
    },
    [changeStage, closeAsLost, reactivate, userList?.data]
  );

  useEffect(() => {
    if (data) {
      setFilteredData(searchSalesPipeline(search, data.data));
      if (salesPipelineId) {
        const selectedPipeline = data.data.find(
          (pipeline) => pipeline.id === Number(salesPipelineId)
        );
        if (selectedPipeline)
          setPropertyDetailsDialog({
            open: true,
            salesPipeline: selectedPipeline,
          });
      }
    }
  }, [search, data, salesPipelineId]);

  if (error || emailTemplatesError || appSettingError) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          {error?.message ??
            emailTemplatesError?.message ??
            appSettingError?.message}
        </AlertDescription>
      </Alert>
    );
  }

  if (
    isPending ||
    isEmailTemplatesPending ||
    isUsersPending ||
    isAppSettingLoading
  ) {
    return <Loader />;
  }

  return (
    <div className="w-full flex -mt-3 space-x-2">
      <div className="space-y-3" style={{ width: 'calc(100% - 372px)' }}>
        <div className="flex justify-between items-center w-full px-4 py-2 bg-navy-navy rounded-lg">
          <div className="relative w-full">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
              <SearchIcon />
            </div>
            <input
              type="text"
              id="simple-search"
              className="h-8 w-[420px] text-label-md bg-gray-50 border border-gray-300 rounded ps-9 p-1 normal-case"
              placeholder="SEARCH BY OWNER, ADDRESS, PHONE..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-end space-x-2">
            {userList && (
              <div className="flex items-center space-x-3">
                <div className="text-red-red text-label-lg w-24 text-right">
                  User View:
                </div>
                <UserDropdown
                  users={userList.data ?? []}
                  selectedUser={selectedUser}
                  onUserChange={(user) => setSelectedUser(user)}
                  borderType={type}
                  alwaysIncludeCurrentUser
                />
              </div>
            )}
            {type === 'LO' && (
              <div>
                <Button
                  variant="secondary"
                  className="flex space-x-2"
                  onClick={() =>
                    setUIState((prevState) => ({
                      ...prevState,
                      newLeadDialog: true,
                    }))
                  }
                >
                  <span>
                    <PlusIcon className="h-4 w-4" />
                  </span>
                  <span>New Lead</span>
                </Button>
              </div>
            )}
          </div>
        </div>
        <Board
          type={type}
          salesPipelines={filteredData.filter((p) => {
            if (selectedUser?.email === userDetails.email) {
              return true;
            }

            if (selectedUser?.userType === 'LO') {
              return selectedUser.id === p.loAssignedUserId;
            }

            if (selectedUser?.userType === 'AE') {
              return selectedUser.id === p.aeAssignedUserId;
            }

            return true;
          })}
          onShowDetails={(salesPipeline: SalesPipeline) => {
            setPropertyDetailsDialog({
              open: true,
              salesPipeline: salesPipeline,
            });
            if (!salesPipeline.dncCheckRun && salesPipeline.property) {
              (async () =>
                await runDncCheck({ salesPipelineId: salesPipeline.id }))();
            }
          }}
          openEmailClient={openEmailClient}
          moveCard={moveCard}
          emailTemplates={emailTemplates?.data as EmailTemplate[]}
          appSetting={appSettingData}
        />
        <PropertyDetailsDialog
          users={userList?.data ?? []}
          changeStage={changeStage}
          closeAsLost={closeAsLost}
          reactivate={reactivate}
          updateSalesPipeline={updateSalesPipeline}
          open={propertyDetailsDialog.open}
          salesPipeline={propertyDetailsDialog.salesPipeline}
          onOpenChange={() => setPropertyDetailsDialog({ open: false })}
          updateSalesPipelineOwner={updateSalesPipelineOwner}
          boardType={type}
        />
        <MoveCardConfirmDialog
          {...moveCardConfirmDialog}
          isSuperuser={userDetails.isSuperuser}
        />
        <AssignCardConfirmDialog {...assignCardConfirmDialog} />
        <NewLeadDialogContainer
          open={uiState.newLeadDialog}
          onClose={() =>
            setUIState((prevState) => ({ ...prevState, newLeadDialog: false }))
          }
          createNewLead={createNewLead}
          isCreating={isCreatingNewLead}
        />
      </div>
      <div
        className="w-[360px] rounded-lg shadow flex flex-col"
        style={{ height: 'calc(100vh - 100px)' }}
      >
        <div className="w-full px-4 py-2 h-14 flex items-center hover:no-underline bg-light-light rounded-t-lg">
          <div className="text-heading-04 text-dark-dark">Tasks</div>
        </div>
        <div
          className="mt-3 bg-white flex-1 rounded-b-lg overflow-y-auto"
          style={{ height: 'calc(100vh - 300px)' }}
        >
          <TaskItems
            preferenceKey="leadStagesTaskState"
            taskPreference={taskPreference}
            setTaskPreference={setTaskPreference}
          />
        </div>
      </div>
    </div>
  );
};
