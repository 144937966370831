import { Sidebar } from '@/pages/settings/common/sidebar';
import { LenderListContainer } from '@/pages/admin/lender-list/lender-list-container';

export const LenderList = () => {
  return (
    <Sidebar currentPage="lenders">
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-heading-01">Lender Rankings</div>
        </div>
        <LenderListContainer />
      </div>
    </Sidebar>
  );
};
