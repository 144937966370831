import { LeaderCompetitiveness } from '@/pages/admin/property-data/property-table';
import { FilterContainer } from '@/components/filters/sections/filter-container';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';

interface Option {
  value: LeaderCompetitiveness;
  label: string;
}

export const RankingMap: Record<LeaderCompetitiveness, string> = {
  '-1': 'Unassigned',
  '4': 'Ultra Low',
  '3': 'Low',
  '2': 'Normal',
  '1': 'High',
  '0': 'Ultra High',
};

export const RANKING_OPTIONS: Option[] = [
  {
    value: -1,
    label: 'Unassigned',
  },
  {
    value: 4,
    label: 'Ultra Low',
  },
  {
    value: 3,
    label: 'Low',
  },
  {
    value: 2,
    label: 'Normal',
  },
  {
    value: 1,
    label: 'High',
  },
  {
    value: 0,
    label: 'Ultra High',
  },
];

export interface LeaderCompetitivenessFilterProps {
  selectedLeaderCompetitiveness: LeaderCompetitiveness[];
  onSelectLeaderCompetitiveness: (
    selectedRankings: LeaderCompetitiveness[]
  ) => void;
  defaultClose?: boolean;
  onChange?: (isOpen: boolean) => void;
}

export const LeaderCompetitivenessFilter = ({
  selectedLeaderCompetitiveness,
  onSelectLeaderCompetitiveness,
  defaultClose,
  onChange,
}: LeaderCompetitivenessFilterProps) => {
  const handleRankingChange = (ranking: LeaderCompetitiveness) => {
    if (selectedLeaderCompetitiveness.includes(ranking)) {
      const newRankings = selectedLeaderCompetitiveness.filter(
        (item) => item !== ranking
      );
      if (newRankings.length > 0) {
        onSelectLeaderCompetitiveness(newRankings);
      }
    } else {
      onSelectLeaderCompetitiveness([
        ...selectedLeaderCompetitiveness,
        ranking,
      ]);
    }
  };

  return (
    <FilterContainer
      value="lender-competitiveness"
      title={
        <div className="text-heading-05 text-dark-dark">
          Lender Competitiveness
        </div>
      }
      defaultClose={defaultClose}
      onChange={onChange}
    >
      <div className="flex-col items-center justify-center space-y-2 px-6">
        {RANKING_OPTIONS.map(({ value, label }) => (
          <CheckboxWithLabel
            key={value}
            label={label}
            checked={selectedLeaderCompetitiveness.includes(value)}
            onClick={() => handleRankingChange(value)}
          />
        ))}
      </div>
    </FilterContainer>
  );
};
