export const SearchIcon = () => (
  <svg
    className="w-6 h-6"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3137 19.3629L11.296 19.3629L7.28712 12.6814L11.296 6L19.3137 6L23.3226 12.6814L19.3137 19.3629ZM19.3137 19.3629L24.7119 28"
      stroke="#192A68"
      strokeWidth="3"
    />
  </svg>
);
