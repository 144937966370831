import type { BarSeriesOption, EChartsOption } from 'echarts';
import { ECharts } from '@/components/charts/market-opportunity-chart/e-charts';
import {
  AllLostReasons,
  LostReasonCode,
  LostReasonCodeMap,
  LostReasonColorMap,
} from '@/components/crm/types';

interface LostReasonStatsByUser {
  userId: number;
  userName: string;
  lostReasons: Record<LostReasonCode, number>;
}

interface TeamLossReasonChartProps {
  data: LostReasonStatsByUser[];
}

export const TeamLossReasonByBrokerChart = ({
  data,
}: TeamLossReasonChartProps) => {
  const sortedReasons = AllLostReasons.slice();

  const seriesData: BarSeriesOption[] = sortedReasons.map((reason) => ({
    name: reason,
    type: 'bar',
    stack: 'total',
    data: data.map((user) => user.lostReasons[LostReasonCodeMap[reason]] || 0),
    itemStyle: {
      color: LostReasonColorMap[reason],
    },
  }));

  const option: EChartsOption = {
    grid: {
      left: '150px',
    },
    yAxis: {
      type: 'category',
      data: data.map((user) => user.userName),
    },
    xAxis: {
      type: 'value',
    },
    series: seriesData,
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const tooltipParams = params as unknown as {
          axisValue: string;
          marker: string;
          seriesName: string;
          data: number;
        }[];
        let tooltipText = `${tooltipParams[0].axisValue}<br/>`;
        tooltipParams.forEach((item) => {
          tooltipText += `${item.marker} ${item.seriesName}: ${item.data}<br/>`;
        });
        return tooltipText;
      },
    },
    legend: {
      data: sortedReasons,
    },
  };

  return (
    <div className="h-[400px] mt-6">
      {data ? (
        <ECharts option={option} style={{ height: 450 }} />
      ) : (
        <div style={{ height: 450 }}>Loading...</div>
      )}
    </div>
  );
};
