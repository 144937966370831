import { Sidebar } from '@/pages/settings/common/sidebar';
import { Input } from '@/components/ui/input';
import { useCallback, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { EditIcon } from '@/components/icons';
import { useApi } from '@/hooks/use-api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { User } from '@/pages';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import { Loader } from '@/components';
import { useToast } from '@/components/ui/use-toast';
import { useAuth, UserDetails } from '@/context';

export interface ProfileForm extends Partial<UserDetails> {}

export const Profile = () => {
  const [state, setState] = useState<{ editing: boolean }>({ editing: false });
  const { getRequest, patchRequest } = useApi();
  const { userDetails, updateUserDetails } = useAuth();
  const { toast } = useToast();
  const { isPending, error, data } = useQuery<User>({
    queryKey: ['me'],
    queryFn: () => getRequest('/api/me'),
  });

  const [profileForm, setProfileForm] = useState<ProfileForm>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const { isPending: isUpdating, mutateAsync: updateProfile } = useMutation({
    mutationFn: ({ form }: { form: ProfileForm }) =>
      patchRequest('/api/me', form),
    onSuccess: () => {
      toast({
        variant: 'success',
        description: 'Your profile has been updated successfully.',
        title: 'Profile Updated',
      });
      setState((prevState) => ({ ...prevState, editing: false }));
      if (userDetails) {
        updateUserDetails({
          ...userDetails,
          firstName: profileForm.firstName ?? '',
          lastName: profileForm.lastName ?? '',
          email: profileForm.email ?? '',
        });
      }
    },
    onError: (error) => {
      console.error('Failed to update profile', error);

      const errorMessage =
        (error as Error).message || 'An unknown error occurred';

      toast({
        variant: 'destructive',
        description: errorMessage,
        title: 'Failed to Update Profile',
      });
    },
  });

  useEffect(() => {
    if (data) {
      setProfileForm((prevState) => ({
        ...prevState,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
      }));
    }
  }, [data]);

  const handleSubmit = useCallback(async () => {
    await updateProfile({ form: profileForm });
  }, [updateProfile, profileForm]);

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <Sidebar currentPage="profile">
      <div>
        <div className="text-heading-01">Profile</div>
      </div>
      {isPending ? (
        <Loader />
      ) : (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await handleSubmit();
          }}
        >
          <div className="my-12">
            <div className="flex space-x-6">
              <div>
                <img
                  src="/assets/images/avatar.png"
                  alt="Avatar"
                  className="h-32"
                />
              </div>
              <div className="space-y-6">
                <div className="space-x-6 flex">
                  <div>
                    <Input
                      id="firstName"
                      name="firstName"
                      placeholder="First name"
                      disabled={!state.editing}
                      required
                      className="w-[376px] h-10 px-3 border-0 rounded-none border-b border-[#192a68] justify-start items-center gap-2.5 inline-flex text-label-md normal-case focus-visible:ring-0 focus-visible:ring-offset-0"
                      value={profileForm.firstName ?? ''}
                      onChange={(e) => {
                        setProfileForm((prevState) => ({
                          ...prevState,
                          firstName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div>
                    <Input
                      id="lastName"
                      name="lastName"
                      disabled={!state.editing}
                      required
                      placeholder="Last name"
                      className="w-[376px] h-10 px-3 border-0 rounded-none border-b border-[#192a68] justify-start items-center gap-2.5 inline-flex text-label-md normal-case focus-visible:ring-0 focus-visible:ring-offset-0"
                      value={profileForm.lastName ?? ''}
                      onChange={(e) => {
                        setProfileForm((prevState) => ({
                          ...prevState,
                          lastName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="space-x-6 flex">
                  <div>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      disabled={!state.editing}
                      required
                      placeholder="Email"
                      className="w-[376px] h-10 px-3 border-0 rounded-none border-b border-[#192a68] justify-start items-center gap-2.5 inline-flex text-label-md normal-case focus-visible:ring-0 focus-visible:ring-offset-0"
                      value={profileForm.email ?? ''}
                      onChange={(e) => {
                        setProfileForm((prevState) => ({
                          ...prevState,
                          email: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div>
                    <Input
                      id="phone"
                      name="phone"
                      disabled={!state.editing}
                      placeholder="Phone number"
                      className="w-[376px] h-10 px-3 border-0 rounded-none border-b border-[#192a68] justify-start items-center gap-2.5 inline-flex text-label-md normal-case focus-visible:ring-0 focus-visible:ring-offset-0"
                      value={profileForm.phone ?? ''}
                      onChange={(e) => {
                        setProfileForm((prevState) => ({
                          ...prevState,
                          phone: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr className="border-navy-navy my-4" />
          </div>
          <div className="flex justify-end space-x-6 items-center">
            <button
              className="w-6 h-6 disabled:cursor-not-allowed disabled:text-orange-shade"
              disabled={state.editing}
              onClick={() =>
                setState((prevState) => ({ ...prevState, editing: true }))
              }
            >
              <EditIcon />
            </button>
            <Button
              disabled={!state.editing || isUpdating}
              className="disabled:cursor-not-allowed"
              type="submit"
            >
              {isUpdating ? 'SAVING CHANGES' : 'SAVE CHANGES'}
            </Button>
          </div>
        </form>
      )}
    </Sidebar>
  );
};
