/* eslint-disable @typescript-eslint/no-explicit-any */

const toCamelCase = (s: string): string => {
  return s.replace(/(_\w)/g, (m) => m[1].toUpperCase());
};

const toSnakeCase = (s: string): string => {
  return s.replace(/([A-Z0-9])/g, '_$1').toLowerCase();
};

const convertKeys = (convertFn: (s: string) => string, obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => convertKeys(convertFn, v));
  } else if (obj !== null && obj !== undefined && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => {
        result[convertFn(key)] = convertKeys(convertFn, obj[key]);
        return result;
      },
      {} as Record<string, any>
    );
  }
  return obj;
};

export const camelizeKeys = (obj: any): any => {
  return convertKeys(toCamelCase, obj);
};

export const snakeizeKeys = (obj: any): any => {
  return convertKeys(toSnakeCase, obj);
};
