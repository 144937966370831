import { SalesPipeline } from '@/pages/crm/lead-stages/types';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';

const normalizePhoneNumber = (phoneNumber: string): string => {
  return `1${phoneNumber.replace(/\D/g, '')}`;
};

export const searchSalesPipeline = (
  searchString: string,
  data: SalesPipeline[]
): SalesPipeline[] => {
  if (searchString.trim() === '') {
    return data;
  }

  const searchWords = searchString
    .toLowerCase()
    .replace(/[^a-zA-Z0-9\s]/g, '')
    .replace(/(?<=\d)\s+(?=\d)/g, '')
    .split(' ');

  return data.filter((salesPipeline) => {
    return searchWords.every((word) => {
      const { property, manualProperty } = salesPipeline;
      const commonProperty = property ?? manualProperty;

      if (!commonProperty) {
        return false;
      }

      return (
        (commonProperty.originalMortgageType
          ? MORTGAGE_TYPE_MAP[commonProperty.originalMortgageType] ?? ''
          : ''
        )
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (commonProperty.situsFullAddress ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (commonProperty.firstCurrentOwnerFirstName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (commonProperty.firstCurrentOwnerLastName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        normalizePhoneNumber(commonProperty.firstCurrentOwnerPhoneNumber ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        normalizePhoneNumber(commonProperty.firstCurrentOwnerPhoneNumber2 ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (commonProperty.firstCurrentOwnerEmail ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (commonProperty.secondCurrentOwnerFirstName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (commonProperty.secondCurrentOwnerLastName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        normalizePhoneNumber(commonProperty.secondCurrentOwnerPhoneNumber ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        normalizePhoneNumber(
          commonProperty.secondCurrentOwnerPhoneNumber2 ?? ''
        )
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (commonProperty.secondCurrentOwnerEmail ?? '')
          .toLowerCase()
          .includes(word.toLowerCase())
      );
    });
  });
};
