import { IconProps } from '@/components/ui/icons/types';

export const ReleaseIcon = ({ className }: IconProps) => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M25.6714 9.98542C24.564 8.45721 23.1101 7.21335 21.4289 6.35594C19.7477 5.49852 17.8871 5.05199 15.9999 5.05298C9.82496 5.05298 4.74688 9.7351 4.11766 15.7439L3.99933 16.8749L6.26359 17.1127L6.38078 15.9806C6.59271 13.9778 7.4245 12.0911 8.76011 10.5838C10.0957 9.07638 11.8685 8.0235 13.8312 7.57201C15.7939 7.12051 17.8486 7.29292 19.7086 8.06518C21.5687 8.83743 23.1412 10.171 24.207 11.8799H19.9823V14.1555H27.947V6.1908H25.6714V9.98542ZM25.7362 16.8874L25.6179 18.0196C25.4057 20.022 24.5738 21.9083 23.2382 23.4154C21.9027 24.9224 20.1301 25.9751 18.1677 26.4265C16.2053 26.878 14.1509 26.7057 12.2911 25.9338C10.4313 25.1618 8.85873 23.8287 7.79281 22.1203H12.0175V19.8446H4.05281V27.8093H6.32844V24.0147C7.43577 25.5429 8.8897 26.7868 10.5709 27.6442C12.2521 28.5016 14.1127 28.9482 15.9999 28.9472C22.1737 28.9472 27.2529 24.265 27.881 18.2562L27.9993 17.1252L25.7362 16.8874Z"
      fill="currentColor"
    />
  </svg>
);
