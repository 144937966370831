import { Slider } from '@/components/ui/slider';
import { Range } from '@/components/filters/sections/mortgage-balance-filter';
import { formatShortestMoney } from '@/utils';

export interface BalanceSliderFilterProps {
  selectedBalanceRange: Range;
  onSelectBalanceRange: (balanceRange: Range) => void;
}

const percentageToMortgageBalance = (percentage: number): number => {
  if (percentage <= 50) {
    return 100000 + (percentage / 50) * 900000;
  } else if (percentage <= 75) {
    return 1000000 + ((percentage - 50) / 25) * 1000000;
  } else {
    return 2000000 + ((percentage - 75) / 25) * 8000000;
  }
};

const mortgageBalanceToPercentage = (balance: number): number => {
  if (balance <= 1000000) {
    return ((balance - 100000) / 900000) * 50;
  } else if (balance <= 2000000) {
    return 50 + ((balance - 1000000) / 1000000) * 25;
  } else {
    return 75 + ((balance - 2000000) / 8000000) * 25;
  }
};

export const BalanceSlider = ({
  selectedBalanceRange,
  onSelectBalanceRange,
}: BalanceSliderFilterProps) => {
  return (
    <div className="flex items-center justify-center space-x-2 px-6">
      <div className="text-eyebrow-md text-navy-tint border-[1px] px-auto w-[90px] border-navy-tint rounded py-1.5 flex items-center justify-center">
        {formatShortestMoney(selectedBalanceRange.from)}
      </div>
      <Slider
        defaultValue={[
          mortgageBalanceToPercentage(selectedBalanceRange.from),
          mortgageBalanceToPercentage(selectedBalanceRange.to),
        ]}
        min={0}
        max={100}
        step={1}
        onValueChange={(values) => {
          onSelectBalanceRange({
            from: percentageToMortgageBalance(values[0]),
            to: percentageToMortgageBalance(values[1]),
          });
        }}
      />
      <div className="text-eyebrow-md text-navy-tint border-[1px] px-auto w-[90px] border-navy-tint rounded py-1.5 flex items-center justify-center">
        {formatShortestMoney(selectedBalanceRange.to)}
      </div>
    </div>
  );
};
