import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '@/context/user-context';
import { TopNavigation } from '@/components';
import { useEffect } from 'react';

export const PrivateRoute = () => {
  const { isAuthenticated, refresh } = useAuth();

  useEffect(() => {
    refresh();
  }, [refresh]);

  return isAuthenticated ? (
    <div className="flex min-h-screen w-full flex-col">
      <TopNavigation />
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-3 mt-[84px]">
        <Outlet />
      </main>
    </div>
  ) : (
    <Navigate to="/login" replace />
  );
};
