import { SalesPipeline } from '@/pages/crm/lead-stages/types';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';
import { formatDateVerbose } from '@/utils/date';
import { capitalize, moneyFormatter, stateMap } from '@/utils';
import { AppSettingResponse } from '@/pages/data-pipeline/api-usage/types';
import {
  getEstAnnualSavingsFromRefinancing,
  getEstCurrentAnnualMortgageExpense,
  getEstMonthlySavingsFromRefinancing,
  getEstNewAnnualWholesaleMortgagePayment,
  getEstNewMonthlyWholesaleMortgagePayment,
} from '@/pages/admin/property-data/columns';

export const AllEmailFields = [
  'user_full_name',
  'owners',
  'address',
  'county',
  'mortgage_lender',
  'street',
  'city',
  'state',
  'state_abbr',
  'zip',
  'interest_rate',
  'mortgage_type',
  'est_mortgage_balance',
  'last_armslength_sale_date',
  'home_build_year',
  'interest_rate_wholesale_cnv',
  'interest_rate_wholesale_fha',
  'interest_rate_wholesale_va',
  'est_current_monthly_mtg_payment',
  'est_current_annual_mtg_expense',
  'est_wholesale_monthly_mtg_payment',
  'est_wholesale_annual_mtg_expense',
  'est_monthly_savings_from_refinancing',
  'est_annual_savings_from_refinancing',
] as const;

type EmailField = (typeof AllEmailFields)[number];

const getZipFromAddress = (address: string): string | null => {
  const zipRegex = /\b\d{5}(?:-\d{4})?\b/;
  const match = address.match(zipRegex);
  return match ? match[0] : null;
};

export const getFieldMapping = (
  userFullName: string,
  salesPipeline?: SalesPipeline,
  appSetting?: AppSettingResponse
): Record<EmailField, string> => {
  const property = salesPipeline?.property;

  const owners = (() => {
    const firstOwnerFirstName = property?.firstCurrentOwnerFirstName ?? '';
    const firstOwnerLastName = property?.firstCurrentOwnerLastName ?? '';
    const secondOwnerFirstName = property?.secondCurrentOwnerFirstName ?? '';
    const secondOwnerLastName = property?.secondCurrentOwnerLastName ?? '';

    if (firstOwnerLastName && firstOwnerLastName === secondOwnerLastName) {
      return `${firstOwnerFirstName} & ${secondOwnerFirstName} ${firstOwnerLastName}`.trim();
    }

    return [
      `${firstOwnerFirstName} ${firstOwnerLastName}`.trim(),
      `${secondOwnerFirstName} ${secondOwnerLastName}`.trim(),
    ]
      .filter((name) => name !== '')
      .join(' & ');
  })();

  return {
    user_full_name: capitalize(userFullName),
    owners: capitalize(owners || ''),
    address: capitalize(property?.situsFullAddress ?? ''),
    county: capitalize(property?.county ?? ''),
    mortgage_lender: property?.originalMortgageLender ?? '',
    street: capitalize(property?.situsStreetAddress ?? ''),
    city: capitalize(property?.situsCity ?? ''),
    state_abbr: property?.state ?? '',
    state: stateMap[property?.state ?? ''] ?? property?.state ?? '',
    zip: getZipFromAddress(property?.situsFullAddress ?? '') ?? '',
    mortgage_type: MORTGAGE_TYPE_MAP[`${property?.originalMortgageType}`],
    est_mortgage_balance: `${moneyFormatter.format(parseFloat(property?.originalMortgageAmount?.toString() ?? '0'))}`,
    last_armslength_sale_date: formatDateVerbose(
      property?.recordDateLastArmsLengthSale
    ),
    home_build_year: property?.yearBuilt?.toString() ?? '',
    interest_rate_wholesale_cnv: `${parseFloat((appSetting?.interestRateWholesaleCnv ?? 0).toString())}%`,
    interest_rate_wholesale_fha: `${parseFloat((appSetting?.interestRateWholesaleFha ?? 0).toString())}%`,
    interest_rate_wholesale_va: `${parseFloat((appSetting?.interestRateWholesaleVa ?? 0).toString())}%`,
    est_current_monthly_mtg_payment: `${moneyFormatter.format(property?.estimatedMonthlyMortgagePayment ?? 0)}`,
    est_current_annual_mtg_expense: `${moneyFormatter.format(getEstCurrentAnnualMortgageExpense(property))}`,
    est_wholesale_monthly_mtg_payment: `${moneyFormatter.format(getEstNewMonthlyWholesaleMortgagePayment(property, appSetting))}`,
    est_wholesale_annual_mtg_expense: `${moneyFormatter.format(getEstNewAnnualWholesaleMortgagePayment(property, appSetting))}`,
    est_monthly_savings_from_refinancing: `${moneyFormatter.format(getEstMonthlySavingsFromRefinancing(property, appSetting))}`,
    est_annual_savings_from_refinancing: `${moneyFormatter.format(getEstAnnualSavingsFromRefinancing(property, appSetting))}`,
    interest_rate: `${parseFloat((property?.originalMortgageInterestRate ?? 0).toString())}%`,
  };
};

export const generateEmailContent = (
  emailBodyTemplate: string,
  fieldMapping: Record<EmailField, string>
) => {
  return AllEmailFields.reduce((acc, field) => {
    return acc.replace(new RegExp(`<${field}>`, 'g'), fieldMapping[field]);
  }, emailBodyTemplate);
};
