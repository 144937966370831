import { Sidebar } from '@/pages/settings/common/sidebar';
import { ManualDataImportContainer } from '@/pages/data-pipeline/manual-data-import/manual-data-import-container';

export const ManualDataImport = () => {
  return (
    <Sidebar currentPage="manual-data-import">
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-heading-01">Manual Data Import</div>
        </div>
        <ManualDataImportContainer />
      </div>
    </Sidebar>
  );
};
