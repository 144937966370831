interface EyeIconProps {
  colorClassNames?: string;
}

export const EyeIcon = ({
  colorClassNames = 'text-navy-navy',
}: EyeIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={colorClassNames}
  >
    <path
      d="M8.23438 3.8125C4.84976 3.8125 2.73438 7.19712 2.73438 8.04327C2.73438 8.88942 4.84976 12.274 8.23438 12.274C11.619 12.274 13.7344 8.88942 13.7344 8.04327C13.7344 7.19712 11.619 3.8125 8.23438 3.8125Z"
      stroke="currentColor"
      strokeWidth="1.26923"
    />
    <path
      d="M6.54297 8.04387L8.23528 6.35156L9.92758 8.04387L8.23528 9.73618L6.54297 8.04387Z"
      fill="currentColor"
    />
  </svg>
);
