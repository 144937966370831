export type LostReason =
  | 'No Pickup'
  | 'Uninterested Owner'
  | 'Bad Email Info'
  | 'Bad Phone Info'
  | 'Bad Mortgage Info'
  | 'Permanently Unqualified'
  | 'Already Refinanced'
  | 'Add to IDNC';

export type LostReasonCode = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const LostReasonCodeMap: Record<LostReason, LostReasonCode> = {
  'No Pickup': 1,
  'Uninterested Owner': 2,
  'Bad Phone Info': 3,
  'Bad Mortgage Info': 4,
  'Bad Email Info': 5,
  'Permanently Unqualified': 6,
  'Already Refinanced': 7,
  'Add to IDNC': 8,
};

export const LostReasonMap: Record<LostReasonCode, LostReason> = {
  1: 'No Pickup',
  2: 'Uninterested Owner',
  3: 'Bad Phone Info',
  4: 'Bad Mortgage Info',
  5: 'Bad Email Info',
  6: 'Permanently Unqualified',
  7: 'Already Refinanced',
  8: 'Add to IDNC',
};

export const AllLostReasons: LostReason[] = [
  'No Pickup',
  'Uninterested Owner',
  'Bad Email Info',
  'Bad Phone Info',
  'Bad Mortgage Info',
  'Permanently Unqualified',
  'Already Refinanced',
  'Add to IDNC',
];

export const LostReasonColorMap: Record<LostReason, string> = {
  'No Pickup': '#E4A701',
  'Uninterested Owner': '#986F01',
  'Bad Email Info': '#FFC37D',
  'Bad Phone Info': '#FFA53C',
  'Bad Mortgage Info': '#0885BA',
  'Permanently Unqualified': '#05597C',
  'Already Refinanced': '#056B3A',
  'Add to IDNC': '#8C0019',
};
